import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow as MuiTableRow,
} from '@mui/material';
import React, { useCallback, useMemo } from 'react';
import { TableComponents, TableVirtuoso } from 'react-virtuoso';

import { TableColumnProps } from '../Table';

export interface VirtualizedTableProps<RowType extends object> {
  columns: TableColumnProps[];
  rows: RowType[];
  onCellRender: (row: RowType, column: TableColumnProps, columnIndex: number) => React.ReactNode;
  onHeaderCellRender?: (column: TableColumnProps, columnIndex: number) => React.ReactNode;
  fixedFooterContent?: JSX.Element;
  EmptyPlaceholder?: TableComponents<RowType>['EmptyPlaceholder'];
}

const createVirtuosoTableComponents = <RowType extends object>(
  overrides: Pick<TableComponents<RowType>, 'EmptyPlaceholder'> = {},
): TableComponents<RowType> => ({
  Scroller: React.forwardRef<HTMLDivElement>((props, ref) => (
    <TableContainer {...props} ref={ref} />
  )),
  Table: (props) => <Table {...props} sx={{ tableLayout: 'fixed', borderCollapse: 'separate' }} />,
  TableHead: React.forwardRef<HTMLTableSectionElement>((props, ref) => (
    <TableHead {...props} ref={ref} />
  )),
  TableRow: (props) => <MuiTableRow {...props} />,
  TableBody: React.forwardRef<HTMLTableSectionElement>((props, ref) => (
    <TableBody {...props} ref={ref} />
  )),
  EmptyPlaceholder: overrides?.EmptyPlaceholder ?? (() => <TableBody></TableBody>),
});

export const VirtualizedTable = React.memo(
  <RowType extends object>({
    columns,
    rows,
    onCellRender,
    onHeaderCellRender,
    fixedFooterContent,
    EmptyPlaceholder,
    ...tableProps
  }: VirtualizedTableProps<RowType>) => {
    const VirtuosoTableComponents = useMemo(
      () => createVirtuosoTableComponents<RowType>({ EmptyPlaceholder }),
      [EmptyPlaceholder],
    );

    const memoizedFixedHeaderContent = useCallback(
      () => (
        <MuiTableRow>
          {columns.map((column, index) => (
            <TableCell
              key={column.dataKey}
              width={column.width}
              align={column.align}
              variant="head"
            >
              {onHeaderCellRender?.(column, index) ?? column.label}
            </TableCell>
          ))}
        </MuiTableRow>
      ),
      [columns, onHeaderCellRender],
    );

    const memoizedFixedFooterContent = useCallback(
      () => (
        <Box position="absolute" right="0">
          {fixedFooterContent}
        </Box>
      ),
      [fixedFooterContent],
    );

    const memoizedRowContent = useCallback(
      (index: number, row: RowType) => (
        <React.Fragment>
          {columns.map((column, columnIndex) => (
            <TableCell key={column.dataKey} align={column.align}>
              {onCellRender(row, column, columnIndex)}
            </TableCell>
          ))}
        </React.Fragment>
      ),
      [columns, onCellRender],
    );

    return (
      <TableVirtuoso<RowType>
        data={rows}
        components={VirtuosoTableComponents}
        fixedHeaderContent={memoizedFixedHeaderContent}
        itemContent={memoizedRowContent}
        defaultItemHeight={48}
        fixedFooterContent={memoizedFixedFooterContent}
        {...tableProps}
      />
    );
  },
) as <RowType extends object>(props: VirtualizedTableProps<RowType>) => React.ReactNode;
