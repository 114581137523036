import * as T from '@aily/graphql-sdk/schema';
import { HStack } from '@aily-labs/ui';
import { Typography } from '@mui/material';
import React from 'react';

import { mapSentimentToChipColor } from '../../utils';
import { Chip } from '../Chip';

export interface TableCellCriteriaBadgeResultProps {
  badge: T.CriteriaBadgeResult;
}

export const TableCellCriteriaBadgeResult: React.FC<TableCellCriteriaBadgeResultProps> = ({
  badge,
}) => {
  const { criteriaPercentage, criteriaSentiment, label } = badge;

  return (
    <HStack alignItems="center" justifyContent="flex-end">
      <Typography variant="body" mr={2}>
        {label}
      </Typography>
      <Chip
        label={criteriaPercentage}
        color={mapSentimentToChipColor(criteriaSentiment ?? T.Sentiment.Neutral)}
      />
    </HStack>
  );
};

export default TableCellCriteriaBadgeResult;
