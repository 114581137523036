export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = {
  [_ in K]?: never;
};
export type Incremental<T> =
  | T
  | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  Date: { input: string; output: string };
  DateTime: { input: string; output: string };
  Decimal: { input: number; output: number };
  JObject: { input: unknown; output: unknown };
  Long: { input: number; output: number };
  Uuid: { input: string; output: string };
};

export type AddToBrainRealInput = {
  addedToBrain: Scalars['Boolean']['input'];
  link: Scalars['String']['input'];
  realId: Scalars['Uuid']['input'];
};

export type AddToBrainStoryInput = {
  addedToBrain: Scalars['Boolean']['input'];
  link: Scalars['String']['input'];
  storyId: Scalars['Uuid']['input'];
};

export type AdditionalFilter = {
  __typename?: 'AdditionalFilter';
  filterCode: Scalars['String']['output'];
  filterLabel: Scalars['String']['output'];
  filterLevel: Scalars['Int']['output'];
};

export type AdditionalFilterOptions = {
  __typename?: 'AdditionalFilterOptions';
  /** Filter code */
  filterCode: Scalars['String']['output'];
  /** Filter label */
  filterLabel: Scalars['String']['output'];
  /** Filter value */
  filterValue: Scalars['String']['output'];
  /** Sub filters */
  subFilters?: Maybe<Array<Maybe<AdditionalFilterOptions>>>;
};

export type AgentResult = {
  __typename?: 'AgentResult';
  details?: Maybe<ProAgentDetailsDataView>;
};

export type AgentResultDetailsArgs = {
  input?: InputMaybe<ProAgentDetailsDataInput>;
};

export type AiPosDriversResult = {
  __typename?: 'AiPosDriversResult';
  /** Fetch AiPosDrivers data */
  data: RndTrialsAiPosDriversDataView;
};

export type AiPosDriversResultDataArgs = {
  input: RndTrialsAiPosDriversDataInput;
};

export enum Alignment {
  Center = 'CENTER',
  Left = 'LEFT',
  Right = 'RIGHT',
}

export enum ApplyPolicy {
  AfterResolver = 'AFTER_RESOLVER',
  BeforeResolver = 'BEFORE_RESOLVER',
  Validation = 'VALIDATION',
}

export enum AxisType {
  Category = 'CATEGORY',
  Percent = 'PERCENT',
  Value = 'VALUE',
}

export type Badge = {
  __typename?: 'Badge';
  activity: BadgeActivity;
  level: BadgeLevel;
  recommended: BadgeRecommended;
};

export type BadgeActivity = {
  __typename?: 'BadgeActivity';
  label: Scalars['String']['output'];
  number: Scalars['Int']['output'];
};

export enum BadgeLevel {
  Bronze = 'BRONZE',
  Gold = 'GOLD',
  Silver = 'SILVER',
}

export type BadgeRecommended = {
  __typename?: 'BadgeRecommended';
  label: Scalars['String']['output'];
  level: BadgeLevel;
  text: Scalars['String']['output'];
};

export type BadgeResult = {
  __typename?: 'BadgeResult';
  /** Label content */
  content: Scalars['String']['output'];
  /** Sentiment for value */
  sentiment?: Maybe<Sentiment>;
};

export type BreakdownResult = {
  __typename?: 'BreakdownResult';
  /** Details for Breakdown AI PTRS data */
  breakdownAiPtrsDriverData?: Maybe<RndTrialsBreakdownAiPtrsDriversDataView>;
};

export type BreakdownResultBreakdownAiPtrsDriverDataArgs = {
  input: RndTrialsBreakdownAiPtrsDriversDataInput;
};

export type BusinessDevelopmentAndLicensingDriversDataInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  moduleId?: InputMaybe<Scalars['ID']['input']>;
  productId: Scalars['Int']['input'];
};

export type BusinessDevelopmentAndLicensingResult = {
  __typename?: 'BusinessDevelopmentAndLicensingResult';
  /** Business Development And Licensing Drivers */
  businessDevelopmentAndLicensingDrivers: RndInsightsBusinessDevelopmentAndLicensingDriversView;
  /** Business Development And Licensing table */
  businessDevelopmentAndLicensingTableData: RndInsightsBusinessDevelopmentAndLicensingTableDataView;
  /** Dependent filters */
  filters?: Maybe<Array<SelectFilter>>;
};

export type BusinessDevelopmentAndLicensingResultBusinessDevelopmentAndLicensingDriversArgs = {
  input: BusinessDevelopmentAndLicensingDriversDataInput;
};

export type BusinessDevelopmentAndLicensingResultBusinessDevelopmentAndLicensingTableDataArgs = {
  input: BusinessDevelopmentAndLicensingTableDataInput;
};

export type BusinessDevelopmentAndLicensingResultFiltersArgs = {
  filterInput: RndInsightsBDandLFilterDataInput;
};

export type BusinessDevelopmentAndLicensingTableDataInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  indicationFilterIds: Array<InputMaybe<Scalars['Int']['input']>>;
  moduleId?: InputMaybe<Scalars['ID']['input']>;
  phaseFilterIds: Array<InputMaybe<Scalars['Int']['input']>>;
  targetFilterId: Scalars['Int']['input'];
};

export type ButtonComponent = Component & {
  __typename?: 'ButtonComponent';
  code: Scalars['String']['output'];
  componentType: ComponentType;
  /** Dependent filters */
  filters?: Maybe<Array<FilterComponent>>;
  icon: ComponentIcon;
  id: Scalars['Uuid']['output'];
  title: Scalars['String']['output'];
  viewType: ViewType;
};

export type ButtonDataView = IDataView & {
  __typename?: 'ButtonDataView';
  availableUserSettings: Array<UserSetting>;
  /** Type of the data view */
  dataViewType: DataViewType;
  /** Dependent filters */
  filters?: Maybe<Array<FilterValue>>;
  /** Data view identifier */
  id: Scalars['Uuid']['output'];
  /** Button Label */
  label: Scalars['String']['output'];
  /** MetaData */
  metadata?: Maybe<Array<MetaData>>;
  /** Title */
  title?: Maybe<Scalars['String']['output']>;
};

export type Card = {
  cardType: CardType;
  code: Scalars['String']['output'];
  id: Scalars['String']['output'];
  title: TextResult;
};

export type CardContent = {
  __typename?: 'CardContent';
  driversList: Array<CardContent>;
  /** KPIs */
  kpis?: Maybe<Array<Maybe<KpiContent>>>;
  previousValue: Scalars['String']['output'];
  sentiment: Sentiment;
  text: Scalars['String']['output'];
  trendDirection: Direction;
  trendSentiment: Sentiment;
  value: Scalars['String']['output'];
  varianceColumnLabel: Scalars['String']['output'];
};

export type CardRanking = {
  __typename?: 'CardRanking';
  id: Scalars['String']['output'];
  isHighlighted: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  ranking: Scalars['Int']['output'];
  score: Scalars['Int']['output'];
  sentiment: Sentiment;
  trendDirection: Direction;
};

export enum CardType {
  GraHome = 'GRA_HOME',
  GtmHome = 'GTM_HOME',
  Kpi = 'KPI',
  MicroChart = 'MICRO_CHART',
  PplHome = 'PPL_HOME',
  Simple = 'SIMPLE',
  SpendHome = 'SPEND_HOME',
}

export type CardsComponent = Component & {
  __typename?: 'CardsComponent';
  code: Scalars['String']['output'];
  componentType: ComponentType;
  icon: ComponentIcon;
  id: Scalars['Uuid']['output'];
  title: Scalars['String']['output'];
  viewType: ViewType;
};

export type CardsDataView = IDataView & {
  __typename?: 'CardsDataView';
  /** Annotation */
  annotation?: Maybe<Scalars['String']['output']>;
  availableUserSettings: Array<UserSetting>;
  /** Cards */
  cards?: Maybe<Array<Maybe<Card>>>;
  /** Type of the data view */
  dataViewType: DataViewType;
  /** Edit link */
  editLink?: Maybe<Link>;
  /** Data view identifier */
  id: Scalars['Uuid']['output'];
  /** MetaData */
  metadata?: Maybe<Array<MetaData>>;
  /** Title */
  title?: Maybe<Scalars['String']['output']>;
};

export type CashFlowKpiComponent = Component & {
  __typename?: 'CashFlowKpiComponent';
  code: Scalars['String']['output'];
  componentType: ComponentType;
  filters: Array<FilterComponent>;
  icon: ComponentIcon;
  id: Scalars['Uuid']['output'];
  title: Scalars['String']['output'];
  viewType: ViewType;
};

export type CashFlowKpiDataViewResult = IDataView & {
  __typename?: 'CashFlowKpiDataViewResult';
  availableUserSettings: Array<UserSetting>;
  dataViewType: DataViewType;
  filters: Array<FilterValue>;
  id: Scalars['Uuid']['output'];
  kpiItems: Array<CashFlowKpiItemResult>;
  metadata?: Maybe<Array<MetaData>>;
  recommendation?: Maybe<CashFlowRecommendationResult>;
};

export type CashFlowKpiItemResult = {
  __typename?: 'CashFlowKpiItemResult';
  direction: Direction;
  label: Scalars['String']['output'];
  sentiment: Sentiment;
  valueFormatted: Scalars['String']['output'];
  varianceFormatted: Scalars['String']['output'];
  varianceLabel: Scalars['String']['output'];
};

export type CashFlowRecommendationResult = {
  __typename?: 'CashFlowRecommendationResult';
  details: Scalars['String']['output'];
  header: Scalars['String']['output'];
};

export type CashflowResult = {
  __typename?: 'CashflowResult';
  kpi: CashFlowKpiDataViewResult;
};

export type CashflowResultKpiArgs = {
  input?: InputMaybe<DataViewInput>;
};

export type ChartAxis =
  | ChartAxisCategoryViewResult
  | ChartAxisPercentViewResult
  | ChartAxisValueViewResult;

export type ChartAxisCategoryViewResult = {
  __typename?: 'ChartAxisCategoryViewResult';
  /** Axis type */
  axisType: AxisType;
  /** Axis categories */
  categories?: Maybe<Array<Scalars['String']['output']>>;
  /** Renderer for chart */
  renderer?: Maybe<Scalars['JObject']['output']>;
};

export type ChartAxisPercentViewResult = {
  __typename?: 'ChartAxisPercentViewResult';
  /** Axis type */
  axisType: AxisType;
  /** Renderer for chart */
  renderer?: Maybe<Scalars['JObject']['output']>;
};

export type ChartAxisValueViewResult = {
  __typename?: 'ChartAxisValueViewResult';
  /** Axis type */
  axisType: AxisType;
  hasRawDataPointValues?: Maybe<Scalars['Boolean']['output']>;
  /** Renderer for chart */
  renderer?: Maybe<Scalars['JObject']['output']>;
};

export type ChartComponent = Component & {
  __typename?: 'ChartComponent';
  /** Component code */
  code: Scalars['String']['output'];
  componentType: ComponentType;
  /** Drill ids */
  drillIds?: Maybe<Array<Scalars['Long']['output']>>;
  filters?: Maybe<Array<FilterComponent>>;
  icon: ComponentIcon;
  id: Scalars['Uuid']['output'];
  title: Scalars['String']['output'];
  viewType: ViewType;
};

export type ChartDataViewResult = IDataView & {
  __typename?: 'ChartDataViewResult';
  availableUserSettings: Array<UserSetting>;
  componentIcon: ComponentIcon;
  /** Type of the data view */
  dataViewType: DataViewType;
  /** Id of the row for which is chart displayed */
  drillId?: Maybe<Scalars['Long']['output']>;
  /** Selected filters for chart */
  filters?: Maybe<Array<FilterValue>>;
  /** Data view identifier */
  id: Scalars['Uuid']['output'];
  /** MetaData */
  metadata?: Maybe<Array<MetaData>>;
  /** Renderer for chart */
  renderer?: Maybe<Scalars['JObject']['output']>;
  /** Chart series */
  series?: Maybe<Array<ChartSeries>>;
  /** Axis x configuration */
  xAxis: ChartAxis;
  /** Axis y configuration */
  yAxis: ChartAxis;
};

export type ChartSeries = {
  __typename?: 'ChartSeries';
  /** Series color */
  color?: Maybe<Color>;
  /** Chart data */
  data?: Maybe<Array<ChartSeriesDataPoint>>;
  /** Series identifier */
  id: Scalars['Int']['output'];
  /** Line style: solid, dotted, dashed */
  lineStyle?: Maybe<LineStyle>;
  /** Identifier of linked series */
  linkedTo?: Maybe<Scalars['Int']['output']>;
  /** Name of the series */
  name: Scalars['String']['output'];
  /** Renderer for chart */
  renderer?: Maybe<Scalars['JObject']['output']>;
  /** Series type: Bar, Line, Area */
  seriesType: SeriesType;
  /** Indicates if series should be shown in legend */
  showInLegend?: Maybe<Scalars['Boolean']['output']>;
  /** Determines if series should be shown in tooltip */
  showInTooltip: Scalars['Boolean']['output'];
  showLastPointMarker: Scalars['Boolean']['output'];
  tooltipCustomType: TooltipCustomType;
  tooltipRangeFormat: TooltipRangeFormat;
  tooltipTitleHigh?: Maybe<Scalars['String']['output']>;
  tooltipTitleLow?: Maybe<Scalars['String']['output']>;
  tooltipTitleMedian?: Maybe<Scalars['String']['output']>;
};

export type ChartSeriesDataPoint = {
  __typename?: 'ChartSeriesDataPoint';
  /** X value */
  x?: Maybe<Scalars['Float']['output']>;
  /** Y value */
  y?: Maybe<ChartSeriesDataPointValue>;
};

export type ChartSeriesDataPointValue = {
  __typename?: 'ChartSeriesDataPointValue';
  /** high */
  high?: Maybe<FormattedValue>;
  /** low */
  low?: Maybe<FormattedValue>;
  /** median */
  median?: Maybe<FormattedValue>;
  /** value */
  value?: Maybe<FormattedValue>;
  /** variance */
  variance?: Maybe<VarianceResult>;
};

export enum ChartType {
  Bar = 'BAR',
  Line = 'LINE',
  Waterfall = 'WATERFALL',
}

export type CmoPulseActionDescription = {
  __typename?: 'CmoPulseActionDescription';
  /** Content of the action description */
  content: Scalars['String']['output'];
  /** Subtitle of the action description */
  subtitle: Scalars['String']['output'];
  /** Title of the action description */
  title: Scalars['String']['output'];
};

export type CmoPulseDriversChartHeader = {
  __typename?: 'CmoPulseDriversChartHeader';
  label: Scalars['String']['output'];
  score: Scalars['Int']['output'];
  sentiment: Sentiment;
  subLabel: Scalars['String']['output'];
};

export type CmoPulseDriversFilterDataView = {
  __typename?: 'CmoPulseDriversFilterDataView';
  /** Key Driver Filter Data */
  timeFilter: SelectFilter;
};

export type CmoPulseDriversFilterDataViewTimeFilterArgs = {
  input?: InputMaybe<SpendCmoPulseKeyDriverFilterDataInput>;
};

export type CmoPulseKeyDriversResult = {
  __typename?: 'CmoPulseKeyDriversResult';
  /** Dependent filters */
  filters: CmoPulseDriversFilterDataView;
  /** Cmo Pulse KeyDrivers Chart Data */
  keyDriversChart?: Maybe<SpendCmoPulseDriversChartDataView>;
  /** Cmo Pulse KeyDrivers List Data */
  keyDriversList?: Maybe<SpendCmoPulseDriversListDataView>;
};

export type CmoPulseKeyDriversResultKeyDriversChartArgs = {
  input?: InputMaybe<SpendCmoPulseDriversChartDataInput>;
};

export type CmoPulseKeyDriversResultKeyDriversListArgs = {
  input?: InputMaybe<SpendCmoPulseDriversListDataInput>;
};

export type CmoPulseLeaderboardPositionDataView = IDataView & {
  __typename?: 'CmoPulseLeaderboardPositionDataView';
  availableUserSettings: Array<UserSetting>;
  /** Type of the data view */
  dataViewType: DataViewType;
  /** Direction for the highlighted element trend arrow */
  direction?: Maybe<Direction>;
  /** Data view identifier */
  id: Scalars['Uuid']['output'];
  /** Label of the highlighted element */
  label?: Maybe<Scalars['String']['output']>;
  /** Link configuration */
  leaderboardPageLink?: Maybe<Link>;
  metadata?: Maybe<Array<MetaData>>;
  /** Message to display when no data is available for selection */
  noDataMessage?: Maybe<SpendNoDataMessage>;
  /** Sentiment for the highlighted element */
  sentiment?: Maybe<Sentiment>;
  /** Score of the highlighted element */
  value?: Maybe<Scalars['String']['output']>;
};

export type CmoPulseRecommendedAction = {
  __typename?: 'CmoPulseRecommendedAction';
  /** ActionDescription of the recommended action */
  cmoPulseAction: CmoPulseActionDescription;
  /** Summary of the recommended action */
  summary: Scalars['String']['output'];
  /** Title of the recommended action */
  title: Scalars['String']['output'];
};

export type CmoPulseRecommendedActionsDataView = IDataView & {
  __typename?: 'CmoPulseRecommendedActionsDataView';
  availableUserSettings: Array<UserSetting>;
  /** Type of the data view */
  dataViewType: DataViewType;
  /** Data view identifier */
  id: Scalars['Uuid']['output'];
  lastUpdateDate?: Maybe<Scalars['String']['output']>;
  metadata: Array<MetaData>;
  /** Message to display when no data is available for selection */
  noDataMessage?: Maybe<SpendNoDataMessage>;
  /** Recommended actions list */
  recommendedActionsList?: Maybe<Array<CmoPulseRecommendedAction>>;
  /** Subtitle of the recommended actions */
  subtitle?: Maybe<Scalars['String']['output']>;
  /** Title of the recommended actions */
  title?: Maybe<Scalars['String']['output']>;
};

export type CmoPulseResult = {
  __typename?: 'CmoPulseResult';
  /** Cmo Pulse Leaderboard Position Data */
  cmoPulseLeaderboardPosition: CmoPulseLeaderboardPositionDataView;
  /** Cmo Pulse Recommended Actions Data */
  cmoPulseRecommendedActions: CmoPulseRecommendedActionsDataView;
  /** Cmo Pulse Dial Area Data */
  dial: SpendCmoPulseDialDataView;
};

export type CmoPulseResultCmoPulseLeaderboardPositionArgs = {
  input?: InputMaybe<SpendCmoPulseDataInput>;
};

export type CmoPulseResultCmoPulseRecommendedActionsArgs = {
  input?: InputMaybe<SpendCmoPulseDataInput>;
};

export type CmoPulseResultDialArgs = {
  input?: InputMaybe<SpendCmoPulseDataInput>;
};

export enum Color {
  AilyBlue = 'AILY_BLUE',
  Aqua = 'AQUA',
  Black = 'BLACK',
  Blue = 'BLUE',
  DarkBlue = 'DARK_BLUE',
  Default = 'DEFAULT',
  Failure = 'FAILURE',
  Green = 'GREEN',
  Grey_1 = 'GREY_1',
  Grey_2 = 'GREY_2',
  Grey_3 = 'GREY_3',
  Orange = 'ORANGE',
  Pink = 'PINK',
  Success = 'SUCCESS',
  Warning = 'WARNING',
  White = 'WHITE',
}

export type ColorString = {
  __typename?: 'ColorString';
  color?: Maybe<Scalars['String']['output']>;
};

export type ColorUnion = ColorString | ThemeColor;

export type ColoredVarianceResult = {
  __typename?: 'ColoredVarianceResult';
  /** Actual Text content */
  actual?: Maybe<Scalars['String']['output']>;
  /** Label content */
  label?: Maybe<Scalars['String']['output']>;
  /** Sentiment for value */
  sentiment?: Maybe<Sentiment>;
  /** SubLabels content */
  subLabels?: Maybe<Array<Scalars['String']['output']>>;
  /** Target Text content */
  target?: Maybe<Scalars['String']['output']>;
};

export type ColoredVarianceWithDeltaResult = {
  __typename?: 'ColoredVarianceWithDeltaResult';
  /** Actual text content */
  actual?: Maybe<Scalars['String']['output']>;
  /** Delta values */
  deltaValues?: Maybe<Delta>;
  /** Sentiment for value */
  sentiment?: Maybe<Sentiment>;
};

export type ColumnLayoutComponent = Component & {
  __typename?: 'ColumnLayoutComponent';
  /** Component code */
  code: Scalars['String']['output'];
  componentType: ComponentType;
  /** First column components */
  firstColumn?: Maybe<Array<Component>>;
  /** Header row components */
  headerRow?: Maybe<Array<Component>>;
  icon: ComponentIcon;
  id: Scalars['Uuid']['output'];
  /** Second column components */
  secondColumn?: Maybe<Array<Component>>;
  title: Scalars['String']['output'];
  viewType: ViewType;
};

export type ComparatorTrendResult = {
  __typename?: 'ComparatorTrendResult';
  /** Left value */
  leftValue: ValueTextResult;
  /** Right value */
  rightValue?: Maybe<Scalars['String']['output']>;
  /** Trend arrow */
  trendArrow?: Maybe<TrendArrowResult>;
};

export type Component = {
  /** Component code */
  code: Scalars['String']['output'];
  /** Component type */
  componentType: ComponentType;
  /** Chart icon for chart */
  icon: ComponentIcon;
  /** Component identifier */
  id: Scalars['Uuid']['output'];
  /** Title */
  title?: Maybe<Scalars['String']['output']>;
  /** View type for which component should be displayed (Landscape, Portrait) */
  viewType: ViewType;
};

export type ComponentBase = Component & {
  __typename?: 'ComponentBase';
  code: Scalars['String']['output'];
  componentType: ComponentType;
  icon: ComponentIcon;
  id: Scalars['Uuid']['output'];
  title: Scalars['String']['output'];
  viewType: ViewType;
};

export enum ComponentIcon {
  BarChart = 'BAR_CHART',
  LineChart = 'LINE_CHART',
  None = 'NONE',
  WaterfallChart = 'WATERFALL_CHART',
}

export enum ComponentType {
  Button = 'BUTTON',
  Cards = 'CARDS',
  CashflowAnalyzerBackButtonComponent = 'CASHFLOW_ANALYZER_BACK_BUTTON_COMPONENT',
  CashflowAnalyzerChartComponent = 'CASHFLOW_ANALYZER_CHART_COMPONENT',
  CashflowAnalyzerChartHeaderComponent = 'CASHFLOW_ANALYZER_CHART_HEADER_COMPONENT',
  CashflowAnalyzerMicrochartComponent = 'CASHFLOW_ANALYZER_MICROCHART_COMPONENT',
  CashflowAnalyzerQuarterTableComponent = 'CASHFLOW_ANALYZER_QUARTER_TABLE_COMPONENT',
  CashflowAnalyzerTableComponent = 'CASHFLOW_ANALYZER_TABLE_COMPONENT',
  CashflowChartComponent = 'CASHFLOW_CHART_COMPONENT',
  CashflowChartHeaderComponent = 'CASHFLOW_CHART_HEADER_COMPONENT',
  CashflowKpiComponent = 'CASHFLOW_KPI_COMPONENT',
  CashflowMicrochartComponent = 'CASHFLOW_MICROCHART_COMPONENT',
  CashflowQuarterTableComponent = 'CASHFLOW_QUARTER_TABLE_COMPONENT',
  CashflowTableComponent = 'CASHFLOW_TABLE_COMPONENT',
  ChartDataView = 'CHART_DATA_VIEW',
  Container = 'CONTAINER',
  Content = 'CONTENT',
  DailySalesChartComponent = 'DAILY_SALES_CHART_COMPONENT',
  DailySalesChartHeaderComponent = 'DAILY_SALES_CHART_HEADER_COMPONENT',
  DailySalesMicrochartComponent = 'DAILY_SALES_MICROCHART_COMPONENT',
  DailySalesTableComponent = 'DAILY_SALES_TABLE_COMPONENT',
  DemoHomeChart = 'DEMO_HOME_CHART',
  DemoHomeChartHeader = 'DEMO_HOME_CHART_HEADER',
  DemoHomeMicrochart = 'DEMO_HOME_MICROCHART',
  Dials = 'DIALS',
  EbiHomeCategoriesDials = 'EBI_HOME_CATEGORIES_DIALS',
  EbiHomeDetailsChart = 'EBI_HOME_DETAILS_CHART',
  EbiHomeDetailsChartHeader = 'EBI_HOME_DETAILS_CHART_HEADER',
  EbiHomeDetailsDrivers = 'EBI_HOME_DETAILS_DRIVERS',
  EbiHomeDetailsDriversChart = 'EBI_HOME_DETAILS_DRIVERS_CHART',
  EbiHomeDetailsDriversChartHeader = 'EBI_HOME_DETAILS_DRIVERS_CHART_HEADER',
  EbiHomeDetailsMicrochart = 'EBI_HOME_DETAILS_MICROCHART',
  EbiHomeDetailsRecommendations = 'EBI_HOME_DETAILS_RECOMMENDATIONS',
  Filter = 'FILTER',
  Filters = 'FILTERS',
  FinCardsComponent = 'FIN_CARDS_COMPONENT',
  FinDriversChartComponent = 'FIN_DRIVERS_CHART_COMPONENT',
  FinDriversChartHeaderComponent = 'FIN_DRIVERS_CHART_HEADER_COMPONENT',
  FinDriversKeyAssumptionsTableComponent = 'FIN_DRIVERS_KEY_ASSUMPTIONS_TABLE_COMPONENT',
  FinDynamicDialsComponent = 'FIN_DYNAMIC_DIALS_COMPONENT',
  FinPriceVolumeTableComponent = 'FIN_PRICE_VOLUME_TABLE_COMPONENT',
  FinResourceAllocationTableComponent = 'FIN_RESOURCE_ALLOCATION_TABLE_COMPONENT',
  GraFacilitatedPathwaysTable = 'GRA_FACILITATED_PATHWAYS_TABLE',
  GraHomeCards = 'GRA_HOME_CARDS',
  GraHomeDialsComponent = 'GRA_HOME_DIALS_COMPONENT',
  GraRecommendedPathwaysDetailsComponent = 'GRA_RECOMMENDED_PATHWAYS_DETAILS_COMPONENT',
  GraRecommendedPathwaysTable = 'GRA_RECOMMENDED_PATHWAYS_TABLE',
  Grid = 'GRID',
  GtmFrequencyTableComponent = 'GTM_FREQUENCY_TABLE_COMPONENT',
  GtmHomeCards = 'GTM_HOME_CARDS',
  GtmHomeDials = 'GTM_HOME_DIALS',
  GtmImpactRecommendationComponent = 'GTM_IMPACT_RECOMMENDATION_COMPONENT',
  GtmImpactTableComponent = 'GTM_IMPACT_TABLE_COMPONENT',
  GtmImpactTableHeaderComponent = 'GTM_IMPACT_TABLE_HEADER_COMPONENT',
  GtmInteractionsChartComponent = 'GTM_INTERACTIONS_CHART_COMPONENT',
  GtmInteractionsChartHeaderComponent = 'GTM_INTERACTIONS_CHART_HEADER_COMPONENT',
  GtmInteractionsMicrochartComponent = 'GTM_INTERACTIONS_MICROCHART_COMPONENT',
  GtmInteractionsTableComponent = 'GTM_INTERACTIONS_TABLE_COMPONENT',
  GtmPulseDetailsChartComponent = 'GTM_PULSE_DETAILS_CHART_COMPONENT',
  GtmPulseDetailsChartHeaderComponent = 'GTM_PULSE_DETAILS_CHART_HEADER_COMPONENT',
  GtmPulseDetailsDriversComponent = 'GTM_PULSE_DETAILS_DRIVERS_COMPONENT',
  GtmPulseDialComponent = 'GTM_PULSE_DIAL_COMPONENT',
  GtmPulseLeaderboardHeaderComponent = 'GTM_PULSE_LEADERBOARD_HEADER_COMPONENT',
  GtmPulseLeaderboardListComponent = 'GTM_PULSE_LEADERBOARD_LIST_COMPONENT',
  GtmPulseLeaderboardPositionComponent = 'GTM_PULSE_LEADERBOARD_POSITION_COMPONENT',
  GtmPulseRecommendedActionsComponent = 'GTM_PULSE_RECOMMENDED_ACTIONS_COMPONENT',
  GtmReachChartComponent = 'GTM_REACH_CHART_COMPONENT',
  GtmReachChartHeaderComponent = 'GTM_REACH_CHART_HEADER_COMPONENT',
  GtmReachMicrochartComponent = 'GTM_REACH_MICROCHART_COMPONENT',
  GtmReachTableComponent = 'GTM_REACH_TABLE_COMPONENT',
  GtmResourceAllocationTableComponent = 'GTM_RESOURCE_ALLOCATION_TABLE_COMPONENT',
  GtmWhatIfChartComponent = 'GTM_WHAT_IF_CHART_COMPONENT',
  GtmWhatIfChartHeaderComponent = 'GTM_WHAT_IF_CHART_HEADER_COMPONENT',
  GtnOpexAnalyzerBackButtonComponent = 'GTN_OPEX_ANALYZER_BACK_BUTTON_COMPONENT',
  GtnOpexAnalyzerChartComponent = 'GTN_OPEX_ANALYZER_CHART_COMPONENT',
  GtnOpexAnalyzerChartHeaderComponent = 'GTN_OPEX_ANALYZER_CHART_HEADER_COMPONENT',
  GtnOpexAnalyzerMicrochartComponent = 'GTN_OPEX_ANALYZER_MICROCHART_COMPONENT',
  GtnOpexAnalyzerQuarterTableComponent = 'GTN_OPEX_ANALYZER_QUARTER_TABLE_COMPONENT',
  GtnOpexAnalyzerTableComponent = 'GTN_OPEX_ANALYZER_TABLE_COMPONENT',
  GtnOpexQuarterTableComponent = 'GTN_OPEX_QUARTER_TABLE_COMPONENT',
  GtnOpexTableComponent = 'GTN_OPEX_TABLE_COMPONENT',
  HeaderRowView = 'HEADER_ROW_VIEW',
  HeaderSimple = 'HEADER_SIMPLE',
  Kpi = 'KPI',
  LaunchesChartComponent = 'LAUNCHES_CHART_COMPONENT',
  LaunchesChartHeaderComponent = 'LAUNCHES_CHART_HEADER_COMPONENT',
  LaunchesMicrochartComponent = 'LAUNCHES_MICROCHART_COMPONENT',
  LaunchesTableComponent = 'LAUNCHES_TABLE_COMPONENT',
  List = 'LIST',
  Markdown = 'MARKDOWN',
  MarketShareChartComponent = 'MARKET_SHARE_CHART_COMPONENT',
  MarketShareChartHeaderComponent = 'MARKET_SHARE_CHART_HEADER_COMPONENT',
  MarketShareDemandChartComponent = 'MARKET_SHARE_DEMAND_CHART_COMPONENT',
  MarketShareDemandChartHeaderComponent = 'MARKET_SHARE_DEMAND_CHART_HEADER_COMPONENT',
  MarketShareDemandMicrochartComponent = 'MARKET_SHARE_DEMAND_MICROCHART_COMPONENT',
  MarketShareMicrochartComponent = 'MARKET_SHARE_MICROCHART_COMPONENT',
  MarketShareTableComponent = 'MARKET_SHARE_TABLE_COMPONENT',
  Microchart = 'MICROCHART',
  MnsDeviationsChartComponent = 'MNS_DEVIATIONS_CHART_COMPONENT',
  MnsDeviationsChartHeaderComponent = 'MNS_DEVIATIONS_CHART_HEADER_COMPONENT',
  MnsDeviationsDetailsComponent = 'MNS_DEVIATIONS_DETAILS_COMPONENT',
  MnsDeviationsDialComponent = 'MNS_DEVIATIONS_DIAL_COMPONENT',
  MnsDeviationsMicrochartComponent = 'MNS_DEVIATIONS_MICROCHART_COMPONENT',
  MnsDeviationsRootCausesAndCapasTableComponent = 'MNS_DEVIATIONS_ROOT_CAUSES_AND_CAPAS_TABLE_COMPONENT',
  MnsDeviationsTableComponent = 'MNS_DEVIATIONS_TABLE_COMPONENT',
  MnsDeviationsTopRootCausesTableComponent = 'MNS_DEVIATIONS_TOP_ROOT_CAUSES_TABLE_COMPONENT',
  Module = 'MODULE',
  MonthlySalesAnnualTrendTableComponent = 'MONTHLY_SALES_ANNUAL_TREND_TABLE_COMPONENT',
  MonthlySalesChartComponent = 'MONTHLY_SALES_CHART_COMPONENT',
  MonthlySalesChartHeaderComponent = 'MONTHLY_SALES_CHART_HEADER_COMPONENT',
  MonthlySalesMicrochartComponent = 'MONTHLY_SALES_MICROCHART_COMPONENT',
  MonthlySalesQuarterTableComponent = 'MONTHLY_SALES_QUARTER_TABLE_COMPONENT',
  MonthlySalesTableComponent = 'MONTHLY_SALES_TABLE_COMPONENT',
  OpexAnnualTableComponent = 'OPEX_ANNUAL_TABLE_COMPONENT',
  OpexChartComponent = 'OPEX_CHART_COMPONENT',
  OpexChartHeaderComponent = 'OPEX_CHART_HEADER_COMPONENT',
  OpexMicrochartComponent = 'OPEX_MICROCHART_COMPONENT',
  OpexQuarterTableComponent = 'OPEX_QUARTER_TABLE_COMPONENT',
  OpexTableComponent = 'OPEX_TABLE_COMPONENT',
  Plailist = 'PLAILIST',
  PnlAnalyzerAnnualTrendTableComponent = 'PNL_ANALYZER_ANNUAL_TREND_TABLE_COMPONENT',
  PnlAnalyzerBackButtonComponent = 'PNL_ANALYZER_BACK_BUTTON_COMPONENT',
  PnlAnalyzerChartComponent = 'PNL_ANALYZER_CHART_COMPONENT',
  PnlAnalyzerChartHeaderComponent = 'PNL_ANALYZER_CHART_HEADER_COMPONENT',
  PnlAnalyzerMicrochartComponent = 'PNL_ANALYZER_MICROCHART_COMPONENT',
  PnlAnalyzerQuarterTableComponent = 'PNL_ANALYZER_QUARTER_TABLE_COMPONENT',
  PnlAnalyzerSpecificAccountChartComponent = 'PNL_ANALYZER_SPECIFIC_ACCOUNT_CHART_COMPONENT',
  PnlAnalyzerSpecificAccountChartHeaderComponent = 'PNL_ANALYZER_SPECIFIC_ACCOUNT_CHART_HEADER_COMPONENT',
  PnlAnalyzerSpecificAccountMicrochartComponent = 'PNL_ANALYZER_SPECIFIC_ACCOUNT_MICROCHART_COMPONENT',
  PnlAnalyzerSpecificAccountTableComponent = 'PNL_ANALYZER_SPECIFIC_ACCOUNT_TABLE_COMPONENT',
  PnlAnalyzerTableComponent = 'PNL_ANALYZER_TABLE_COMPONENT',
  PnlAnnualTrendTableComponent = 'PNL_ANNUAL_TREND_TABLE_COMPONENT',
  PnlChartComponent = 'PNL_CHART_COMPONENT',
  PnlChartHeaderComponent = 'PNL_CHART_HEADER_COMPONENT',
  PnlMicrochartComponent = 'PNL_MICROCHART_COMPONENT',
  PnlQuarterTableComponent = 'PNL_QUARTER_TABLE_COMPONENT',
  PnlRebatesBreakdownChartComponent = 'PNL_REBATES_BREAKDOWN_CHART_COMPONENT',
  PnlRebatesBreakdownChartHeaderComponent = 'PNL_REBATES_BREAKDOWN_CHART_HEADER_COMPONENT',
  PnlRebatesBreakdownMicrochartComponent = 'PNL_REBATES_BREAKDOWN_MICROCHART_COMPONENT',
  PnlRebatesBreakdownTableComponent = 'PNL_REBATES_BREAKDOWN_TABLE_COMPONENT',
  PnlRebatesInsightsCardsComponent = 'PNL_REBATES_INSIGHTS_CARDS_COMPONENT',
  PnlRebatesInsightsHeaderComponent = 'PNL_REBATES_INSIGHTS_HEADER_COMPONENT',
  PnlTableComponent = 'PNL_TABLE_COMPONENT',
  PplHomeCards = 'PPL_HOME_CARDS',
  PplHomeCategoriesDials = 'PPL_HOME_CATEGORIES_DIALS',
  PplHomeDetailsChart = 'PPL_HOME_DETAILS_CHART',
  PplHomeDetailsChartHeader = 'PPL_HOME_DETAILS_CHART_HEADER',
  PplHomeDetailsDrivers = 'PPL_HOME_DETAILS_DRIVERS',
  PplHomeDetailsDriversChart = 'PPL_HOME_DETAILS_DRIVERS_CHART',
  PplHomeDetailsDriversChartHeader = 'PPL_HOME_DETAILS_DRIVERS_CHART_HEADER',
  PplHomeDetailsMicrochart = 'PPL_HOME_DETAILS_MICROCHART',
  PplHomeDetailsRecommendations = 'PPL_HOME_DETAILS_RECOMMENDATIONS',
  PplHomeDial = 'PPL_HOME_DIAL',
  ProAgentDetails = 'PRO_AGENT_DETAILS',
  ProDailyAgent = 'PRO_DAILY_AGENT',
  ProHomeFeed = 'PRO_HOME_FEED',
  Recommendations = 'RECOMMENDATIONS',
  RecommendationsBreakdown = 'RECOMMENDATIONS_BREAKDOWN',
  Recommender = 'RECOMMENDER',
  RndDriversChartComponent = 'RND_DRIVERS_CHART_COMPONENT',
  RndDriversChartHeaderComponent = 'RND_DRIVERS_CHART_HEADER_COMPONENT',
  RndDriversKeyAssumptionsTableComponent = 'RND_DRIVERS_KEY_ASSUMPTIONS_TABLE_COMPONENT',
  RndGnoMilestonesTableComponent = 'RND_GNO_MILESTONES_TABLE_COMPONENT',
  RndGnoMilestonesTableTimelineComponent = 'RND_GNO_MILESTONES_TABLE_TIMELINE_COMPONENT',
  RndGnoTimelineComponent = 'RND_GNO_TIMELINE_COMPONENT',
  RndHomeCardsComponent = 'RND_HOME_CARDS_COMPONENT',
  RndHomeDial = 'RND_HOME_DIAL',
  RndHomeDialsComponent = 'RND_HOME_DIALS_COMPONENT',
  RndInsightsBusinessDevelopmentAndLicensingDriversComponent = 'RND_INSIGHTS_BUSINESS_DEVELOPMENT_AND_LICENSING_DRIVERS_COMPONENT',
  RndInsightsBusinessDevelopmentAndLicensingTableComponent = 'RND_INSIGHTS_BUSINESS_DEVELOPMENT_AND_LICENSING_TABLE_COMPONENT',
  RndInsightsPlaifolioChartComponent = 'RND_INSIGHTS_PLAIFOLIO_CHART_COMPONENT',
  RndInsightsPlaifolioRecommendationsComponent = 'RND_INSIGHTS_PLAIFOLIO_RECOMMENDATIONS_COMPONENT',
  RndInsightsWhatifChartComponent = 'RND_INSIGHTS_WHATIF_CHART_COMPONENT',
  RndInsightsWhatifChartHeaderComponent = 'RND_INSIGHTS_WHATIF_CHART_HEADER_COMPONENT',
  RndInsightsWhatifEnrolTableComponent = 'RND_INSIGHTS_WHATIF_ENROL_TABLE_COMPONENT',
  RndInsightsWhatifInitTableComponent = 'RND_INSIGHTS_WHATIF_INIT_TABLE_COMPONENT',
  RndInsightsWhatifRecommendationBreakdownComponent = 'RND_INSIGHTS_WHATIF_RECOMMENDATION_BREAKDOWN_COMPONENT',
  RndInsightsWhatifRecommendationComponent = 'RND_INSIGHTS_WHATIF_RECOMMENDATION_COMPONENT',
  RndStudyDesignerChartComponent = 'RND_STUDY_DESIGNER_CHART_COMPONENT',
  RndStudyDesignerForm = 'RND_STUDY_DESIGNER_FORM',
  RndStudyDesignerSiteDistributionTable = 'RND_STUDY_DESIGNER_SITE_DISTRIBUTION_TABLE',
  RndStudyDesignerTable = 'RND_STUDY_DESIGNER_TABLE',
  RndTrialsBreakdownAiPtrsDriversComponent = 'RND_TRIALS_BREAKDOWN_AI_PTRS_DRIVERS_COMPONENT',
  RndTrialsBreakdownTableComponent = 'RND_TRIALS_BREAKDOWN_TABLE_COMPONENT',
  RndTrialsInventoryChartComponent = 'RND_TRIALS_INVENTORY_CHART_COMPONENT',
  RndTrialsInventoryChartHeaderComponent = 'RND_TRIALS_INVENTORY_CHART_HEADER_COMPONENT',
  RndTrialsInventoryMicrochartComponent = 'RND_TRIALS_INVENTORY_MICROCHART_COMPONENT',
  RndTrialsInventoryOutlierSitesChartComponent = 'RND_TRIALS_INVENTORY_OUTLIER_SITES_CHART_COMPONENT',
  RndTrialsInventoryOutlierSitesChartHeaderComponent = 'RND_TRIALS_INVENTORY_OUTLIER_SITES_CHART_HEADER_COMPONENT',
  RndTrialsInventoryOutlierSitesMicrochartComponent = 'RND_TRIALS_INVENTORY_OUTLIER_SITES_MICROCHART_COMPONENT',
  RndTrialsInventoryOutlierSitesTableComponent = 'RND_TRIALS_INVENTORY_OUTLIER_SITES_TABLE_COMPONENT',
  RndTrialsInventoryRecommendationsBreakdownComponent = 'RND_TRIALS_INVENTORY_RECOMMENDATIONS_BREAKDOWN_COMPONENT',
  RndTrialsInventoryRecommendationsComponent = 'RND_TRIALS_INVENTORY_RECOMMENDATIONS_COMPONENT',
  RndTrialsInventoryRootCausesComponent = 'RND_TRIALS_INVENTORY_ROOT_CAUSES_COMPONENT',
  RndTrialsInventoryTableComponent = 'RND_TRIALS_INVENTORY_TABLE_COMPONENT',
  RndTrialsKpiCategoriesComponent = 'RND_TRIALS_KPI_CATEGORIES_COMPONENT',
  RndTrialsKpiDetailTableComponent = 'RND_TRIALS_KPI_DETAIL_TABLE_COMPONENT',
  RndTrialsMilestonesIndicationsTimelineComponent = 'RND_TRIALS_MILESTONES_INDICATIONS_TIMELINE_COMPONENT',
  RndTrialsMilestonesIndicationsTimelineTableComponent = 'RND_TRIALS_MILESTONES_INDICATIONS_TIMELINE_TABLE_COMPONENT',
  RndTrialsMilestonesProgramsTimelineComponent = 'RND_TRIALS_MILESTONES_PROGRAMS_TIMELINE_COMPONENT',
  RndTrialsMilestonesProgramsTimelineTableComponent = 'RND_TRIALS_MILESTONES_PROGRAMS_TIMELINE_TABLE_COMPONENT',
  RndTrialsMilestonesStudiesAiPosDriversComponent = 'RND_TRIALS_MILESTONES_STUDIES_AI_POS_DRIVERS_COMPONENT',
  RndTrialsMilestonesStudiesKpiComponent = 'RND_TRIALS_MILESTONES_STUDIES_KPI_COMPONENT',
  RndTrialsMilestonesStudiesSummaryInfoComponent = 'RND_TRIALS_MILESTONES_STUDIES_SUMMARY_INFO_COMPONENT',
  RndTrialsMilestonesStudiesTimelineComponent = 'RND_TRIALS_MILESTONES_STUDIES_TIMELINE_COMPONENT',
  RndTrialsMilestonesStudiesTimelineTableComponent = 'RND_TRIALS_MILESTONES_STUDIES_TIMELINE_TABLE_COMPONENT',
  RndTrialsPatientsEnrollmentChartComponent = 'RND_TRIALS_PATIENTS_ENROLLMENT_CHART_COMPONENT',
  RndTrialsPatientsEnrollmentChartHeaderComponent = 'RND_TRIALS_PATIENTS_ENROLLMENT_CHART_HEADER_COMPONENT',
  RndTrialsPatientsEnrollmentMicrochartComponent = 'RND_TRIALS_PATIENTS_ENROLLMENT_MICROCHART_COMPONENT',
  RndTrialsPatientsEnrollmentTableComponent = 'RND_TRIALS_PATIENTS_ENROLLMENT_TABLE_COMPONENT',
  RndTrialsRndInvestmentsPnlTableComponent = 'RND_TRIALS_RND_INVESTMENTS_PNL_TABLE_COMPONENT',
  RndTrialsRndInvestmentsStratplanTableComponent = 'RND_TRIALS_RND_INVESTMENTS_STRATPLAN_TABLE_COMPONENT',
  RndTrialsSitesEnrollmentChartComponent = 'RND_TRIALS_SITES_ENROLLMENT_CHART_COMPONENT',
  RndTrialsSitesEnrollmentChartHeaderComponent = 'RND_TRIALS_SITES_ENROLLMENT_CHART_HEADER_COMPONENT',
  RndTrialsSitesEnrollmentMicrochartComponent = 'RND_TRIALS_SITES_ENROLLMENT_MICROCHART_COMPONENT',
  RndTrialsSitesEnrollmentTableComponent = 'RND_TRIALS_SITES_ENROLLMENT_TABLE_COMPONENT',
  RndTrialsValueOverviewChartComponent = 'RND_TRIALS_VALUE_OVERVIEW_CHART_COMPONENT',
  RndTrialsValueOverviewChartHeaderComponent = 'RND_TRIALS_VALUE_OVERVIEW_CHART_HEADER_COMPONENT',
  SgnaTableComponent = 'SGNA_TABLE_COMPONENT',
  SpendCmoPulseDialComponent = 'SPEND_CMO_PULSE_DIAL_COMPONENT',
  SpendCmoPulseDriversChartComponent = 'SPEND_CMO_PULSE_DRIVERS_CHART_COMPONENT',
  SpendCmoPulseDriversListComponent = 'SPEND_CMO_PULSE_DRIVERS_LIST_COMPONENT',
  SpendCmoPulseKeyDriversChartComponent = 'SPEND_CMO_PULSE_KEY_DRIVERS_CHART_COMPONENT',
  SpendCmoPulseKeyDriversListComponent = 'SPEND_CMO_PULSE_KEY_DRIVERS_LIST_COMPONENT',
  SpendCmoPulseLeaderboardPositionComponent = 'SPEND_CMO_PULSE_LEADERBOARD_POSITION_COMPONENT',
  SpendCmoPulseRecommendedActionsComponent = 'SPEND_CMO_PULSE_RECOMMENDED_ACTIONS_COMPONENT',
  SpendGlocalPulseDialComponent = 'SPEND_GLOCAL_PULSE_DIAL_COMPONENT',
  SpendGlocalPulseDriversChartComponent = 'SPEND_GLOCAL_PULSE_DRIVERS_CHART_COMPONENT',
  SpendGlocalPulseDriversChartHeaderComponent = 'SPEND_GLOCAL_PULSE_DRIVERS_CHART_HEADER_COMPONENT',
  SpendGlocalPulseDriversListComponent = 'SPEND_GLOCAL_PULSE_DRIVERS_LIST_COMPONENT',
  SpendGlocalPulseKeyDriversChartComponent = 'SPEND_GLOCAL_PULSE_KEY_DRIVERS_CHART_COMPONENT',
  SpendGlocalPulseKeyDriversListComponent = 'SPEND_GLOCAL_PULSE_KEY_DRIVERS_LIST_COMPONENT',
  SpendGlocalPulseLeaderboardListComponent = 'SPEND_GLOCAL_PULSE_LEADERBOARD_LIST_COMPONENT',
  SpendGlocalPulseLeaderboardPositionComponent = 'SPEND_GLOCAL_PULSE_LEADERBOARD_POSITION_COMPONENT',
  SpendGlocalPulseRecommendedActionsComponent = 'SPEND_GLOCAL_PULSE_RECOMMENDED_ACTIONS_COMPONENT',
  SpendHomeCards = 'SPEND_HOME_CARDS',
  SpendHomeDials = 'SPEND_HOME_DIALS',
  SpendProcurementChartComponent = 'SPEND_PROCUREMENT_CHART_COMPONENT',
  SpendProcurementChartHeaderComponent = 'SPEND_PROCUREMENT_CHART_HEADER_COMPONENT',
  SpendProcurementMicrochartComponent = 'SPEND_PROCUREMENT_MICROCHART_COMPONENT',
  SpendProcurementTableComponent = 'SPEND_PROCUREMENT_TABLE_COMPONENT',
  SpendTrendingComponent = 'SPEND_TRENDING_COMPONENT',
  Story = 'STORY',
  StoryList = 'STORY_LIST',
  SummaryInfoDataView = 'SUMMARY_INFO_DATA_VIEW',
  SupplyHomeCardsComponent = 'SUPPLY_HOME_CARDS_COMPONENT',
  SupplyHomeDialsComponent = 'SUPPLY_HOME_DIALS_COMPONENT',
  SupplyLirChartComponent = 'SUPPLY_LIR_CHART_COMPONENT',
  SupplyLirChartHeaderComponent = 'SUPPLY_LIR_CHART_HEADER_COMPONENT',
  SupplyLirMicrochartComponent = 'SUPPLY_LIR_MICROCHART_COMPONENT',
  SupplyLirRootCausesComponent = 'SUPPLY_LIR_ROOT_CAUSES_COMPONENT',
  SupplyLirTableComponent = 'SUPPLY_LIR_TABLE_COMPONENT',
  SupplyPulseComponent = 'SUPPLY_PULSE_COMPONENT',
  SupplyPulseDriverKpisComponent = 'SUPPLY_PULSE_DRIVER_KPIS_COMPONENT',
  SupplyPulseHistoryChartComponent = 'SUPPLY_PULSE_HISTORY_CHART_COMPONENT',
  SupplyPulseHistoryHeaderComponent = 'SUPPLY_PULSE_HISTORY_HEADER_COMPONENT',
  SupplyPulseKeyDriversComponent = 'SUPPLY_PULSE_KEY_DRIVERS_COMPONENT',
  SupplyPulseLeaderboardListComponent = 'SUPPLY_PULSE_LEADERBOARD_LIST_COMPONENT',
  SupplyPulseRecommendationsComponent = 'SUPPLY_PULSE_RECOMMENDATIONS_COMPONENT',
  SupplyShortDatedChartComponent = 'SUPPLY_SHORT_DATED_CHART_COMPONENT',
  SupplyShortDatedChartHeaderComponent = 'SUPPLY_SHORT_DATED_CHART_HEADER_COMPONENT',
  SupplyShortDatedMicrochartComponent = 'SUPPLY_SHORT_DATED_MICROCHART_COMPONENT',
  SupplyShortDatedRootCausesComponent = 'SUPPLY_SHORT_DATED_ROOT_CAUSES_COMPONENT',
  SupplyShortDatedTableComponent = 'SUPPLY_SHORT_DATED_TABLE_COMPONENT',
  TableDataView = 'TABLE_DATA_VIEW',
  TableSwitcher = 'TABLE_SWITCHER',
  TabNavigation = 'TAB_NAVIGATION',
  Timeline = 'TIMELINE',
  TwoColumnLayout = 'TWO_COLUMN_LAYOUT',
  Unknown = 'UNKNOWN',
  UserAnalytics = 'USER_ANALYTICS',
  UserPriorities = 'USER_PRIORITIES',
  Video = 'VIDEO',
  WhatIfChartComponent = 'WHAT_IF_CHART_COMPONENT',
  WhatIfChartHeaderComponent = 'WHAT_IF_CHART_HEADER_COMPONENT',
}

export type CreateStudyDesignInput = {
  assetCode: Scalars['String']['input'];
  clinicalIndicationCode: Scalars['String']['input'];
  comment?: InputMaybe<Scalars['String']['input']>;
  firstPatientIn: Scalars['String']['input'];
  studyOptimizerId: Scalars['String']['input'];
  studyPhaseId: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type CriteriaBadgeResult = {
  __typename?: 'CriteriaBadgeResult';
  /** Criteria percentage */
  criteriaPercentage?: Maybe<Scalars['String']['output']>;
  /** Criteria sentiment */
  criteriaSentiment?: Maybe<Sentiment>;
  /** Label for criteria value */
  label?: Maybe<Scalars['String']['output']>;
};

export type DailyAgentActionsScreenResult = ScreenResultType & {
  __typename?: 'DailyAgentActionsScreenResult';
  /** Content */
  content: Scalars['String']['output'];
  /** Redirect label */
  redirectLabel?: Maybe<Scalars['String']['output']>;
  screenType: ScreenType;
  /** Title */
  title: FormattedTextResult;
};

export type DailyAgentDialResult = {
  __typename?: 'DailyAgentDialResult';
  /** Label */
  label: Scalars['String']['output'];
  /** QuerterTarget */
  querterTarget: Scalars['String']['output'];
  /** Sentiment */
  sentiment: Sentiment;
  /** Target */
  target: Scalars['Float']['output'];
  /** Target Reach date */
  targetReachDate: Scalars['String']['output'];
  /** Value */
  value: Scalars['Float']['output'];
};

export type DailyAgentFireworksScreenResult = ScreenResultType & {
  __typename?: 'DailyAgentFireworksScreenResult';
  /** Headline */
  headline: Scalars['String']['output'];
  screenType: ScreenType;
  /** Streak */
  streak: DailyAgentStreakResult;
};

export type DailyAgentProgressScreenResult = ScreenResultType & {
  __typename?: 'DailyAgentProgressScreenResult';
  /** Current position */
  currentPosition: LeaderboardPositionResult;
  /** Dial */
  dial: DailyAgentDialResult;
  /** Formatted date */
  formattedDate: Scalars['String']['output'];
  /** Goal Short label */
  goalShortLabel?: Maybe<Scalars['String']['output']>;
  /** Leaderboard */
  leaderboard: LeaderboardResult;
  /** Long Audio path */
  longAudioPath?: Maybe<Scalars['String']['output']>;
  /** Message */
  message: Scalars['String']['output'];
  screenType: ScreenType;
  /** Short Audio path */
  shortAudioPath?: Maybe<Scalars['String']['output']>;
};

export type DailyAgentResult = {
  __typename?: 'DailyAgentResult';
  details?: Maybe<ProDailyAgentDataView>;
  goals?: Maybe<Array<Maybe<GoalResultType>>>;
  intercepts?: Maybe<Array<Maybe<InterceptResultType>>>;
  screens?: Maybe<Array<Maybe<Array<Maybe<ScreenResultType>>>>>;
};

export type DailyAgentResultDetailsArgs = {
  input?: InputMaybe<ProDailyAgentDetailsDataInput>;
};

export type DailyAgentStreakDayItemResult = {
  __typename?: 'DailyAgentStreakDayItemResult';
  /** Day */
  day: Scalars['String']['output'];
  /** Value */
  value: Scalars['Boolean']['output'];
};

export type DailyAgentStreakResult = {
  __typename?: 'DailyAgentStreakResult';
  /** Days */
  days: Array<Maybe<DailyAgentStreakDayItemResult>>;
  /** Label */
  label: Scalars['String']['output'];
  /** Value */
  value: FormattedTextResult;
};

export type DataViewInput = {
  additionalFiltersInput?: InputMaybe<Array<UserAdditionalFiltersInput>>;
  categoryId?: InputMaybe<Scalars['Int']['input']>;
  componentCode?: InputMaybe<Scalars['String']['input']>;
  dimensionId?: InputMaybe<Scalars['Long']['input']>;
  drillId?: InputMaybe<Scalars['Int']['input']>;
  drillIds?: InputMaybe<Array<Scalars['Long']['input']>>;
  filters?: InputMaybe<Array<FilterInput>>;
  id: Scalars['Uuid']['input'];
  moduleId: Scalars['Uuid']['input'];
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  previousPriorityId?: InputMaybe<Scalars['Int']['input']>;
  priorityId?: InputMaybe<Scalars['Int']['input']>;
  rowsPerPage?: InputMaybe<Scalars['Int']['input']>;
  scoreDriverCode?: InputMaybe<Scalars['String']['input']>;
  sortColumn?: InputMaybe<Scalars['String']['input']>;
  sortType?: InputMaybe<Scalars['String']['input']>;
  timePeriodId?: InputMaybe<Scalars['Int']['input']>;
  userSettings?: InputMaybe<Array<UserSettingInput>>;
};

export enum DataViewType {
  Button = 'BUTTON',
  Cards = 'CARDS',
  Chart = 'CHART',
  ChartHeader = 'CHART_HEADER',
  DailyAgent = 'DAILY_AGENT',
  Dials = 'DIALS',
  Drivers = 'DRIVERS',
  Feed = 'FEED',
  FinCashFlowKpi = 'FIN_CASH_FLOW_KPI',
  FinHomeCards = 'FIN_HOME_CARDS',
  FinPnlRebatesInsightsCards = 'FIN_PNL_REBATES_INSIGHTS_CARDS',
  GraFacilitatedPathwaysTable = 'GRA_FACILITATED_PATHWAYS_TABLE',
  GraHomeDials = 'GRA_HOME_DIALS',
  GraRecommendedPathwaysDetailsComponent = 'GRA_RECOMMENDED_PATHWAYS_DETAILS_COMPONENT',
  GraRecommendedPathwaysTable = 'GRA_RECOMMENDED_PATHWAYS_TABLE',
  Grid = 'GRID',
  HeaderRow = 'HEADER_ROW',
  HeaderSimple = 'HEADER_SIMPLE',
  Kpi = 'KPI',
  List = 'LIST',
  Microchart = 'MICROCHART',
  Prediction = 'PREDICTION',
  ProAgentDetails = 'PRO_AGENT_DETAILS',
  Recommendations = 'RECOMMENDATIONS',
  RecommendationsBreakdown = 'RECOMMENDATIONS_BREAKDOWN',
  Recommender = 'RECOMMENDER',
  RndBusinessDevelopmentAndLicensingDrivers = 'RND_BUSINESS_DEVELOPMENT_AND_LICENSING_DRIVERS',
  RndBusinessDevelopmentAndLicensingTable = 'RND_BUSINESS_DEVELOPMENT_AND_LICENSING_TABLE',
  RndHomeDials = 'RND_HOME_DIALS',
  RndStudyDesignsSiteDistributionTable = 'RND_STUDY_DESIGNS_SITE_DISTRIBUTION_TABLE',
  RndStudyDesignsTable = 'RND_STUDY_DESIGNS_TABLE',
  RndTrialsAiPosDrivers = 'RND_TRIALS_AI_POS_DRIVERS',
  RndTrialsBreakdownAiPtrsDrivers = 'RND_TRIALS_BREAKDOWN_AI_PTRS_DRIVERS',
  RndTrialsInventoryRecommendations = 'RND_TRIALS_INVENTORY_RECOMMENDATIONS',
  RndTrialsInventoryRecommendationsBreakdown = 'RND_TRIALS_INVENTORY_RECOMMENDATIONS_BREAKDOWN',
  RndTrialsInventoryRootCauses = 'RND_TRIALS_INVENTORY_ROOT_CAUSES',
  RndTrialsKpisCategories = 'RND_TRIALS_KPIS_CATEGORIES',
  RndTrialsKpisDetailTable = 'RND_TRIALS_KPIS_DETAIL_TABLE',
  RndTrialsRndInvestmentsPnlTableComponent = 'RND_TRIALS_RND_INVESTMENTS_PNL_TABLE_COMPONENT',
  RndTrialsRndInvestmentsStratplanTableComponent = 'RND_TRIALS_RND_INVESTMENTS_STRATPLAN_TABLE_COMPONENT',
  RndTrialsValueOverviewChart = 'RND_TRIALS_VALUE_OVERVIEW_CHART',
  RndTrialsValueOverviewChartHeader = 'RND_TRIALS_VALUE_OVERVIEW_CHART_HEADER',
  SpendProcurementChart = 'SPEND_PROCUREMENT_CHART',
  SpendProcurementTable = 'SPEND_PROCUREMENT_TABLE',
  SpendTrending = 'SPEND_TRENDING',
  SummaryInfo = 'SUMMARY_INFO',
  Table = 'TABLE',
}

export enum DecisionContentType {
  Agent = 'AGENT',
  Real = 'REAL',
}

export type DecisionItem = {
  __typename?: 'DecisionItem';
  /** Your decision content type Agent or Real */
  contentType: DecisionContentType;
  /** Date and time when the item was added for decision tracking. */
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** Indicates if the decision item is expired. */
  isExpired: Scalars['Boolean']['output'];
  /** Decision link to a specific page in the app. */
  link: Scalars['String']['output'];
  /** Module code of the Decision item. */
  moduleCode: ModuleCode;
  /** Decision item resource id - Agent id, real. */
  resourceId: Scalars['String']['output'];
  /** Status of the decision item. */
  status: DecisionStatusEnum;
  /** Decision item title. */
  title: Scalars['String']['output'];
};

export type DecisionResult = {
  __typename?: 'DecisionResult';
  decisionItemResults?: Maybe<Array<DecisionItem>>;
};

export enum DecisionStatusEnum {
  Done = 'DONE',
  Inprogress = 'INPROGRESS',
}

export type Delta = {
  __typename?: 'Delta';
  /** Delta actual text content */
  actual?: Maybe<Scalars['String']['output']>;
  /** Delta sentiment for value */
  sentiment?: Maybe<Sentiment>;
};

export type DemoHomeChartComponent = Component & {
  __typename?: 'DemoHomeChartComponent';
  /** Component code */
  code: Scalars['String']['output'];
  componentType: ComponentType;
  filters?: Maybe<Array<FilterComponent>>;
  icon: ComponentIcon;
  id: Scalars['Uuid']['output'];
  title: Scalars['String']['output'];
  viewType: ViewType;
};

export type DemoHomeMicroChartComponent = Component & {
  __typename?: 'DemoHomeMicroChartComponent';
  /** Component code */
  code: Scalars['String']['output'];
  componentType: ComponentType;
  filters?: Maybe<Array<FilterComponent>>;
  icon: ComponentIcon;
  id: Scalars['Uuid']['output'];
  title: Scalars['String']['output'];
  viewType: ViewType;
};

export type DesignStudyUserOptions = {
  __typename?: 'DesignStudyUserOptions';
  assetOptions: UserInputSelect;
  clinicalIndicationOptions: UserInputSelect;
  optimiseForOptions: UserInputSelect;
  studyPhaseOptions: UserInputSelect;
};

export type DialItem = {
  __typename?: 'DialItem';
  actualText?: Maybe<TextResult>;
  label: Scalars['String']['output'];
  link?: Maybe<TextLinkResult>;
  progress: Scalars['Float']['output'];
  progressText?: Maybe<TextResult>;
  sentiment: Color;
  showTarget: Scalars['Boolean']['output'];
  target?: Maybe<Scalars['Float']['output']>;
  targetText?: Maybe<TextResult>;
  varianceAIText?: Maybe<TextResult>;
  varianceText?: Maybe<TextResult>;
};

export type DialsComponent = Component & {
  __typename?: 'DialsComponent';
  /** Component code */
  code: Scalars['String']['output'];
  componentType: ComponentType;
  icon: ComponentIcon;
  id: Scalars['Uuid']['output'];
  title: Scalars['String']['output'];
  viewType: ViewType;
};

export type DialsDataView = IDataView & {
  __typename?: 'DialsDataView';
  /** A information to be displayed along with the annotation */
  annotation?: Maybe<Scalars['String']['output']>;
  /** A date information to be displayed along with the annotation */
  annotationDate?: Maybe<Scalars['String']['output']>;
  availableUserSettings: Array<UserSetting>;
  /** Type of the data view */
  dataViewType: DataViewType;
  /** Data view identifier */
  id: Scalars['Uuid']['output'];
  /** Dial items */
  items?: Maybe<Array<DialItem>>;
  /** Formatted date of last data update */
  lastUpdateDate?: Maybe<Scalars['String']['output']>;
  /** MetaData */
  metadata?: Maybe<Array<MetaData>>;
};

export enum Direction {
  Down = 'DOWN',
  Neutral = 'NEUTRAL',
  Up = 'UP',
}

export enum DisplayMode {
  Default = 'DEFAULT',
  Inline = 'INLINE',
  Slider = 'SLIDER',
}

export enum DisplayVariant {
  Alternative = 'ALTERNATIVE',
  Default = 'DEFAULT',
}

export type DrillDownResult = {
  __typename?: 'DrillDownResult';
  /** Ids to update dimension filter */
  dimensionIds?: Maybe<Array<Maybe<Scalars['Long']['output']>>>;
  /** Id of row id which is used to drill down action */
  drillDownId?: Maybe<Scalars['Long']['output']>;
  /** Ids of rows that are creating Drill down path */
  drillDownIds?: Maybe<Array<Maybe<Scalars['Long']['output']>>>;
  /** Id of parent row id which is used to drill up action */
  drillUpId?: Maybe<Scalars['Long']['output']>;
  drillUpIds?: Maybe<Array<Scalars['Long']['output']>>;
  /** Indicator if cell is hierarchical */
  isHierarchical?: Maybe<Scalars['Boolean']['output']>;
  /** SubLabels content */
  subLabels?: Maybe<Array<Scalars['String']['output']>>;
  /** Text content */
  value?: Maybe<Scalars['String']['output']>;
};

export type DriverDataRow = {
  __typename?: 'DriverDataRow';
  categoryScoreDeltaSentiment: Sentiment;
  categoryScoreDeltaValue: Scalars['String']['output'];
  categoryScoreValue: Scalars['String']['output'];
  categoryScoreValueSentiment: Sentiment;
  detailsData: DriverDetailsData;
  label: Scalars['String']['output'];
};

export type DriverDetailsData = {
  __typename?: 'DriverDetailsData';
  categoryScoreDeltaSentiment: Sentiment;
  categoryScoreDeltaValue: Scalars['String']['output'];
  categoryScoreValue: Scalars['String']['output'];
  categoryScoreValueSentiment: Sentiment;
  driverDetails: Array<Scalars['String']['output']>;
  driverDetailsTitle: Scalars['String']['output'];
  lastUpdatedDate: Scalars['String']['output'];
  subTitle: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type DriversData = {
  __typename?: 'DriversData';
  /** Drivers list */
  drivers: Array<DriverDataRow>;
  /** Drivers List Title */
  driversListTitle?: Maybe<Scalars['String']['output']>;
  indication: Scalars['String']['output'];
  /** IsAddedToShortlist */
  isAddedToShortlist: Scalars['Boolean']['output'];
  productCode: Scalars['String']['output'];
  /** Drivers SubTitle */
  subTitle?: Maybe<Scalars['String']['output']>;
  /** Data view identifier */
  title?: Maybe<Scalars['String']['output']>;
  /** TotalScore and Npv row */
  totalScoreAndNpvRow: TotalScoreAndNpvRow;
};

export type EbiHomeCategoriesDialsComponent = Component & {
  __typename?: 'EbiHomeCategoriesDialsComponent';
  /** Component code */
  code: Scalars['String']['output'];
  componentType: ComponentType;
  filters?: Maybe<Array<FilterComponent>>;
  icon: ComponentIcon;
  id: Scalars['Uuid']['output'];
  title: Scalars['String']['output'];
  viewType: ViewType;
};

export type EbiHomeCategoriesDialsDataView = IDataView & {
  __typename?: 'EbiHomeCategoriesDialsDataView';
  /** Annotation */
  annotation?: Maybe<Scalars['String']['output']>;
  availableUserSettings: Array<UserSetting>;
  /** Type of the data view */
  dataViewType: DataViewType;
  /** Categories dials */
  dials: Array<Maybe<EbiHomeCategoryDial>>;
  id: Scalars['Uuid']['output'];
  linkResult: Link;
  metadata?: Maybe<Array<MetaData>>;
  title: Scalars['String']['output'];
};

export type EbiHomeCategoryDial = {
  __typename?: 'EbiHomeCategoryDial';
  /** Float value for progress (how much circle is filled */
  progress: Scalars['Float']['output'];
  /** Category name */
  scoreCategory: Scalars['String']['output'];
  /** Category identifier */
  scoreCategoryId: Scalars['Int']['output'];
  /** Sentiment of circle which is translated to the color on frontend side */
  sentiment: Sentiment;
  /** Float value of target (white dot inside circle) */
  target?: Maybe<Scalars['Float']['output']>;
  /** Trend arrow which displays business status of the score */
  trend?: Maybe<TrendArrowResult>;
  /** Value of dial described by formatted string, type and sentiment */
  valueText: ValueTextResult;
};

export type EditUserAdditionalFiltersInput = {
  userCustomAdditionalFilters: Array<UserAdditionalFilterInput>;
};

export type ExternalLink = {
  __typename?: 'ExternalLink';
  /** Display text */
  displayText?: Maybe<Scalars['String']['output']>;
  /** Url */
  url?: Maybe<Scalars['String']['output']>;
};

export type Filter = {
  /** Type of the filter: Select, MultipleSelect */
  filterType: FilterType;
  /** Filter Identifier. */
  id: Scalars['Uuid']['output'];
  /** Filter title. */
  label?: Maybe<Scalars['String']['output']>;
  /** ParentId to support hierarchy */
  parentId?: Maybe<Scalars['Uuid']['output']>;
};

export type FilterCodeValue = {
  __typename?: 'FilterCodeValue';
  /** Child filter code */
  filterCode: Scalars['String']['output'];
  /** Child filter value */
  value: Scalars['Int']['output'];
};

export type FilterComponent = Component & {
  __typename?: 'FilterComponent';
  /** Component alignment */
  alignment?: Maybe<Alignment>;
  /** Component code */
  code: Scalars['String']['output'];
  componentType: ComponentType;
  defaultValue?: Maybe<Scalars['Long']['output']>;
  /** Display mode for the selected field (inline or default) */
  displayMode?: Maybe<DisplayMode>;
  icon: ComponentIcon;
  id: Scalars['Uuid']['output'];
  /** Is hidden */
  isHidden?: Maybe<Scalars['Boolean']['output']>;
  /** Component position */
  position?: Maybe<Position>;
  title: Scalars['String']['output'];
  viewType: ViewType;
};

export enum FilterIcon {
  ForecastIcon = 'FORECAST_ICON',
  IsAiDriven = 'IS_AI_DRIVEN',
}

export type FilterInput = {
  multiSelectFilter?: InputMaybe<MultiSelectFilterInput>;
  selectFilter?: InputMaybe<SelectFilterInput>;
  selectGroupFilter?: InputMaybe<SelectGroupFilterInput>;
};

export type FilterOptionResult = {
  __typename?: 'FilterOptionResult';
  /** Additional filter option labels */
  additionalLabels?: Maybe<Array<Scalars['String']['output']>>;
  filterCode?: Maybe<Scalars['String']['output']>;
  /** Icon to be added in the filter */
  filterIcon?: Maybe<FilterIcon>;
  /** Option unique ID. */
  id: Scalars['ID']['output'];
  /** Flag to indicate whether option is default for filter */
  isDefault?: Maybe<Scalars['Boolean']['output']>;
  /** Flag to indicate whether option is target for filter */
  isTarget?: Maybe<Scalars['Boolean']['output']>;
  /** Option label. */
  label: Scalars['String']['output'];
  numericLabel?: Maybe<Scalars['Decimal']['output']>;
  /** Filter Option Sentiment */
  sentiment?: Maybe<Sentiment>;
  /** Option value */
  value?: Maybe<Scalars['Long']['output']>;
};

export enum FilterType {
  Inputtext = 'INPUTTEXT',
  Multiselect = 'MULTISELECT',
  Range = 'RANGE',
  Select = 'SELECT',
  SelectGroup = 'SELECT_GROUP',
  Switch = 'SWITCH',
  TreeMultiSelect = 'TREE_MULTI_SELECT',
  TreeSelect = 'TREE_SELECT',
}

export type FilterValue = MultiSelectFilterValue | SelectFilterValue | SelectGroupFilterValue;

export type FiltersComponent = Component & {
  __typename?: 'FiltersComponent';
  /** Component code */
  code: Scalars['String']['output'];
  componentType: ComponentType;
  filters?: Maybe<Array<FilterComponent>>;
  icon: ComponentIcon;
  id: Scalars['Uuid']['output'];
  title: Scalars['String']['output'];
  viewType: ViewType;
};

export type FinHomePageCardsComponent = Component & {
  __typename?: 'FinHomePageCardsComponent';
  code: Scalars['String']['output'];
  componentType: ComponentType;
  icon: ComponentIcon;
  id: Scalars['Uuid']['output'];
  title: Scalars['String']['output'];
  viewType: ViewType;
};

export type FinHomePageCardsDataView = IDataView & {
  __typename?: 'FinHomePageCardsDataView';
  /** Annotation */
  annotation?: Maybe<Scalars['String']['output']>;
  availableUserSettings: Array<UserSetting>;
  /** Cards */
  cards?: Maybe<Array<Maybe<MicroChartCardResult>>>;
  /** Type of the data view */
  dataViewType: DataViewType;
  editLink?: Maybe<Link>;
  /** Data view identifier */
  id: Scalars['Uuid']['output'];
  /** MetaData */
  metadata?: Maybe<Array<MetaData>>;
  /** Title */
  title?: Maybe<Scalars['String']['output']>;
};

export type FinModuleResult = {
  __typename?: 'FinModuleResult';
  cashFlow: CashflowResult;
  pnl: PnlResult;
};

export type FormattedTextResult = {
  __typename?: 'FormattedTextResult';
  /** Content */
  content: Scalars['String']['output'];
  /** Sentiment */
  sentiment?: Maybe<Sentiment>;
  /** Size */
  size?: Maybe<TextSize>;
};

export type FormattedValue = {
  __typename?: 'FormattedValue';
  /** Formatted value */
  formatted?: Maybe<Scalars['String']['output']>;
  /** Raw value */
  raw?: Maybe<Scalars['Float']['output']>;
  title: Scalars['String']['output'];
};

export type GeolocationCoordinatesResult = {
  __typename?: 'GeolocationCoordinatesResult';
  /** Latitude coordinate */
  latitude?: Maybe<Scalars['Float']['output']>;
  /** Longitude coordinate */
  longitude?: Maybe<Scalars['Float']['output']>;
};

export type GeolocationPositionResult = {
  __typename?: 'GeolocationPositionResult';
  /** Geolocation position coordinates */
  coordinates?: Maybe<GeolocationCoordinatesResult>;
};

export type GetStudyDesignByIdInput = {
  id: Scalars['Int']['input'];
};

export type GetUserSettingsInput = {
  moduleId: Scalars['Uuid']['input'];
};

export type GetUsersInput = {
  searchTerm?: InputMaybe<Scalars['String']['input']>;
  skip: Scalars['Int']['input'];
  take: Scalars['Int']['input'];
};

export type GlocalPulseActionDescription = {
  __typename?: 'GlocalPulseActionDescription';
  /** Content of the action description */
  content: Scalars['String']['output'];
  /** Subtitle of the action description */
  subtitle: Scalars['String']['output'];
  /** Title of the action description */
  title: Scalars['String']['output'];
};

export type GlocalPulseDriversChartHeader = {
  __typename?: 'GlocalPulseDriversChartHeader';
  label: Scalars['String']['output'];
  score: Scalars['Int']['output'];
  sentiment: Sentiment;
  subLabel: Scalars['String']['output'];
};

export type GlocalPulseDriversFilterDataView = {
  __typename?: 'GlocalPulseDriversFilterDataView';
  /** Key Driver Filter Data */
  timeFilter: SelectFilter;
};

export type GlocalPulseDriversFilterDataViewTimeFilterArgs = {
  input?: InputMaybe<SpendGlocalPulseKeyDriverFilterDataInput>;
};

export type GlocalPulseKeyDriversResult = {
  __typename?: 'GlocalPulseKeyDriversResult';
  /** Dependent filters */
  filters: GlocalPulseDriversFilterDataView;
  /** Glocal Pulse KeyDrivers Chart Data */
  keyDriversChart?: Maybe<SpendGlocalPulseDriversChartDataView>;
  /** Glocal Pulse KeyDrivers List Data */
  keyDriversList?: Maybe<SpendGlocalPulseDriversListDataView>;
};

export type GlocalPulseKeyDriversResultKeyDriversChartArgs = {
  input?: InputMaybe<SpendGlocalPulseDriversChartDataInput>;
};

export type GlocalPulseKeyDriversResultKeyDriversListArgs = {
  input?: InputMaybe<SpendGlocalPulseDriversListDataInput>;
};

export type GlocalPulseLeaderboardListFilterResult = {
  __typename?: 'GlocalPulseLeaderboardListFilterResult';
  /** Business Unit Filter Data */
  businessUnit: SelectFilter;
  /** Key Driver Filter Data */
  keyDriver: SelectFilter;
};

export type GlocalPulseLeaderboardListFilterResultBusinessUnitArgs = {
  input?: InputMaybe<SpendGlocalPulseBusinessUnitFilterDataInput>;
};

export type GlocalPulseLeaderboardListFilterResultKeyDriverArgs = {
  input?: InputMaybe<SpendGlocalPulseKeyDriverFilterDataInput>;
};

export type GlocalPulseLeaderboardListResult = {
  __typename?: 'GlocalPulseLeaderboardListResult';
  /** Dependent filters */
  filters: GlocalPulseLeaderboardListFilterResult;
  /** Glocal Pulse Leaderboard List Data */
  leaderboardListData?: Maybe<SpendGlocalPulseLeaderboardListDataView>;
};

export type GlocalPulseLeaderboardListResultLeaderboardListDataArgs = {
  input?: InputMaybe<SpendGlocalPulseLeaderboardDataInput>;
};

export type GlocalPulseLeaderboardPositionDataView = IDataView & {
  __typename?: 'GlocalPulseLeaderboardPositionDataView';
  availableUserSettings: Array<UserSetting>;
  /** Type of the data view */
  dataViewType: DataViewType;
  /** Direction for the highlighted element trend arrow */
  direction?: Maybe<Direction>;
  /** Data view identifier */
  id: Scalars['Uuid']['output'];
  /** Label of the highlighted element */
  label?: Maybe<Scalars['String']['output']>;
  /** Link configuration */
  leaderboardPageLink?: Maybe<Link>;
  metadata?: Maybe<Array<MetaData>>;
  /** Message to display when no data is available for selection */
  noDataMessage?: Maybe<SpendNoDataMessage>;
  /** Sentiment for the highlighted element */
  sentiment?: Maybe<Sentiment>;
  /** Score of the highlighted element */
  value?: Maybe<Scalars['String']['output']>;
};

export type GlocalPulseRecommendedAction = {
  __typename?: 'GlocalPulseRecommendedAction';
  /** ActionDescription of the recommended action */
  glocalPulseAction: GlocalPulseActionDescription;
  /** Summary of the recommended action */
  summary: Scalars['String']['output'];
  /** Title of the recommended action */
  title: Scalars['String']['output'];
};

export type GlocalPulseRecommendedActionsDataView = IDataView & {
  __typename?: 'GlocalPulseRecommendedActionsDataView';
  availableUserSettings: Array<UserSetting>;
  /** Type of the data view */
  dataViewType: DataViewType;
  /** Data view identifier */
  id: Scalars['Uuid']['output'];
  lastUpdateDate?: Maybe<Scalars['String']['output']>;
  metadata: Array<MetaData>;
  /** Message to display when no data is available for selection */
  noDataMessage?: Maybe<SpendNoDataMessage>;
  /** Recommended actions list */
  recommendedActionsList?: Maybe<Array<GlocalPulseRecommendedAction>>;
  /** Subtitle of the recommended actions */
  subtitle?: Maybe<Scalars['String']['output']>;
  /** Title of the recommended actions */
  title?: Maybe<Scalars['String']['output']>;
};

export type GlocalPulseResult = {
  __typename?: 'GlocalPulseResult';
  /** Glocal Pulse Dial Area Data */
  dial: SpendGlocalPulseDialDataView;
  /** Glocal Pulse Leaderboard Position Data */
  glocalPulseLeaderboardPosition: GlocalPulseLeaderboardPositionDataView;
  /** Glocal Pulse Recommended Actions Data */
  glocalPulseRecommendedActions: GlocalPulseRecommendedActionsDataView;
};

export type GlocalPulseResultDialArgs = {
  input?: InputMaybe<SpendGlocalPulseDataInput>;
};

export type GlocalPulseResultGlocalPulseLeaderboardPositionArgs = {
  input?: InputMaybe<SpendGlocalPulseDataInput>;
};

export type GlocalPulseResultGlocalPulseRecommendedActionsArgs = {
  input?: InputMaybe<SpendGlocalPulseDataInput>;
};

export type GoalResultType = {
  __typename?: 'GoalResultType';
  /** Goal Id */
  goalId: Scalars['Int']['output'];
  /** Goal Label */
  goalLongLabel: Scalars['String']['output'];
};

export type GraCardDataView = IDataView & {
  __typename?: 'GraCardDataView';
  /** Annotation */
  annotation?: Maybe<Scalars['String']['output']>;
  availableUserSettings: Array<UserSetting>;
  /** Cards */
  cards?: Maybe<Array<Maybe<GraHomeCardResult>>>;
  /** Type of the data view */
  dataViewType: DataViewType;
  /** Edit link */
  editLink?: Maybe<Link>;
  /** Data view identifier */
  id: Scalars['Uuid']['output'];
  metadata?: Maybe<Array<MetaData>>;
  /** Title */
  title?: Maybe<Scalars['String']['output']>;
};

export type GraDialItem = {
  __typename?: 'GraDialItem';
  dialItem?: Maybe<DialItem>;
};

export type GraFacilitatedPathwaysTableComponent = Component & {
  __typename?: 'GraFacilitatedPathwaysTableComponent';
  code: Scalars['String']['output'];
  componentType: ComponentType;
  filters?: Maybe<Array<FilterComponent>>;
  icon: ComponentIcon;
  id: Scalars['Uuid']['output'];
  title: Scalars['String']['output'];
  viewType: ViewType;
};

export type GraFacilitatedPathwaysTableDataView = IDataView & {
  __typename?: 'GraFacilitatedPathwaysTableDataView';
  availableUserSettings: Array<UserSetting>;
  columns?: Maybe<Array<TableColumnResult>>;
  dataViewType: DataViewType;
  /** Dependent filters */
  filters?: Maybe<Array<FilterValue>>;
  id: Scalars['Uuid']['output'];
  lastUpdateDate: Scalars['String']['output'];
  metadata: Array<MetaData>;
  rows?: Maybe<Array<Maybe<TableRowResult>>>;
};

export type GraHomeCardResult = {
  __typename?: 'GraHomeCardResult';
  /** Card AbsoluteYtd value */
  absoluteYtd: Scalars['String']['output'];
  id: Scalars['String']['output'];
  /** Link result */
  linkResult?: Maybe<Link>;
  /** Card PctVsPy value */
  pctVsPy: Scalars['String']['output'];
  /** Card PctVsPy variance value */
  pctVsPySentiment: Sentiment;
  priorityCode: Scalars['String']['output'];
  /** Card subtitle */
  subtitle: Scalars['String']['output'];
  /** Card title */
  title: Scalars['String']['output'];
};

export type GraHomeCardsComponent = Component & {
  __typename?: 'GraHomeCardsComponent';
  /** Component code */
  code: Scalars['String']['output'];
  componentType: ComponentType;
  filters?: Maybe<Array<FilterComponent>>;
  icon: ComponentIcon;
  id: Scalars['Uuid']['output'];
  title: Scalars['String']['output'];
  viewType: ViewType;
};

export type GraHomeDialsComponent = Component & {
  __typename?: 'GraHomeDialsComponent';
  /** Component code */
  code: Scalars['String']['output'];
  componentType: ComponentType;
  filters?: Maybe<Array<FilterComponent>>;
  icon: ComponentIcon;
  id: Scalars['Uuid']['output'];
  title: Scalars['String']['output'];
  viewType: ViewType;
};

export type GraHomeDialsDataView = IDataView & {
  __typename?: 'GraHomeDialsDataView';
  annotation?: Maybe<Scalars['String']['output']>;
  availableUserSettings: Array<UserSetting>;
  /** Type of the data view */
  dataViewType: DataViewType;
  id: Scalars['Uuid']['output'];
  /** Dial items */
  items?: Maybe<Array<GraDialItem>>;
  metadata?: Maybe<Array<MetaData>>;
  title: Scalars['String']['output'];
};

export type GraRecommendedPathwaysDetailsComponent = Component & {
  __typename?: 'GraRecommendedPathwaysDetailsComponent';
  code: Scalars['String']['output'];
  componentType: ComponentType;
  filters?: Maybe<Array<FilterComponent>>;
  icon: ComponentIcon;
  id: Scalars['Uuid']['output'];
  title: Scalars['String']['output'];
  viewType: ViewType;
};

export type GraRecommendedPathwaysDetailsDataView = IDataView & {
  __typename?: 'GraRecommendedPathwaysDetailsDataView';
  availableUserSettings: Array<UserSetting>;
  data: RecommendedPathwaysDetailsData;
  dataViewType: DataViewType;
  /** Dependant filters */
  filters?: Maybe<Array<FilterValue>>;
  id: Scalars['Uuid']['output'];
  metadata: Array<MetaData>;
};

export type GraRecommendedPathwaysTableComponent = Component & {
  __typename?: 'GraRecommendedPathwaysTableComponent';
  code: Scalars['String']['output'];
  componentType: ComponentType;
  drillIds: Array<Scalars['Int']['output']>;
  filters?: Maybe<Array<FilterComponent>>;
  icon: ComponentIcon;
  id: Scalars['Uuid']['output'];
  title: Scalars['String']['output'];
  viewType: ViewType;
};

export type GraRecommendedPathwaysTableDataView = IDataView & {
  __typename?: 'GraRecommendedPathwaysTableDataView';
  availableUserSettings: Array<UserSetting>;
  columns?: Maybe<Array<TableColumnResult>>;
  dataViewType: DataViewType;
  /** Dependent filters */
  filters?: Maybe<Array<FilterValue>>;
  id: Scalars['Uuid']['output'];
  lastUpdateDate: Scalars['String']['output'];
  metadata: Array<MetaData>;
  rows?: Maybe<Array<Maybe<TableRowResult>>>;
};

export type GraphicElementResult = {
  __typename?: 'GraphicElementResult';
  /** Color of element */
  color?: Maybe<Scalars['String']['output']>;
  /** Shape of element */
  shape?: Maybe<ShapesEnum>;
};

export type GridComponent = Component & {
  __typename?: 'GridComponent';
  /** Component code */
  code: Scalars['String']['output'];
  componentType: ComponentType;
  filters?: Maybe<Array<FilterComponent>>;
  icon: ComponentIcon;
  id: Scalars['Uuid']['output'];
  title: Scalars['String']['output'];
  viewType: ViewType;
};

export type GridDataView = IDataView & {
  __typename?: 'GridDataView';
  /** Annotations */
  annotation?: Maybe<Scalars['String']['output']>;
  availableUserSettings: Array<UserSetting>;
  /** Grid columns */
  columns?: Maybe<Array<TableColumnResult>>;
  /** Type of the data view */
  dataViewType: DataViewType;
  /** Edit link */
  editLink?: Maybe<Link>;
  /** Dependent filters */
  filters?: Maybe<Array<FilterValue>>;
  /** Data view identifier */
  id: Scalars['Uuid']['output'];
  /** Formatted date of last data update */
  lastUpdateDate?: Maybe<Scalars['String']['output']>;
  /** MetaData */
  metadata?: Maybe<Array<MetaData>>;
  /** Grid options */
  options?: Maybe<TableViewOptionsResult>;
  /** Grid rows */
  rows?: Maybe<Array<TableRowResult>>;
  /** Title */
  title?: Maybe<Scalars['String']['output']>;
};

export type GtmCardDataView = IDataView & {
  __typename?: 'GtmCardDataView';
  /** Annotation */
  annotation?: Maybe<Scalars['String']['output']>;
  availableUserSettings: Array<UserSetting>;
  /** Cards */
  cards?: Maybe<Array<Maybe<GtmHomeCardResult>>>;
  /** Type of the data view */
  dataViewType: DataViewType;
  /** Data view identifier */
  id: Scalars['Uuid']['output'];
  metadata?: Maybe<Array<MetaData>>;
  /** Title */
  title?: Maybe<Scalars['String']['output']>;
};

export type GtmHomeCardResult = {
  __typename?: 'GtmHomeCardResult';
  /** Id */
  id: Scalars['Int']['output'];
  /** Link result */
  linkResult?: Maybe<Link>;
  /** Card sentiment */
  sentiment: Sentiment;
  /** Card subtitle */
  subtitle: Scalars['String']['output'];
  /** Card title */
  title: Scalars['String']['output'];
  /** Card value */
  value: Scalars['String']['output'];
};

export type GtmHomeCardsComponent = Component & {
  __typename?: 'GtmHomeCardsComponent';
  /** Component code */
  code: Scalars['String']['output'];
  componentType: ComponentType;
  filters?: Maybe<Array<FilterComponent>>;
  icon: ComponentIcon;
  id: Scalars['Uuid']['output'];
  title: Scalars['String']['output'];
  viewType: ViewType;
};

export type GtmHomeDial = {
  __typename?: 'GtmHomeDial';
  /** Float value for progress (how much circle is filled */
  actual: Scalars['Float']['output'];
  /** Value of dial described by formatted string */
  label: Scalars['String']['output'];
  /** Sentiment of circle which is translated to the color on frontend side */
  sentiment: Sentiment;
  /** Int value of sort order */
  sortOrder?: Maybe<Scalars['Int']['output']>;
  /** Float value of target (white dot inside circle) */
  target?: Maybe<Scalars['Float']['output']>;
  /** Value of dial described by formatted string */
  valueCenter: Scalars['String']['output'];
};

export type GtmHomeDialComponent = Component & {
  __typename?: 'GtmHomeDialComponent';
  /** Component code */
  code: Scalars['String']['output'];
  componentType: ComponentType;
  filters?: Maybe<Array<FilterComponent>>;
  icon: ComponentIcon;
  id: Scalars['Uuid']['output'];
  title: Scalars['String']['output'];
  viewType: ViewType;
};

export type GtmHomeDialDataView = IDataView & {
  __typename?: 'GtmHomeDialDataView';
  /** A information to be displayed along with the annotation */
  annotation?: Maybe<Scalars['String']['output']>;
  /** A date information to be displayed along with the annotation */
  annotationDate?: Maybe<Scalars['String']['output']>;
  availableUserSettings: Array<UserSetting>;
  /** Type of the data view */
  dataViewType: DataViewType;
  /** Data view identifier */
  id: Scalars['Uuid']['output'];
  /** Dial items */
  items?: Maybe<Array<DialItem>>;
  lastUpdateDate?: Maybe<Scalars['String']['output']>;
  metadata?: Maybe<Array<MetaData>>;
  /** Title */
  title?: Maybe<Scalars['String']['output']>;
};

export type GtmImpactRecommendationComponent = Component & {
  __typename?: 'GtmImpactRecommendationComponent';
  /** Component code */
  code: Scalars['String']['output'];
  componentType: ComponentType;
  filters?: Maybe<Array<FilterComponent>>;
  icon: ComponentIcon;
  id: Scalars['Uuid']['output'];
  title: Scalars['String']['output'];
  viewType: ViewType;
};

export type GtmImpactRecommendationDataView = IDataView & {
  __typename?: 'GtmImpactRecommendationDataView';
  availableUserSettings: Array<UserSetting>;
  dataViewType: DataViewType;
  /** Description of recommendation */
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Uuid']['output'];
  metadata?: Maybe<Array<MetaData>>;
  /** Title of recommendation */
  title?: Maybe<Scalars['String']['output']>;
};

export type GtmImpactTableComponent = Component & {
  __typename?: 'GtmImpactTableComponent';
  code: Scalars['String']['output'];
  componentType: ComponentType;
  icon: ComponentIcon;
  id: Scalars['Uuid']['output'];
  title: Scalars['String']['output'];
  viewType: ViewType;
};

export type GtmImpactTableDataView = IDataView & {
  __typename?: 'GtmImpactTableDataView';
  availableUserSettings: Array<UserSetting>;
  /** Table columns */
  columns?: Maybe<Array<TableColumnResult>>;
  /** Type of the data view */
  dataViewType: DataViewType;
  /** Dependent filters */
  filters?: Maybe<Array<FilterValue>>;
  /** Data view identifier */
  id: Scalars['Uuid']['output'];
  /** Formatted date of last data update */
  lastUpdateDate?: Maybe<Scalars['String']['output']>;
  /** MetaData */
  metadata?: Maybe<Array<MetaData>>;
  /** Table rows */
  rows?: Maybe<Array<Maybe<TableRowResult>>>;
};

export type GtmImpactTableHeaderComponent = Component & {
  __typename?: 'GtmImpactTableHeaderComponent';
  /** Component code */
  code: Scalars['String']['output'];
  componentType: ComponentType;
  filters?: Maybe<Array<FilterComponent>>;
  icon: ComponentIcon;
  id: Scalars['Uuid']['output'];
  title: Scalars['String']['output'];
  viewType: ViewType;
};

export type GtmImpactTableHeaderDataView = IDataView & {
  __typename?: 'GtmImpactTableHeaderDataView';
  availableUserSettings: Array<UserSetting>;
  /** Table header columns */
  columns?: Maybe<Array<TableColumnResult>>;
  /** Type of the data view */
  dataViewType: DataViewType;
  /** Data view identifier */
  id: Scalars['Uuid']['output'];
  /** MetaData */
  metadata?: Maybe<Array<MetaData>>;
  options: TableViewOptionsResult;
  /** Table header row */
  rows?: Maybe<Array<TableRowResult>>;
};

export type GtmLeaderboardHeader = {
  __typename?: 'GtmLeaderboardHeader';
  direction: Direction;
  label?: Maybe<Scalars['String']['output']>;
  points: Scalars['String']['output'];
  rank: Scalars['String']['output'];
  sentiment: Sentiment;
  value: Scalars['String']['output'];
};

export type GtmPulseDetailsChartComponent = Component & {
  __typename?: 'GtmPulseDetailsChartComponent';
  /** Component code */
  code: Scalars['String']['output'];
  componentType: ComponentType;
  filters?: Maybe<Array<FilterComponent>>;
  icon: ComponentIcon;
  id: Scalars['Uuid']['output'];
  title: Scalars['String']['output'];
  viewType: ViewType;
};

export type GtmPulseDetailsChartHeaderComponent = Component & {
  __typename?: 'GtmPulseDetailsChartHeaderComponent';
  /** Component code */
  code: Scalars['String']['output'];
  componentType: ComponentType;
  icon: ComponentIcon;
  id: Scalars['Uuid']['output'];
  title: Scalars['String']['output'];
  viewType: ViewType;
};

export type GtmPulseDetailsChartHeaderDataView = IDataView & {
  __typename?: 'GtmPulseDetailsChartHeaderDataView';
  availableUserSettings: Array<UserSetting>;
  /** Type of the data view */
  dataViewType: DataViewType;
  /** Component identificator */
  id: Scalars['Uuid']['output'];
  /** Header label */
  label: Scalars['String']['output'];
  metadata?: Maybe<Array<MetaData>>;
  /** Header sub label */
  subLabel: Scalars['String']['output'];
  /** Value of pulse */
  value: ValueTextResult;
};

export type GtmPulseDetailsDriversComponent = Component & {
  __typename?: 'GtmPulseDetailsDriversComponent';
  code: Scalars['String']['output'];
  componentType: ComponentType;
  icon: ComponentIcon;
  id: Scalars['Uuid']['output'];
  title: Scalars['String']['output'];
  viewType: ViewType;
};

export type GtmPulseDetailsDriversDataView = IDataView & {
  __typename?: 'GtmPulseDetailsDriversDataView';
  availableUserSettings: Array<UserSetting>;
  /** Type of the data view */
  dataViewType: DataViewType;
  /** Drivers for GTM */
  drivers?: Maybe<Array<Maybe<GtmPulseDetailsKeyDriverResult>>>;
  /** Data view identifier */
  id: Scalars['Uuid']['output'];
  /** Key Drivers Title */
  keyDriversTitle: Scalars['String']['output'];
  /** Last Update Date */
  lastUpdateDate?: Maybe<Scalars['String']['output']>;
  /** Metadata */
  metadata?: Maybe<Array<MetaData>>;
};

export type GtmPulseDetailsKeyDriverResult = {
  __typename?: 'GtmPulseDetailsKeyDriverResult';
  /** Children drivers */
  children?: Maybe<Array<Maybe<GtmPulseDetailsKeyDriverResult>>>;
  /** Driver identifier */
  id: Scalars['Int']['output'];
  /** Label of driver */
  label: Scalars['String']['output'];
  /** Previous value of driver */
  previousValue: ValueTextResult;
  /** Reference label */
  referenceLabel: Scalars['String']['output'];
  /** Sentiment for number which is translated to the color on frontend side */
  sentiment: Sentiment;
  /** SubLabel of driver */
  subLabel: Scalars['String']['output'];
  /** Trend arrow which displays business status of the score */
  trend: TrendArrowResult;
  /** Value of driver */
  value: ValueTextResult;
};

export type GtmPulseDial = {
  __typename?: 'GtmPulseDial';
  /** Median Label */
  medianLabel: Scalars['String']['output'];
  /** Median Value */
  medianValue: Scalars['String']['output'];
  /** Pulse Label */
  pulseLabel: Scalars['String']['output'];
  /** Pulse Sentiment */
  pulseSentiment: Sentiment;
  /** Pulse Value */
  pulseValue: Scalars['String']['output'];
};

export type GtmPulseDialComponent = Component & {
  __typename?: 'GtmPulseDialComponent';
  code: Scalars['String']['output'];
  componentType: ComponentType;
  icon: ComponentIcon;
  id: Scalars['Uuid']['output'];
  title: Scalars['String']['output'];
  viewType: ViewType;
};

export type GtmPulseDialDataView = IDataView & {
  __typename?: 'GtmPulseDialDataView';
  availableUserSettings: Array<UserSetting>;
  /** Type of the data view */
  dataViewType: DataViewType;
  /** Gauge */
  dial?: Maybe<GtmPulseDial>;
  /** Data view identifier */
  id: Scalars['Uuid']['output'];
  metadata?: Maybe<Array<MetaData>>;
};

export type GtmPulseLeaderboardCompetitor = {
  __typename?: 'GtmPulseLeaderboardCompetitor';
  /** IsSelectedPriority */
  isSelectedPriority: Scalars['Boolean']['output'];
  /** Name */
  name: Scalars['String']['output'];
  /** Value */
  rank: ValueTextResult;
  /** Score */
  score: ValueTextResult;
  /** TrendArrow */
  trendArrow?: Maybe<TrendArrowResult>;
};

export type GtmPulseLeaderboardListComponent = Component & {
  __typename?: 'GtmPulseLeaderboardListComponent';
  code: Scalars['String']['output'];
  componentType: ComponentType;
  filters: Array<FilterComponent>;
  icon: ComponentIcon;
  id: Scalars['Uuid']['output'];
  title: Scalars['String']['output'];
  viewType: ViewType;
};

export type GtmPulseLeaderboardListDataView = IDataView & {
  __typename?: 'GtmPulseLeaderboardListDataView';
  availableUserSettings: Array<UserSetting>;
  /** Competitors */
  competitors?: Maybe<Array<Maybe<GtmPulseLeaderboardCompetitor>>>;
  /** Type of the data view */
  dataViewType: DataViewType;
  /** Leaderboard Headers */
  header?: Maybe<GtmLeaderboardHeader>;
  /** Data view identifier */
  id: Scalars['Uuid']['output'];
  /** Last Update Date */
  lastUpdateDate?: Maybe<Scalars['String']['output']>;
  /** Metadata */
  metadata?: Maybe<Array<MetaData>>;
  subTitle?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};

export type GtmPulseLeaderboardPositionComponent = Component & {
  __typename?: 'GtmPulseLeaderboardPositionComponent';
  code: Scalars['String']['output'];
  componentType: ComponentType;
  icon: ComponentIcon;
  id: Scalars['Uuid']['output'];
  title: Scalars['String']['output'];
  viewType: ViewType;
};

export type GtmPulseLeaderboardPositionDataView = IDataView & {
  __typename?: 'GtmPulseLeaderboardPositionDataView';
  availableUserSettings: Array<UserSetting>;
  /** Type of the data view */
  dataViewType: DataViewType;
  /** Data view identifier */
  id: Scalars['Uuid']['output'];
  /** Link configuration */
  leaderboardPageLink?: Maybe<Link>;
  metadata?: Maybe<Array<MetaData>>;
  /** Position Direction */
  positionDirection?: Maybe<Direction>;
  /** Position Sentiment */
  positionSentiment?: Maybe<Sentiment>;
  /** Position Value */
  positionValue?: Maybe<Scalars['String']['output']>;
};

export type GtmPulseRecommendedActions = {
  __typename?: 'GtmPulseRecommendedActions';
  /** Description */
  description: Scalars['String']['output'];
  items?: Maybe<Array<GtmPulseRecommendedActions>>;
  /** Title */
  title: Scalars['String']['output'];
};

export type GtmPulseRecommendedActionsComponent = Component & {
  __typename?: 'GtmPulseRecommendedActionsComponent';
  code: Scalars['String']['output'];
  componentType: ComponentType;
  icon: ComponentIcon;
  id: Scalars['Uuid']['output'];
  title: Scalars['String']['output'];
  viewType: ViewType;
};

export type GtmPulseRecommendedActionsDataView = IDataView & {
  __typename?: 'GtmPulseRecommendedActionsDataView';
  availableUserSettings: Array<UserSetting>;
  /** Type of the data view */
  dataViewType: DataViewType;
  /** Data view identifier */
  id: Scalars['Uuid']['output'];
  /** Last Update Date */
  lastUpdateDate?: Maybe<Scalars['String']['output']>;
  /** Metadata */
  metadata?: Maybe<Array<MetaData>>;
  /** Recommendation */
  recommendedActions?: Maybe<GtmPulseRecommendedActions>;
};

export type GtmResourceAllocationTableComponent = Component & {
  __typename?: 'GtmResourceAllocationTableComponent';
  /** Component code */
  code: Scalars['String']['output'];
  componentType: ComponentType;
  drillIds: Array<Scalars['Long']['output']>;
  filters?: Maybe<Array<FilterComponent>>;
  icon: ComponentIcon;
  id: Scalars['Uuid']['output'];
  title: Scalars['String']['output'];
  viewType: ViewType;
};

export type GtmResourceAllocationTableDataView = IDataView & {
  __typename?: 'GtmResourceAllocationTableDataView';
  availableUserSettings: Array<UserSetting>;
  /** Table columns */
  columns?: Maybe<Array<TableColumnResult>>;
  /** Type of the data view */
  dataViewType: DataViewType;
  filters?: Maybe<Array<FilterValue>>;
  /** Data view identifier */
  id: Scalars['Uuid']['output'];
  lastUpdateDate?: Maybe<Scalars['String']['output']>;
  /** MetaData */
  metadata?: Maybe<Array<MetaData>>;
  /** Table rows */
  rows?: Maybe<Array<Maybe<TableRowResult>>>;
};

export type HeaderRow = IDataView & {
  __typename?: 'HeaderRow';
  availableUserSettings: Array<UserSetting>;
  /** Header columns */
  columns?: Maybe<Array<TableColumnResult>>;
  /** Type of the data view */
  dataViewType: DataViewType;
  /** Selected filters for chart header */
  filters?: Maybe<Array<FilterValue>>;
  /** Data view identifier */
  id: Scalars['Uuid']['output'];
  /** MetaData */
  metadata?: Maybe<Array<MetaData>>;
  options: TableViewOptionsResult;
  /** Chart header row */
  rows?: Maybe<Array<Maybe<TableRowResult>>>;
};

export type HeaderRowComponent = Component & {
  __typename?: 'HeaderRowComponent';
  /** Component code */
  code: Scalars['String']['output'];
  componentType: ComponentType;
  /** Drill ids */
  drillIds?: Maybe<Array<Scalars['Long']['output']>>;
  filters?: Maybe<Array<FilterComponent>>;
  icon: ComponentIcon;
  id: Scalars['Uuid']['output'];
  title: Scalars['String']['output'];
  /** Variant of header row */
  variant: DisplayVariant;
  viewType: ViewType;
};

export type HeaderSimple = IDataView & {
  __typename?: 'HeaderSimple';
  /** Annotation */
  annotation?: Maybe<Scalars['String']['output']>;
  availableUserSettings: Array<UserSetting>;
  /** Type of the data view */
  dataViewType: DataViewType;
  /** Data view identifier */
  id: Scalars['Uuid']['output'];
  /** Items for header */
  items: Array<Maybe<ValueTextResult>>;
  metadata?: Maybe<Array<MetaData>>;
  /** Title */
  title?: Maybe<Scalars['String']['output']>;
};

export type HeaderSimpleComponent = Component & {
  __typename?: 'HeaderSimpleComponent';
  code: Scalars['String']['output'];
  componentType: ComponentType;
  icon: ComponentIcon;
  id: Scalars['Uuid']['output'];
  title: Scalars['String']['output'];
  viewType: ViewType;
};

export type HomeResult = {
  __typename?: 'HomeResult';
  feed?: Maybe<ProHomeFeedDataView>;
};

export type HomeResultFeedArgs = {
  input?: InputMaybe<ProHomeFeedDataInput>;
};

export type IDataView = {
  availableUserSettings: Array<UserSetting>;
  dataViewType: DataViewType;
  id: Scalars['Uuid']['output'];
  metadata?: Maybe<Array<MetaData>>;
};

export enum Icon {
  Ai = 'AI',
  Analyzer = 'ANALYZER',
  Calendar = 'CALENDAR',
  Euro = 'EURO',
  OpenInNew = 'OPEN_IN_NEW',
  People = 'PEOPLE',
  Rhombus = 'RHOMBUS',
}

export type IconLinkResult = {
  __typename?: 'IconLinkResult';
  /** Icon to display */
  icon?: Maybe<Icon>;
  /** Link result */
  linkResult?: Maybe<Link>;
  /** Show or hide icon */
  showIcon?: Maybe<Scalars['Boolean']['output']>;
};

export type IconsLinkResult = {
  __typename?: 'IconsLinkResult';
  /** Icons */
  icons?: Maybe<Array<Scalars['String']['output']>>;
  /** Forward link */
  linkResult?: Maybe<Link>;
  /** SubLabels content */
  subLabels?: Maybe<Array<Scalars['String']['output']>>;
  /** Text content */
  value?: Maybe<Scalars['String']['output']>;
};

export type ImageResult = Media & {
  __typename?: 'ImageResult';
  id: Scalars['Uuid']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  lqipUrl?: Maybe<Scalars['String']['output']>;
  thumbnailUrl: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export enum InboxContentType {
  Real = 'REAL',
  Story = 'STORY',
}

export type InboxItemTag = {
  __typename?: 'InboxItemTag';
  isDefault: Scalars['Boolean']['output'];
  message: Scalars['String']['output'];
  type: TagType;
};

export type InboxResult = {
  __typename?: 'InboxResult';
  decisions?: Maybe<Array<Maybe<DecisionItem>>>;
  messages?: Maybe<MessagesResult>;
  yourBrain?: Maybe<Array<Maybe<YourBrainItem>>>;
};

export type InsightType = Real | Story;

export enum InsightsType {
  Real = 'REAL',
  Story = 'STORY',
}

export enum InterceptActionType {
  Closed = 'CLOSED',
  Dismissed = 'DISMISSED',
  Followed = 'FOLLOWED',
  Seen = 'SEEN',
}

export type InterceptResult = {
  __typename?: 'InterceptResult';
  cancelText?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Uuid']['output'];
  link?: Maybe<Link>;
  linkText?: Maybe<Scalars['String']['output']>;
  media?: Maybe<ImageResult>;
  name?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type InterceptResultType = {
  __typename?: 'InterceptResultType';
  /** Country label */
  countryLabel: Scalars['String']['output'];
  /** Filter Code */
  filterCode: Scalars['String']['output'];
  /** Goal Id */
  goalId: Scalars['Int']['output'];
  /** Goal Label */
  goalLongLabel: Scalars['String']['output'];
  /** Intercept update date */
  interceptUpdate: Scalars['Date']['output'];
  /** Is Visible */
  isVisible: Scalars['Boolean']['output'];
  /** Intercept last access date */
  lastAccessDate?: Maybe<Scalars['Date']['output']>;
  /** Intercept update date */
  numberOfConsecutiveDaysActive: Scalars['Int']['output'];
  /** Product label */
  productLabel: Scalars['String']['output'];
};

export type InterceptSubscriptionInput = {
  originName: Scalars['String']['input'];
  userId: Scalars['Uuid']['input'];
};

export type InventoryResult = {
  __typename?: 'InventoryResult';
  /** Fetch Inventory root causes data */
  rootCausesData: RndInventoryRootCausesDataView;
};

export type InventoryResultRootCausesDataArgs = {
  input: RndInventoryRootCausesDataInput;
};

export type KpiCardResult = Card & {
  __typename?: 'KpiCardResult';
  /** Card Type */
  cardType: CardType;
  code: Scalars['String']['output'];
  componentIcon: ComponentIcon;
  /** Data view identifier */
  id: Scalars['String']['output'];
  /** Kpi statuses */
  items?: Maybe<Array<KpiStatus>>;
  /** Link result */
  linkResult?: Maybe<Link>;
  /** Card title */
  title: TextResult;
};

export type KpiComponent = Component & {
  __typename?: 'KpiComponent';
  /** Component code */
  code: Scalars['String']['output'];
  componentType: ComponentType;
  filters?: Maybe<Array<FilterComponent>>;
  icon: ComponentIcon;
  id: Scalars['Uuid']['output'];
  title: Scalars['String']['output'];
  viewType: ViewType;
};

export type KpiContent = {
  __typename?: 'KpiContent';
  previousValue: Scalars['String']['output'];
  sentiment: Sentiment;
  text: Scalars['String']['output'];
  trendDirection: Direction;
  trendSentiment: Sentiment;
  value: Scalars['String']['output'];
};

export type KpiStatus = {
  __typename?: 'KpiStatus';
  actual: Scalars['String']['output'];
  icon: Icon;
  /** Icon link */
  iconLink?: Maybe<IconLinkResult>;
  sentiment: Sentiment;
  target?: Maybe<Scalars['String']['output']>;
  /** @deprecated Field is replaced by Actual field. Please use it instead */
  text?: Maybe<Scalars['String']['output']>;
};

export type KpiStatusOverview = IDataView & {
  __typename?: 'KpiStatusOverview';
  availableUserSettings: Array<UserSetting>;
  /** Type of the data view */
  dataViewType: DataViewType;
  /** Data view identifier */
  id: Scalars['Uuid']['output'];
  /** Kpi statuses */
  items?: Maybe<Array<KpiStatus>>;
  /** Link */
  link?: Maybe<Link>;
  /** MetaData */
  metadata?: Maybe<Array<MetaData>>;
  /** Kpi's tooltip */
  tooltip: TableContent;
};

export type KpisResult = {
  __typename?: 'KpisResult';
  /** Dependent filters */
  filters?: Maybe<Array<SelectFilter>>;
  /** Fetch KPIs data for a specific time period */
  kpisCategoriesData: RndTrialsKpiCategoriesDataView;
  /** Detailed KPIs data for a specific time period */
  kpisDetailTableData: RndTrialsKpiDetailTableDataView;
};

export type KpisResultFiltersArgs = {
  input: RndTrialsKpiTimeFilterDataInput;
};

export type KpisResultKpisCategoriesDataArgs = {
  input: RndTrialsKpiCategoriesDataInput;
};

export type KpisResultKpisDetailTableDataArgs = {
  input: RndTrialsKpiDetailTableDataInput;
};

export type LeaderboardHeader = {
  __typename?: 'LeaderboardHeader';
  direction: Direction;
  label: Scalars['String']['output'];
  points: Scalars['String']['output'];
  sentiment: Sentiment;
  value: Scalars['String']['output'];
};

export type LeaderboardPositionResult = {
  __typename?: 'LeaderboardPositionResult';
  /** Label */
  label: Scalars['String']['output'];
  /** Progress */
  progressIndicator: Direction;
  /** Rank */
  rank: FormattedTextResult;
  /** Value */
  value: FormattedTextResult;
};

export type LeaderboardResult = {
  __typename?: 'LeaderboardResult';
  /** Current position */
  currentPosition: LeaderboardPositionResult;
  /** Description value */
  descriptionValue?: Maybe<Scalars['String']['output']>;
  /** Positions */
  positions: Array<Maybe<LeaderboardPositionResult>>;
  /** Subtitle */
  subtitle: Scalars['String']['output'];
  /** Title */
  title: Scalars['String']['output'];
};

export type LegacyLink = {
  __typename?: 'LegacyLink';
  /** Filters */
  filters?: Maybe<Array<Maybe<LegacyLinkFilter>>>;
  /** Focus area code */
  focusAreaCode?: Maybe<Scalars['String']['output']>;
  /** Module code */
  moduleCode?: Maybe<Scalars['String']['output']>;
  /** Page code */
  pageCode?: Maybe<Scalars['String']['output']>;
  /** Path */
  path?: Maybe<Scalars['String']['output']>;
  /** Priority */
  priorityValue?: Maybe<Scalars['Long']['output']>;
  /** Query params */
  queryParams?: Maybe<Scalars['String']['output']>;
  /** View code */
  viewCode?: Maybe<Scalars['String']['output']>;
  /** Visible columns */
  visibleColumns?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type LegacyLinkFilter = {
  __typename?: 'LegacyLinkFilter';
  /** Filter code */
  filterCode?: Maybe<Scalars['String']['output']>;
  /** Filter label */
  filterLabel?: Maybe<Scalars['String']['output']>;
  /** Filter string value */
  filterStringValue?: Maybe<Scalars['String']['output']>;
  /** Filter value */
  filterValue?: Maybe<Scalars['Int']['output']>;
};

export type LegendItem = {
  __typename?: 'LegendItem';
  label: Scalars['String']['output'];
  sentiment: Sentiment;
};

export type LikeStoryInput = {
  liked: Scalars['Boolean']['input'];
  storyId: Scalars['Uuid']['input'];
};

export enum LineStyle {
  Dashed = 'DASHED',
  Dasheddotted = 'DASHEDDOTTED',
  Dotted = 'DOTTED',
  None = 'NONE',
  Solid = 'SOLID',
}

export type Link = {
  __typename?: 'Link';
  absolutePath?: Maybe<Scalars['String']['output']>;
  drillId?: Maybe<Scalars['Long']['output']>;
  drillIds?: Maybe<Array<Scalars['Long']['output']>>;
  filters?: Maybe<Array<FilterValue>>;
  pageId?: Maybe<Scalars['Uuid']['output']>;
  plailistId?: Maybe<Scalars['Uuid']['output']>;
  previousPriorityId?: Maybe<Scalars['Long']['output']>;
  target?: Maybe<LinkTargetType>;
};

export enum LinkDirection {
  Back = 'BACK',
  Forward = 'FORWARD',
  None = 'NONE',
}

export enum LinkTargetType {
  Modal = 'MODAL',
  Page = 'PAGE',
}

export type ListComponent = Component & {
  __typename?: 'ListComponent';
  /** Component code */
  code: Scalars['String']['output'];
  componentType: ComponentType;
  filters?: Maybe<Array<FilterComponent>>;
  icon: ComponentIcon;
  id: Scalars['Uuid']['output'];
  title: Scalars['String']['output'];
  viewType: ViewType;
};

export type ListDataView = IDataView & {
  __typename?: 'ListDataView';
  /** Annotations */
  annotation?: Maybe<Scalars['String']['output']>;
  availableUserSettings: Array<UserSetting>;
  /** List item columns */
  columns?: Maybe<Array<TableColumnResult>>;
  /** Type of the data view */
  dataViewType: DataViewType;
  /** Dependent filters */
  filters?: Maybe<Array<FilterValue>>;
  /** Data view identifier */
  id: Scalars['Uuid']['output'];
  /** Formatted date of last data update */
  lastUpdateDate?: Maybe<Scalars['String']['output']>;
  /** MetaData */
  metadata?: Maybe<Array<MetaData>>;
  /** List options/extensions */
  options?: Maybe<TableViewOptionsResult>;
  /** List rows */
  rows?: Maybe<Array<TableRowResult>>;
  /** Title */
  title?: Maybe<Scalars['String']['output']>;
};

export type LocationMarkerResult = {
  __typename?: 'LocationMarkerResult';
  /** Geolocation position */
  position?: Maybe<GeolocationPositionResult>;
  /** SubLabels content */
  subLabels?: Maybe<Array<Scalars['String']['output']>>;
  /** Coordinate text description */
  text?: Maybe<Scalars['String']['output']>;
};

export type MarkStoryAsReadInput = {
  storyId: Scalars['Uuid']['input'];
};

export type MarkdownComponent = Component & {
  __typename?: 'MarkdownComponent';
  /** Component code */
  code: Scalars['String']['output'];
  componentType: ComponentType;
  /** Content of the markdown */
  content: Scalars['String']['output'];
  icon: ComponentIcon;
  id: Scalars['Uuid']['output'];
  title: Scalars['String']['output'];
  viewType: ViewType;
};

export type Media = {
  /** Video id */
  id: Scalars['Uuid']['output'];
  /** Low quality image url. */
  lqipUrl?: Maybe<Scalars['String']['output']>;
  /** Thumbnail image url. */
  thumbnailUrl: Scalars['String']['output'];
  /** Video title. */
  title?: Maybe<Scalars['String']['output']>;
};

export enum MenuIcon {
  AilyLogoOutlined = 'AILY_LOGO_OUTLINED',
  HomeOutlined = 'HOME_OUTLINED',
  LineChartOutlined = 'LINE_CHART_OUTLINED',
  None = 'NONE',
  PlayCircleOutlined = 'PLAY_CIRCLE_OUTLINED',
  SettingsOutlined = 'SETTINGS_OUTLINED',
  StarOutlined = 'STAR_OUTLINED',
}

export type MessagesResult = {
  __typename?: 'MessagesResult';
  getShareablePage?: Maybe<ShareableLink>;
  sharedLinks: Array<SharedLink>;
};

export type MessagesResultGetShareablePageArgs = {
  input?: InputMaybe<ShareLinkInput>;
};

export type MetaData = {
  __typename?: 'MetaData';
  /** Meta info key */
  key?: Maybe<Scalars['String']['output']>;
  /** Meta info label */
  label?: Maybe<Scalars['String']['output']>;
  /** Meta info value */
  value?: Maybe<Scalars['String']['output']>;
};

export type Metric = {
  __typename?: 'Metric';
  /** Metric name */
  id: Scalars['Int']['output'];
  /** Metric name */
  name: Scalars['String']['output'];
  rawValue?: Maybe<Scalars['Float']['output']>;
  /** Sentiment for the metric */
  sentiment?: Maybe<Sentiment>;
  timestamp?: Maybe<Scalars['DateTime']['output']>;
  /** Metric unit */
  unit: Scalars['String']['output'];
  /** Metric value */
  value: Scalars['String']['output'];
  /** Available variances */
  variances?: Maybe<Array<Maybe<VarianceResult>>>;
};

export type MetricList = {
  __typename?: 'MetricList';
  /** MetricList metrics */
  metrics: Array<Maybe<Metric>>;
  /** MetricList name */
  name: Scalars['String']['output'];
};

export type MicroChartAxisResult = {
  __typename?: 'MicroChartAxisResult';
  /** Axis type */
  axisType?: Maybe<AxisType>;
  /** Axis value labels */
  labels?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type MicroChartCardResult = Card & {
  __typename?: 'MicroChartCardResult';
  /** Actual text */
  actual?: Maybe<TextResult>;
  /** Card Type */
  cardType: CardType;
  code: Scalars['String']['output'];
  /** Data view identifier */
  id: Scalars['String']['output'];
  /** Link result */
  linkResult?: Maybe<Link>;
  /** Microchart */
  microChart?: Maybe<MicroChartResult>;
  /** Card title */
  title: TextResult;
  /** Variance result */
  variance?: Maybe<VarianceResult>;
};

export type MicroChartComponent = Component & {
  __typename?: 'MicroChartComponent';
  /** Component code */
  code: Scalars['String']['output'];
  componentType: ComponentType;
  filters?: Maybe<Array<FilterComponent>>;
  icon: ComponentIcon;
  id: Scalars['Uuid']['output'];
  title: Scalars['String']['output'];
  viewType: ViewType;
};

export type MicroChartHeaderResult = {
  __typename?: 'MicroChartHeaderResult';
  /** Label of the header */
  label?: Maybe<Scalars['String']['output']>;
  /** Sentiment used for value color */
  sentiment: Sentiment;
  /** SubLabels content */
  subLabels?: Maybe<Array<Scalars['String']['output']>>;
  /** Value of microchart */
  value?: Maybe<Scalars['String']['output']>;
};

export type MicroChartResult = IDataView & {
  __typename?: 'MicroChartResult';
  availableUserSettings: Array<UserSetting>;
  dataDimensionId?: Maybe<Scalars['Long']['output']>;
  /** Type of the data view */
  dataViewType: DataViewType;
  /** Title of the microchart */
  header?: Maybe<MicroChartHeaderResult>;
  /** Data view identifier */
  id: Scalars['Uuid']['output'];
  isTotalAggregation: Scalars['Boolean']['output'];
  /** Link configuration */
  linkResult?: Maybe<Link>;
  /** MetaData */
  metadata?: Maybe<Array<MetaData>>;
  /** Sentiment for chart */
  sentiment?: Maybe<Sentiment>;
  /** Series data */
  seriesData?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  subLabels: Array<Scalars['String']['output']>;
  /** Trend line data */
  trendlineData?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
};

export type MnsDeviationDetailsRootCause = {
  __typename?: 'MnsDeviationDetailsRootCause';
  percentage?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};

export type MnsDeviationsDetailsCapa = {
  __typename?: 'MnsDeviationsDetailsCapa';
  title: Scalars['String']['output'];
};

export type MnsDeviationsDetailsCapaInfo = {
  __typename?: 'MnsDeviationsDetailsCapaInfo';
  capas: Array<MnsDeviationsDetailsCapa>;
  investigationDetails?: Maybe<Scalars['String']['output']>;
};

export type MnsDeviationsDetailsComponent = Component & {
  __typename?: 'MnsDeviationsDetailsComponent';
  /** Component code */
  code: Scalars['String']['output'];
  componentType: ComponentType;
  icon: ComponentIcon;
  id: Scalars['Uuid']['output'];
  title: Scalars['String']['output'];
  viewType: ViewType;
};

export type MnsDeviationsDetailsData = {
  __typename?: 'MnsDeviationsDetailsData';
  capaInfo: MnsDeviationsDetailsCapaInfo;
  /** Category */
  category: Scalars['String']['output'];
  categorySentiment: Scalars['String']['output'];
  /** Country */
  country: Scalars['String']['output'];
  deviationCode: Scalars['String']['output'];
  /** DeviationDescription */
  deviationDescription: Scalars['String']['output'];
  /** DeviationCode */
  deviationId: Scalars['String']['output'];
  /** DeviationStatus */
  deviationStatus?: Maybe<Scalars['String']['output']>;
  /** DeviationTopic */
  deviationTopic: Scalars['String']['output'];
  /** DueDate */
  dueDate: Scalars['String']['output'];
  /** EntryDate */
  entryDate: Scalars['String']['output'];
  rootCauseInfo: MnsDeviationsDetailsRootCauseInfo;
  /** Site */
  site: Scalars['String']['output'];
};

export type MnsDeviationsDetailsDataView = IDataView & {
  __typename?: 'MnsDeviationsDetailsDataView';
  availableUserSettings: Array<UserSetting>;
  /** Content */
  content: MnsDeviationsDetailsData;
  /** Type of the data view */
  dataViewType: DataViewType;
  /** Data view identifier */
  id: Scalars['Uuid']['output'];
  metadata?: Maybe<Array<MetaData>>;
  title: Scalars['String']['output'];
};

export type MnsDeviationsDetailsRootCauseInfo = {
  __typename?: 'MnsDeviationsDetailsRootCauseInfo';
  investigationDetails?: Maybe<Scalars['String']['output']>;
  rootCauses: Array<MnsDeviationDetailsRootCause>;
};

export type MnsDeviationsDial = {
  __typename?: 'MnsDeviationsDial';
  actualText?: Maybe<TextResult>;
  filterOption: FilterOptionResult;
  /** Value of dial described by formatted string */
  label: Scalars['String']['output'];
  progress: Scalars['Float']['output'];
  /** Trend arrow which displays business status of the score */
  trend?: Maybe<TrendArrowResult>;
};

export type MnsDeviationsDialComponent = Component & {
  __typename?: 'MnsDeviationsDialComponent';
  /** Component code */
  code: Scalars['String']['output'];
  componentType: ComponentType;
  filters?: Maybe<Array<FilterComponent>>;
  icon: ComponentIcon;
  id: Scalars['Uuid']['output'];
  title: Scalars['String']['output'];
  viewType: ViewType;
};

export type MnsDeviationsDialDataView = IDataView & {
  __typename?: 'MnsDeviationsDialDataView';
  availableUserSettings: Array<UserSetting>;
  /** Type of the data view */
  dataViewType: DataViewType;
  /** Dials */
  dials: Array<Maybe<MnsDeviationsDial>>;
  /** Data view identifier */
  id: Scalars['Uuid']['output'];
  metadata?: Maybe<Array<MetaData>>;
  /** Title */
  title?: Maybe<Scalars['String']['output']>;
};

export type Module = {
  __typename?: 'Module';
  /** Application version for a module. Can be either: 1.0 or 3.0. */
  applicationVersion: Scalars['String']['output'];
  /** Module unique ID. */
  id: Scalars['Uuid']['output'];
  /** Module code */
  moduleCode: ModuleCode;
  /** Module name. */
  name: Scalars['String']['output'];
  /** Hierarchical structure of children pages */
  pages?: Maybe<Array<Page>>;
  /** Module path. */
  path: Scalars['String']['output'];
};

export enum ModuleCode {
  Clinops = 'CLINOPS',
  Cmc = 'CMC',
  Demo = 'DEMO',
  Ebi = 'EBI',
  Financial = 'FINANCIAL',
  Gra = 'GRA',
  Gtm = 'GTM',
  Mns = 'MNS',
  Ppl = 'PPL',
  Pro = 'PRO',
  Qa = 'QA',
  Rnd = 'RND',
  Spend = 'SPEND',
  Supply = 'SUPPLY',
  Unknown = 'UNKNOWN',
  UnusedMns = 'UNUSED_MNS',
}

export type ModuleDefaultsResult = {
  __typename?: 'ModuleDefaultsResult';
  availableNumberScalingSettings: Array<NumberScaling>;
  availablePeriodTypeSettings: Array<PeriodType>;
  decimalPrecision: Scalars['Int']['output'];
  exchangeRate: Scalars['Int']['output'];
  moduleCode: ModuleCode;
  numberScaling: NumberScaling;
  periodType: PeriodType;
  priorities: Array<TenantDefaultsPriorityResult>;
};

export type MultiSelectFilterInput = {
  /** Filter identifier */
  id: Scalars['Uuid']['input'];
  /** Selected filter values */
  selectedValues: Array<InputMaybe<Scalars['Long']['input']>>;
};

export type MultiSelectFilterValue = {
  __typename?: 'MultiSelectFilterValue';
  /** Filter identifier */
  id: Scalars['Uuid']['output'];
  /** Selected filter options */
  selectedValues: Array<Maybe<Scalars['Long']['output']>>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addRealToBrain: Scalars['Boolean']['output'];
  addStoryToBrain: Scalars['Boolean']['output'];
  addTenantModuleDefaultPriority: ModuleDefaultsResult;
  addTenantModuleDefaults: TenantDefaultsResult;
  createStudyDesign: StudyDesign;
  deleteUserDailyAgentPreselectedFilter: Scalars['Boolean']['output'];
  editUserAdditionalFilters: Scalars['Boolean']['output'];
  followPriorities: Array<PriorityResult>;
  followPrioritiesDimensions: Array<PriorityDimensionResult>;
  followPriority: PriorityResult;
  likeStory: Scalars['Boolean']['output'];
  markInboxItemAsSeen: Scalars['Boolean']['output'];
  markStoryAsRead: Scalars['Boolean']['output'];
  processIntercept: Scalars['Boolean']['output'];
  removeTenantDefaults: TenantDefaultsResult;
  removeTenantModuleDefaultPriority: ModuleDefaultsResult;
  removeTenantModuleDefaults: TenantDefaultsResult;
  reorderPriorities: Array<PriorityResult>;
  reorderPriority: PriorityResult;
  revertAgentDecisionByUser: Scalars['Boolean']['output'];
  rndBdnlAddProductToShortList: UpdateResponse;
  rndBdnlDeleteProductFromShortList: UpdateResponse;
  rndStudyDesignDeleteStudyDesign: StudyDesign;
  rndStudyDesignDuplicateStudyDesign: StudyDesign;
  rndStudyDesignUpdateStudyDesignTimeline: StudyDesign;
  rndStudyDesignerUpdateSiteDistribution: StudyDesign;
  saveUserDailyAgentPreselectedFilter: Scalars['Boolean']['output'];
  saveUserSettings: Array<UserSetting>;
  setTenantModuleDefaults: ModuleDefaultsResult;
  shareLink: SharedLink;
  takeAgentDecisionByUser: Scalars['Boolean']['output'];
  unfollowPriority: PriorityResult;
  updateRecommendationItemPinnedItem: RecommendationItemPinned;
  updateSeenAllScreensFlag: Scalars['Boolean']['output'];
  updateStudyDesignComment: StudyDesign;
  updateTenantModuleDefaultPriority: ModuleDefaultsResult;
  updateTenantModuleDefaults: TenantDefaultsResult;
};

export type MutationAddRealToBrainArgs = {
  input: AddToBrainRealInput;
};

export type MutationAddStoryToBrainArgs = {
  input: AddToBrainStoryInput;
};

export type MutationAddTenantModuleDefaultPriorityArgs = {
  input: TenantDefaultsPriorityInput;
  moduleCode: ModuleCode;
  tenantId: Scalars['String']['input'];
};

export type MutationAddTenantModuleDefaultsArgs = {
  input: TenantDefaultsInput;
  moduleCode: ModuleCode;
  tenantId: Scalars['String']['input'];
};

export type MutationCreateStudyDesignArgs = {
  input: CreateStudyDesignInput;
};

export type MutationDeleteUserDailyAgentPreselectedFilterArgs = {
  filterCode: Scalars['String']['input'];
  goalId: Scalars['Int']['input'];
};

export type MutationEditUserAdditionalFiltersArgs = {
  input: EditUserAdditionalFiltersInput;
};

export type MutationFollowPrioritiesArgs = {
  input?: InputMaybe<Array<PriorityInput>>;
};

export type MutationFollowPrioritiesDimensionsArgs = {
  input?: InputMaybe<Array<PriorityDimensionInput>>;
};

export type MutationFollowPriorityArgs = {
  input: PriorityInput;
};

export type MutationLikeStoryArgs = {
  input: LikeStoryInput;
};

export type MutationMarkInboxItemAsSeenArgs = {
  shareId: Scalars['Uuid']['input'];
};

export type MutationMarkStoryAsReadArgs = {
  input: MarkStoryAsReadInput;
};

export type MutationProcessInterceptArgs = {
  input: ProcessInterceptInput;
};

export type MutationRemoveTenantDefaultsArgs = {
  tenantId: Scalars['String']['input'];
};

export type MutationRemoveTenantModuleDefaultPriorityArgs = {
  moduleCode: ModuleCode;
  priorityId: Scalars['Int']['input'];
  tenantId: Scalars['String']['input'];
};

export type MutationRemoveTenantModuleDefaultsArgs = {
  moduleCode: ModuleCode;
  tenantId: Scalars['String']['input'];
};

export type MutationReorderPrioritiesArgs = {
  input?: InputMaybe<Array<PriorityInput>>;
};

export type MutationReorderPriorityArgs = {
  input: PriorityInput;
};

export type MutationRevertAgentDecisionByUserArgs = {
  input: RevertAgentDecisionInput;
};

export type MutationRndBdnlAddProductToShortListArgs = {
  input: RndBdnlShortListnCommandInput;
};

export type MutationRndBdnlDeleteProductFromShortListArgs = {
  input: RndBdnlShortListnCommandInput;
};

export type MutationRndStudyDesignDeleteStudyDesignArgs = {
  input: RndStudyDesignerDeleteStudyDesignInput;
};

export type MutationRndStudyDesignDuplicateStudyDesignArgs = {
  input: RndStudyDesignerDuplicateStudyDesignInput;
};

export type MutationRndStudyDesignUpdateStudyDesignTimelineArgs = {
  input: UpdateStudyDesignInput;
};

export type MutationRndStudyDesignerUpdateSiteDistributionArgs = {
  input: RndStudyDesignerUpdateSiteDistributionInput;
};

export type MutationSaveUserDailyAgentPreselectedFilterArgs = {
  goalId: Scalars['Int']['input'];
  preselectedFilterId: Scalars['String']['input'];
};

export type MutationSaveUserSettingsArgs = {
  input: SaveUserSettingsInput;
};

export type MutationSetTenantModuleDefaultsArgs = {
  input: TenantModuleDefaultsInput;
  moduleCode: ModuleCode;
  tenantId: Scalars['String']['input'];
};

export type MutationShareLinkArgs = {
  mutationInput: SendSharingMutationInput;
};

export type MutationTakeAgentDecisionByUserArgs = {
  input: TakeAgentDecisionInput;
};

export type MutationUnfollowPriorityArgs = {
  input: PriorityInput;
};

export type MutationUpdateRecommendationItemPinnedItemArgs = {
  input: UpdateRecommendationItemPinnedInput;
};

export type MutationUpdateSeenAllScreensFlagArgs = {
  input: UpdateSeenAllScreensInput;
};

export type MutationUpdateStudyDesignCommentArgs = {
  input: UpdateStudyDesignCommentInput;
};

export type MutationUpdateTenantModuleDefaultPriorityArgs = {
  input: TenantDefaultsPriorityInput;
  moduleCode: ModuleCode;
  tenantId: Scalars['String']['input'];
};

export type MutationUpdateTenantModuleDefaultsArgs = {
  input: TenantDefaultsInput;
  moduleCode: ModuleCode;
  tenantId: Scalars['String']['input'];
};

export type NavigationTabsComponent = Component & {
  __typename?: 'NavigationTabsComponent';
  /** Component code */
  code: Scalars['String']['output'];
  componentType: ComponentType;
  defaultSelectionIndex?: Maybe<Scalars['Int']['output']>;
  icon: ComponentIcon;
  id: Scalars['Uuid']['output'];
  tabs?: Maybe<Array<Tab>>;
  title: Scalars['String']['output'];
  viewType: ViewType;
};

export enum NumberScaling {
  Billions = 'BILLIONS',
  Millions = 'MILLIONS',
  Ones = 'ONES',
  Thousands = 'THOUSANDS',
}

export type Page = {
  __typename?: 'Page';
  /** Page absolute path */
  absolutePath: Scalars['String']['output'];
  /** Application version */
  applicationVersion?: Maybe<Scalars['String']['output']>;
  /** Hierarchical structure of children pages */
  children?: Maybe<Array<Page>>;
  /** Focus area code */
  focusAreaCode: Scalars['String']['output'];
  /** Page unique ID. */
  id: Scalars['Uuid']['output'];
  /** Should this page be displayed in menu */
  isMenuHidden?: Maybe<Scalars['Boolean']['output']>;
  /** Analytics key which is used to support coexistence. Key which is taken from V1 */
  legacyAnalyticsKey?: Maybe<Scalars['String']['output']>;
  /** Legacy FocusAreaCode which is used to support coexistence. Key which is taken from V1 */
  legacyFocusAreaCode?: Maybe<Scalars['String']['output']>;
  /** Icon used in navigation menus */
  menuIcon?: Maybe<MenuIcon>;
  /** Title used in navigation menus */
  menuTitle?: Maybe<Scalars['String']['output']>;
  /** Type of the page: HOME_PAGE, PRIORITY_PAGE, SETTINGS_PAGE, CONTENT_PAGE, MODAL_PAGE, DEFAULT_PAGE */
  pageType: PageTypeEnum;
  /** Page relative to parent path */
  path: Scalars['String']['output'];
  /** Page permissions */
  permissions?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** Page title. */
  title: Scalars['String']['output'];
};

export type PageContentResult = {
  __typename?: 'PageContentResult';
  body: Array<Component>;
};

export type PageDataResult = {
  __typename?: 'PageDataResult';
  isShareable: Scalars['Boolean']['output'];
  pageContentResult?: Maybe<PageContentResult>;
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']['output']>;
  /** Indicates whether more edges exist following the set defined by the clients arguments. */
  hasNextPage: Scalars['Boolean']['output'];
  /** Indicates whether more edges exist prior the set defined by the clients arguments. */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']['output']>;
};

export enum PageTypeEnum {
  ContentPage = 'CONTENT_PAGE',
  DefaultPage = 'DEFAULT_PAGE',
  HomePage = 'HOME_PAGE',
  LandingPage = 'LANDING_PAGE',
  ModalPage = 'MODAL_PAGE',
  PriorityPage = 'PRIORITY_PAGE',
  RndHomePage = 'RND_HOME_PAGE',
  SettingsPage = 'SETTINGS_PAGE',
}

/** A connection to a list of items. */
export type PagesConnection = {
  __typename?: 'PagesConnection';
  /** A list of edges. */
  edges?: Maybe<Array<PagesEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Page>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type PagesEdge = {
  __typename?: 'PagesEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Page;
};

export enum PeriodType {
  Period = 'PERIOD',
  Quarter = 'QUARTER',
  Year = 'YEAR',
}

export type Plailist = {
  __typename?: 'Plailist';
  /** Plailist tile color end */
  colorEnd?: Maybe<ColorUnion>;
  /** Plailist tile color start */
  colorStart?: Maybe<ColorUnion>;
  /** Plailist filters */
  filters?: Maybe<Array<Maybe<PlailistFilter>>>;
  permissions: Array<Scalars['String']['output']>;
  /** Plailist Id */
  plailistId: Scalars['Uuid']['output'];
  /** Stories which belong to this plailist */
  stories?: Maybe<Array<Maybe<Story>>>;
  /** Plailist summary (text) */
  summary?: Maybe<Scalars['String']['output']>;
  /** Plailist title */
  title?: Maybe<Scalars['String']['output']>;
};

export type PlailistCategory = {
  __typename?: 'PlailistCategory';
  /** Plailist category Id */
  categoryId: Scalars['Uuid']['output'];
  /** Plailist category name */
  name: Scalars['String']['output'];
  /** Plailists which belong to this category */
  plailists?: Maybe<Array<Maybe<Plailist>>>;
};

export type PlailistComponent = Component & {
  __typename?: 'PlailistComponent';
  /** Component code */
  code: Scalars['String']['output'];
  componentType: ComponentType;
  icon: ComponentIcon;
  id: Scalars['Uuid']['output'];
  title: Scalars['String']['output'];
  viewType: ViewType;
};

export type PlailistFilter = {
  __typename?: 'PlailistFilter';
  /** FilterCode */
  filterCode: Scalars['String']['output'];
  /** Filter label */
  label?: Maybe<Scalars['String']['output']>;
  /** Options */
  options?: Maybe<Array<Maybe<PlailistFilterOption>>>;
};

export type PlailistFilterInput = {
  filterCode: Scalars['String']['input'];
  value: Scalars['Long']['input'];
};

export type PlailistFilterOption = {
  __typename?: 'PlailistFilterOption';
  /** Sub options */
  children?: Maybe<Array<PlailistFilterOption>>;
  /** Identifier of option */
  id: Scalars['Long']['output'];
  /** Flag to indicate whether option is default for filter */
  isDefault?: Maybe<Scalars['Boolean']['output']>;
  /** Option label. */
  label: Scalars['String']['output'];
  /** Option value */
  value?: Maybe<Scalars['Long']['output']>;
};

export type PlailistStoriesInput = {
  filters: Array<PlailistFilterInput>;
  plailistId: Scalars['Uuid']['input'];
};

export type PlailistsQueryInput = {
  moduleId: Scalars['Uuid']['input'];
};

export type PlailistsResult = {
  __typename?: 'PlailistsResult';
  /** Categories */
  categories: Array<Maybe<PlailistCategory>>;
  /** Meta data */
  metadata?: Maybe<Array<Maybe<MetaData>>>;
};

export type PnlRebatesBreakdownChartComponent = Component & {
  __typename?: 'PnlRebatesBreakdownChartComponent';
  code: Scalars['String']['output'];
  componentType: ComponentType;
  /** Dependent filters */
  filters?: Maybe<Array<FilterComponent>>;
  icon: ComponentIcon;
  id: Scalars['Uuid']['output'];
  title: Scalars['String']['output'];
  viewType: ViewType;
};

export type PnlRebatesBreakdownChartDataView = IDataView & {
  __typename?: 'PnlRebatesBreakdownChartDataView';
  availableUserSettings: Array<UserSetting>;
  dataViewType: DataViewType;
  /** Selected filters for chart */
  filters?: Maybe<Array<FilterValue>>;
  id: Scalars['Uuid']['output'];
  metadata?: Maybe<Array<MetaData>>;
  renderer: Scalars['String']['output'];
  /** Chart series */
  series?: Maybe<Array<ChartSeries>>;
  /** Axis x configuration */
  xAxis: ChartAxis;
  /** Axis y configuration */
  yAxis: ChartAxis;
};

export type PnlRebatesBreakdownChartHeaderComponent = Component & {
  __typename?: 'PnlRebatesBreakdownChartHeaderComponent';
  code: Scalars['String']['output'];
  componentType: ComponentType;
  /** Dependent filters */
  filters?: Maybe<Array<FilterComponent>>;
  icon: ComponentIcon;
  id: Scalars['Uuid']['output'];
  title: Scalars['String']['output'];
  viewType: ViewType;
};

export type PnlRebatesBreakdownChartHeaderDataView = IDataView & {
  __typename?: 'PnlRebatesBreakdownChartHeaderDataView';
  availableUserSettings: Array<UserSetting>;
  /** Table columns */
  columns?: Maybe<Array<TableColumnResult>>;
  dataViewType: DataViewType;
  /** Dependent filters */
  filters?: Maybe<Array<FilterValue>>;
  id: Scalars['Uuid']['output'];
  metadata?: Maybe<Array<MetaData>>;
  /** Table rows */
  rows?: Maybe<Array<Maybe<TableRowResult>>>;
};

export type PnlRebatesBreakdownResult = {
  __typename?: 'PnlRebatesBreakdownResult';
  chart: PnlRebatesBreakdownChartDataView;
  chartHeader: PnlRebatesBreakdownChartHeaderDataView;
  table: PnlRebatesBreakdownTableDataView;
};

export type PnlRebatesBreakdownResultChartArgs = {
  input?: InputMaybe<DataViewInput>;
};

export type PnlRebatesBreakdownResultChartHeaderArgs = {
  input?: InputMaybe<DataViewInput>;
};

export type PnlRebatesBreakdownResultTableArgs = {
  input?: InputMaybe<DataViewInput>;
};

export type PnlRebatesBreakdownTableComponent = Component & {
  __typename?: 'PnlRebatesBreakdownTableComponent';
  code: Scalars['String']['output'];
  componentType: ComponentType;
  filters: Array<FilterComponent>;
  icon: ComponentIcon;
  id: Scalars['Uuid']['output'];
  title: Scalars['String']['output'];
  viewType: ViewType;
};

export type PnlRebatesBreakdownTableDataView = IDataView & {
  __typename?: 'PnlRebatesBreakdownTableDataView';
  availableUserSettings: Array<UserSetting>;
  /** Table columns */
  columns?: Maybe<Array<TableColumnResult>>;
  dataViewType: DataViewType;
  /** Dependent filters */
  filters?: Maybe<Array<FilterValue>>;
  id: Scalars['Uuid']['output'];
  metadata?: Maybe<Array<MetaData>>;
  /** Table rows */
  rows?: Maybe<Array<Maybe<TableRowResult>>>;
};

export type PnlRebatesInsightsCard = {
  __typename?: 'PnlRebatesInsightsCard';
  absoluteValueFormatted: Scalars['String']['output'];
  percentageValueFormatted: Scalars['String']['output'];
  sentiment: Sentiment;
  subtitle: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type PnlRebatesInsightsCardsComponent = Component & {
  __typename?: 'PnlRebatesInsightsCardsComponent';
  code: Scalars['String']['output'];
  componentType: ComponentType;
  /** Dependent filters */
  filters?: Maybe<Array<FilterComponent>>;
  icon: ComponentIcon;
  id: Scalars['Uuid']['output'];
  title: Scalars['String']['output'];
  viewType: ViewType;
};

export type PnlRebatesInsightsCardsDataView = IDataView & {
  __typename?: 'PnlRebatesInsightsCardsDataView';
  availableUserSettings: Array<UserSetting>;
  dataViewType: DataViewType;
  /** Dependent filters */
  filters?: Maybe<Array<FilterValue>>;
  groups: Array<PnlRebatesInsightsCardsGroup>;
  id: Scalars['Uuid']['output'];
  metadata?: Maybe<Array<MetaData>>;
};

export type PnlRebatesInsightsCardsGroup = {
  __typename?: 'PnlRebatesInsightsCardsGroup';
  cards: Array<PnlRebatesInsightsCard>;
  title: Scalars['String']['output'];
};

export type PnlRebatesInsightsHeaderComponent = Component & {
  __typename?: 'PnlRebatesInsightsHeaderComponent';
  code: Scalars['String']['output'];
  componentType: ComponentType;
  /** Dependent filters */
  filters?: Maybe<Array<FilterComponent>>;
  icon: ComponentIcon;
  id: Scalars['Uuid']['output'];
  title: Scalars['String']['output'];
  viewType: ViewType;
};

export type PnlRebatesInsightsHeaderDataView = IDataView & {
  __typename?: 'PnlRebatesInsightsHeaderDataView';
  availableUserSettings: Array<UserSetting>;
  /** Table columns */
  columns?: Maybe<Array<TableColumnResult>>;
  dataViewType: DataViewType;
  /** Dependent filters */
  filters?: Maybe<Array<FilterValue>>;
  id: Scalars['Uuid']['output'];
  metadata?: Maybe<Array<MetaData>>;
  /** Table rows */
  rows?: Maybe<Array<Maybe<TableRowResult>>>;
};

export type PnlRebatesInsightsResult = {
  __typename?: 'PnlRebatesInsightsResult';
  cards: PnlRebatesInsightsCardsDataView;
  header: PnlRebatesInsightsHeaderDataView;
};

export type PnlRebatesInsightsResultCardsArgs = {
  input?: InputMaybe<DataViewInput>;
};

export type PnlRebatesInsightsResultHeaderArgs = {
  input?: InputMaybe<DataViewInput>;
};

export type PnlRebatesResult = {
  __typename?: 'PnlRebatesResult';
  breakdown: PnlRebatesBreakdownResult;
  insights: PnlRebatesInsightsResult;
};

export type PnlResult = {
  __typename?: 'PnlResult';
  rebates: PnlRebatesResult;
};

export enum Position {
  Bottom = 'BOTTOM',
  Top = 'TOP',
}

export type PplCardDataView = IDataView & {
  __typename?: 'PplCardDataView';
  availableUserSettings: Array<UserSetting>;
  /** Cards */
  cards?: Maybe<Array<Maybe<PplHomeCardResult>>>;
  /** Type of the data view */
  dataViewType: DataViewType;
  /** Data view identifier */
  id: Scalars['Uuid']['output'];
  metadata?: Maybe<Array<MetaData>>;
  /** Title */
  title?: Maybe<Scalars['String']['output']>;
};

export type PplHomeCardResult = {
  __typename?: 'PplHomeCardResult';
  /** Card Type */
  cardType: CardType;
  id: Scalars['String']['output'];
  /** Link result */
  linkResult?: Maybe<Link>;
  /** Card title */
  title: TextResult;
  value: ValueTextResult;
};

export type PplHomeCardsComponent = Component & {
  __typename?: 'PplHomeCardsComponent';
  /** Component code */
  code: Scalars['String']['output'];
  componentType: ComponentType;
  filters?: Maybe<Array<FilterComponent>>;
  icon: ComponentIcon;
  id: Scalars['Uuid']['output'];
  title: Scalars['String']['output'];
  viewType: ViewType;
};

export type PplHomeCategoriesDialsComponent = Component & {
  __typename?: 'PplHomeCategoriesDialsComponent';
  /** Component code */
  code: Scalars['String']['output'];
  componentType: ComponentType;
  filters?: Maybe<Array<FilterComponent>>;
  icon: ComponentIcon;
  id: Scalars['Uuid']['output'];
  title: Scalars['String']['output'];
  viewType: ViewType;
};

export type PplHomeCategoriesDialsDataView = IDataView & {
  __typename?: 'PplHomeCategoriesDialsDataView';
  /** Annotation */
  annotation?: Maybe<Scalars['String']['output']>;
  availableUserSettings: Array<UserSetting>;
  /** Type of the data view */
  dataViewType: DataViewType;
  /** Categories dials */
  dials: Array<Maybe<PplHomeCategoryDial>>;
  id: Scalars['Uuid']['output'];
  linkResult: Link;
  metadata?: Maybe<Array<MetaData>>;
  title: Scalars['String']['output'];
};

export type PplHomeCategoryDial = {
  __typename?: 'PplHomeCategoryDial';
  /** Float value for progress (how much circle is filled */
  progress: Scalars['Float']['output'];
  /** Category name */
  scoreCategory: Scalars['String']['output'];
  /** Category identifier */
  scoreCategoryId: Scalars['Int']['output'];
  /** Sentiment of circle which is translated to the color on frontend side */
  sentiment: Sentiment;
  /** Float value of target (white dot inside circle) */
  target?: Maybe<Scalars['Float']['output']>;
  /** Trend arrow which displays business status of the score */
  trend?: Maybe<TrendArrowResult>;
  /** Value of dial described by formatted string, type and sentiment */
  valueText: ValueTextResult;
};

export type PplHomeDetailsChartComponent = Component & {
  __typename?: 'PplHomeDetailsChartComponent';
  /** Component code */
  code: Scalars['String']['output'];
  componentType: ComponentType;
  filters?: Maybe<Array<FilterComponent>>;
  icon: ComponentIcon;
  id: Scalars['Uuid']['output'];
  title: Scalars['String']['output'];
  viewType: ViewType;
};

export type PplHomeDetailsChartHeaderComponent = Component & {
  __typename?: 'PplHomeDetailsChartHeaderComponent';
  /** Component code */
  code: Scalars['String']['output'];
  componentType: ComponentType;
  icon: ComponentIcon;
  id: Scalars['Uuid']['output'];
  title: Scalars['String']['output'];
  viewType: ViewType;
};

export type PplHomeDetailsChartHeaderDataView = IDataView & {
  __typename?: 'PplHomeDetailsChartHeaderDataView';
  /** Annotation */
  annotation?: Maybe<Scalars['String']['output']>;
  availableUserSettings: Array<UserSetting>;
  /** Type of the data view */
  dataViewType: DataViewType;
  id: Scalars['Uuid']['output'];
  /** Header label */
  label: Scalars['String']['output'];
  metadata?: Maybe<Array<MetaData>>;
  /** Header sub labels */
  subLabels: Array<Maybe<Scalars['String']['output']>>;
  /** Time period label for which header is created */
  timePeriodLabel: Scalars['String']['output'];
  title: Scalars['String']['output'];
  /** Value of predicted KPI */
  value: ValueTextResult;
  /** Variance of predicted KPI */
  valueWithVariance: ValueWithVarianceResult;
};

export type PplHomeDetailsDriverChartHeaderDataView = IDataView & {
  __typename?: 'PplHomeDetailsDriverChartHeaderDataView';
  /** Annotation */
  annotation?: Maybe<Scalars['String']['output']>;
  availableUserSettings: Array<UserSetting>;
  /** Type of the data view */
  dataViewType: DataViewType;
  id: Scalars['Uuid']['output'];
  /** Header label */
  label: Scalars['String']['output'];
  metadata?: Maybe<Array<MetaData>>;
  /** Header sub labels */
  subLabels: Array<Maybe<Scalars['String']['output']>>;
  /** Time period for which header is created */
  timePeriodLabel: Scalars['String']['output'];
  title: Scalars['String']['output'];
  /** Value of driver */
  value: ValueTextResult;
  /** Value of driver */
  valueWithVariance: ValueWithVarianceResult;
};

export type PplHomeDetailsDriverRecommendation = {
  __typename?: 'PplHomeDetailsDriverRecommendation';
  /** Description of details' recommendation */
  description: Scalars['String']['output'];
  /** Id of the driver targeted by the recommendation */
  driverId: Scalars['Int']['output'];
  /** SubTitle of details' recommendation */
  subTitle: Scalars['String']['output'];
  /** Title of details' recommendation */
  title: Scalars['String']['output'];
};

export type PplHomeDetailsDriverResult = {
  __typename?: 'PplHomeDetailsDriverResult';
  chart?: Maybe<ChartDataViewResult>;
  /** Driver identifier */
  id: Scalars['Int']['output'];
  /** Label of driver */
  label: Scalars['String']['output'];
  /** List of recommendations for driver */
  recommendations: Array<Maybe<PplHomeDetailsRecommendation>>;
  /** Sentiment for number which is translated to the color on frontend side */
  sentiment: Sentiment;
  /** Trend arrow which displays business status of the score */
  trend: TrendArrowResult;
  /** Value of driver */
  value: ValueTextResult;
  /** Formatted value as text for driver */
  valueText: Scalars['String']['output'];
};

export type PplHomeDetailsDriversChartComponent = Component & {
  __typename?: 'PplHomeDetailsDriversChartComponent';
  /** Component code */
  code: Scalars['String']['output'];
  componentType: ComponentType;
  filters?: Maybe<Array<FilterComponent>>;
  icon: ComponentIcon;
  id: Scalars['Uuid']['output'];
  title: Scalars['String']['output'];
  viewType: ViewType;
};

export type PplHomeDetailsDriversChartHeaderComponent = Component & {
  __typename?: 'PplHomeDetailsDriversChartHeaderComponent';
  /** Component code */
  code: Scalars['String']['output'];
  componentType: ComponentType;
  icon: ComponentIcon;
  id: Scalars['Uuid']['output'];
  title: Scalars['String']['output'];
  viewType: ViewType;
};

export type PplHomeDetailsDriversComponent = Component & {
  __typename?: 'PplHomeDetailsDriversComponent';
  /** Component code */
  code: Scalars['String']['output'];
  componentType: ComponentType;
  filters?: Maybe<Array<FilterComponent>>;
  icon: ComponentIcon;
  id: Scalars['Uuid']['output'];
  title: Scalars['String']['output'];
  viewType: ViewType;
};

export type PplHomeDetailsDriversDataView = IDataView & {
  __typename?: 'PplHomeDetailsDriversDataView';
  /** Annotation */
  annotation?: Maybe<Scalars['String']['output']>;
  availableUserSettings: Array<UserSetting>;
  /** Type of the data view */
  dataViewType: DataViewType;
  /** Drivers for PPL */
  drivers?: Maybe<Array<Maybe<PplHomeDetailsDriverResult>>>;
  /** Data view identifier */
  id: Scalars['Uuid']['output'];
  linkResult: Link;
  metadata?: Maybe<Array<MetaData>>;
};

export type PplHomeDetailsRecommendation = {
  __typename?: 'PplHomeDetailsRecommendation';
  /** Details of recommendation for driver */
  details: PplHomeDetailsRecommendationDetails;
  /** Title of recommendation for driver */
  title: Scalars['String']['output'];
};

export type PplHomeDetailsRecommendationDetails = {
  __typename?: 'PplHomeDetailsRecommendationDetails';
  /** Description of the recommendation */
  description: Scalars['String']['output'];
  /** Label of the recommendation (usually the title of the recommendation) */
  label: Scalars['String']['output'];
  /** SubTitle of recommendation */
  subTitle: Scalars['String']['output'];
  /** Title of recommendation component */
  title: Scalars['String']['output'];
};

export type PplHomeDetailsRecommendationsComponent = Component & {
  __typename?: 'PplHomeDetailsRecommendationsComponent';
  /** Component code */
  code: Scalars['String']['output'];
  componentType: ComponentType;
  filters?: Maybe<Array<FilterComponent>>;
  icon: ComponentIcon;
  id: Scalars['Uuid']['output'];
  title: Scalars['String']['output'];
  viewType: ViewType;
};

export type PplHomeDetailsRecommendationsDataView = IDataView & {
  __typename?: 'PplHomeDetailsRecommendationsDataView';
  availableUserSettings: Array<UserSetting>;
  /** Type of the data view */
  dataViewType: DataViewType;
  /** Data view identifier */
  id: Scalars['Uuid']['output'];
  metadata?: Maybe<Array<MetaData>>;
  /** List of recommendations */
  recommendations: Array<Maybe<PplHomeDetailsDriverRecommendation>>;
};

export type PplHomeDialComponent = Component & {
  __typename?: 'PplHomeDialComponent';
  /** Component code */
  code: Scalars['String']['output'];
  componentType: ComponentType;
  filters?: Maybe<Array<FilterComponent>>;
  icon: ComponentIcon;
  id: Scalars['Uuid']['output'];
  title: Scalars['String']['output'];
  viewType: ViewType;
};

export type PplHomeDialDataView = IDataView & {
  __typename?: 'PplHomeDialDataView';
  availableUserSettings: Array<UserSetting>;
  /** Type of the data view */
  dataViewType: DataViewType;
  id: Scalars['Uuid']['output'];
  /** Link which indicates where to navigate and which filter pass with navigation */
  link?: Maybe<Link>;
  metadata?: Maybe<Array<MetaData>>;
  /** Float value for progress (how much circle is filled */
  progress: Scalars['Float']['output'];
  /** Category */
  scoreCategory: Scalars['String']['output'];
  /** Category identifier */
  scoreCategoryId: Scalars['Int']['output'];
  /** Sentiment of circle which is translated to the color on frontend side */
  sentiment: Sentiment;
  /** Float value of target (white dot inside circle) */
  target?: Maybe<Scalars['Float']['output']>;
  title: Scalars['String']['output'];
  /** Trend arrow which displays business status of the score */
  trend?: Maybe<TrendArrowResult>;
  /** Value of dial described by formatted string, type and sentiment */
  valueText: ValueTextResult;
};

export type PplMicroChartResult = IDataView & {
  __typename?: 'PplMicroChartResult';
  availableUserSettings: Array<UserSetting>;
  /** Type of the data view */
  dataViewType: DataViewType;
  /** Title of the microchart */
  header?: Maybe<MicroChartHeaderResult>;
  /** Data view identifier */
  id: Scalars['Uuid']['output'];
  /** Link configuration */
  linkResult?: Maybe<Link>;
  /** MetaData */
  metadata?: Maybe<Array<MetaData>>;
  /** Sentiment for chart */
  sentiment?: Maybe<Sentiment>;
  /** Series data */
  seriesData?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  /** Trend line data */
  trendlineData?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  /** XAxis configuration */
  xAxis?: Maybe<MicroChartAxisResult>;
  /** YAxis configuration */
  yAxis?: Maybe<MicroChartAxisResult>;
};

export type PrioritiesInput = {
  moduleId: Scalars['Uuid']['input'];
};

export type PriorityCategoryResult = {
  __typename?: 'PriorityCategoryResult';
  categoryName: Scalars['String']['output'];
  priorities: Array<PriorityResult>;
};

export type PriorityDimensionInput = {
  dimensionId?: InputMaybe<Scalars['Long']['input']>;
  moduleId: Scalars['Uuid']['input'];
  priorityId: Scalars['Int']['input'];
  sortOrder: Scalars['Int']['input'];
};

export type PriorityDimensionResult = {
  __typename?: 'PriorityDimensionResult';
  dimensionId?: Maybe<Scalars['Long']['output']>;
  dimensionName?: Maybe<Scalars['String']['output']>;
  isFollowed: Scalars['Boolean']['output'];
  priorityId: Scalars['Int']['output'];
  priorityName: Scalars['String']['output'];
  sortOrder: Scalars['Int']['output'];
};

export type PriorityInput = {
  moduleId: Scalars['Uuid']['input'];
  priorityId: Scalars['Int']['input'];
  sortOrder: Scalars['Int']['input'];
};

export type PriorityResult = {
  __typename?: 'PriorityResult';
  isFollowed: Scalars['Boolean']['output'];
  priorityId: Scalars['Long']['output'];
  priorityName: Scalars['String']['output'];
  sortOrder: Scalars['Int']['output'];
  subLabel?: Maybe<Scalars['String']['output']>;
};

export type ProAgentDetailsComponent = Component & {
  __typename?: 'ProAgentDetailsComponent';
  /** Component code */
  code: Scalars['String']['output'];
  componentType: ComponentType;
  icon: ComponentIcon;
  id: Scalars['Uuid']['output'];
  title: Scalars['String']['output'];
  viewType: ViewType;
};

export type ProAgentDetailsDataInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  moduleId?: InputMaybe<Scalars['ID']['input']>;
  storyId?: InputMaybe<Scalars['ID']['input']>;
};

export type ProAgentDetailsDataView = IDataView & {
  __typename?: 'ProAgentDetailsDataView';
  availableUserSettings: Array<UserSetting>;
  /** Content */
  content?: Maybe<Scalars['String']['output']>;
  /** Type of the data view */
  dataViewType: DataViewType;
  id: Scalars['Uuid']['output'];
  metadata?: Maybe<Array<MetaData>>;
  title: Scalars['String']['output'];
};

export type ProDailyAgentComponent = Component & {
  __typename?: 'ProDailyAgentComponent';
  /** Component code */
  code: Scalars['String']['output'];
  componentType: ComponentType;
  icon: ComponentIcon;
  id: Scalars['Uuid']['output'];
  title: Scalars['String']['output'];
  viewType: ViewType;
};

export type ProDailyAgentDataView = IDataView & {
  __typename?: 'ProDailyAgentDataView';
  availableGoals: Array<ProDailyAgentGoalRace>;
  availableUserSettings: Array<UserSetting>;
  /** Type of the data view */
  dataViewType: DataViewType;
  /** Filter code */
  filterCode?: Maybe<Scalars['String']['output']>;
  id: Scalars['Uuid']['output'];
  metadata?: Maybe<Array<MetaData>>;
  /** Scopes */
  scopes: Array<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};

export type ProDailyAgentDetailsDataInput = {
  componentCode?: InputMaybe<Scalars['String']['input']>;
  goalId?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  moduleId?: InputMaybe<Scalars['ID']['input']>;
};

export type ProDailyAgentGoalGeography = {
  __typename?: 'ProDailyAgentGoalGeography';
  growthRate: FormattedTextResult;
  label: Scalars['String']['output'];
  targetDateLabel: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type ProDailyAgentGoalRace = {
  __typename?: 'ProDailyAgentGoalRace';
  geographies: Array<ProDailyAgentGoalGeography>;
  label: Scalars['String']['output'];
};

export type ProHomeFeedComponent = Component & {
  __typename?: 'ProHomeFeedComponent';
  /** Component code */
  code: Scalars['String']['output'];
  componentType: ComponentType;
  icon: ComponentIcon;
  id: Scalars['Uuid']['output'];
  title: Scalars['String']['output'];
  viewType: ViewType;
};

export type ProHomeFeedDataInput = {
  additionalFiltersInput?: InputMaybe<Array<InputMaybe<UserAdditionalFiltersInput>>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  moduleId?: InputMaybe<Scalars['ID']['input']>;
};

export type ProHomeFeedDataView = IDataView & {
  __typename?: 'ProHomeFeedDataView';
  availableUserSettings: Array<UserSetting>;
  /** Type of the data view */
  dataViewType: DataViewType;
  /** Additional filter options */
  feedFilterOptions?: Maybe<Array<Maybe<AdditionalFilterOptions>>>;
  id: Scalars['Uuid']['output'];
  /** Items in the data view */
  items?: Maybe<Array<Maybe<InsightType>>>;
  metadata?: Maybe<Array<MetaData>>;
  /** User additional filters */
  userAdditionalFilters?: Maybe<Array<Maybe<UserAdditionalFilters>>>;
};

export type ProModuleResult = {
  __typename?: 'ProModuleResult';
  agent?: Maybe<AgentResult>;
  dailyAgent?: Maybe<DailyAgentResult>;
  home?: Maybe<HomeResult>;
};

export type ProcessInterceptInput = {
  action: InterceptActionType;
  interceptId: Scalars['Uuid']['input'];
  moduleCode: ModuleCode;
};

export type ProgressBarResult = {
  __typename?: 'ProgressBarResult';
  /** Base progress bar value */
  baseValue: Scalars['Float']['output'];
  /** Is progress bar inverted */
  inverted: Scalars['Boolean']['output'];
  /** Maximum progress bar value */
  max: Scalars['Float']['output'];
  /** Minimum progress bar value */
  min: Scalars['Float']['output'];
  /** Bar progress value */
  progressValue: Scalars['Float']['output'];
  /** Sentiment for value */
  sentiment?: Maybe<Sentiment>;
  /** ProgressBarVariant for value */
  variant?: Maybe<ProgressBarVariant>;
};

export enum ProgressBarVariant {
  Default = 'DEFAULT',
  Tornado = 'TORNADO',
}

export type Query = {
  __typename?: 'Query';
  component?: Maybe<Component>;
  dataView?: Maybe<IDataView>;
  designStudyUserOptions: DesignStudyUserOptions;
  dimensionFilter?: Maybe<Filter>;
  filterById?: Maybe<Filter>;
  filtersById?: Maybe<Array<Maybe<Filter>>>;
  fin: FinModuleResult;
  inbox: InboxResult;
  modules?: Maybe<Array<Maybe<Module>>>;
  /** @deprecated The query is to be removed, after all tenants are migrated to the new PageDataResult query */
  pageContent: PageContentResult;
  /** @deprecated Obsolete */
  pageDataResult: PageDataResult;
  /** @deprecated Query is obsolete because its used as a fallback for GetModules within mobile application. */
  pages?: Maybe<PagesConnection>;
  plailists?: Maybe<PlailistsResult>;
  priorities: Array<PriorityCategoryResult>;
  priorityFilter?: Maybe<Filter>;
  pro: ProModuleResult;
  rnd: RndModuleResult;
  spend: SpendModuleResult;
  stories?: Maybe<Array<Maybe<InsightType>>>;
  storiesByPlailist?: Maybe<Array<Maybe<Story>>>;
  storyById?: Maybe<Story>;
  studyDesignById: StudyDesign;
  supply: SupplyModuleResult;
  tenantDefaults: TenantDefaultsResult;
  tenantModuleDefaults: ModuleDefaultsResult;
  userAnalytics?: Maybe<UserAnalytics>;
  userPriorities: Array<PriorityCategoryResult>;
  userPrioritiesDimensions: Array<PriorityDimensionResult>;
  userProfile: UserProfileResult;
  userSettings: Array<UserSetting>;
  userSettingsByModuleId: Array<UserSetting>;
  users: Array<UserResult>;
};

export type QueryComponentArgs = {
  componentId: Scalars['Uuid']['input'];
};

export type QueryDataViewArgs = {
  input?: InputMaybe<DataViewInput>;
};

export type QueryDimensionFilterArgs = {
  moduleId: Scalars['Uuid']['input'];
  priorityId: Scalars['Int']['input'];
};

export type QueryFilterByIdArgs = {
  id: Scalars['Uuid']['input'];
  moduleId?: InputMaybe<Scalars['Uuid']['input']>;
};

export type QueryFiltersByIdArgs = {
  drillId?: InputMaybe<Scalars['Long']['input']>;
  drillIds?: InputMaybe<Array<Scalars['Long']['input']>>;
  filters?: InputMaybe<Array<InputMaybe<FilterInput>>>;
  ids?: InputMaybe<Array<Scalars['Uuid']['input']>>;
  moduleId?: InputMaybe<Scalars['Uuid']['input']>;
  viewCode?: InputMaybe<Scalars['String']['input']>;
};

export type QueryPageContentArgs = {
  pageId: Scalars['Uuid']['input'];
  priorityId?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryPageDataResultArgs = {
  pageId: Scalars['Uuid']['input'];
  priorityId?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryPagesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryPlailistsArgs = {
  input: PlailistsQueryInput;
};

export type QueryPrioritiesArgs = {
  input: PrioritiesInput;
};

export type QueryPriorityFilterArgs = {
  moduleId: Scalars['Uuid']['input'];
};

export type QueryStoriesArgs = {
  input: StoriesQueryInput;
};

export type QueryStoriesByPlailistArgs = {
  input: PlailistStoriesInput;
};

export type QueryStoryByIdArgs = {
  storyId: Scalars['Uuid']['input'];
};

export type QueryStudyDesignByIdArgs = {
  input: GetStudyDesignByIdInput;
};

export type QueryTenantDefaultsArgs = {
  tenantId: Scalars['String']['input'];
};

export type QueryTenantModuleDefaultsArgs = {
  moduleCode: ModuleCode;
  tenantId: Scalars['String']['input'];
};

export type QueryUserAnalyticsArgs = {
  input?: InputMaybe<UserAnalyticsInput>;
};

export type QueryUserPrioritiesArgs = {
  input: PrioritiesInput;
};

export type QueryUserPrioritiesDimensionsArgs = {
  input: PrioritiesInput;
};

export type QueryUserSettingsArgs = {
  input: GetUserSettingsInput;
};

export type QueryUserSettingsByModuleIdArgs = {
  moduleId: Scalars['Uuid']['input'];
  settingsIds: Array<Scalars['Uuid']['input']>;
  viewCode: Scalars['String']['input'];
};

export type QueryUsersArgs = {
  input: GetUsersInput;
};

export type Real = {
  __typename?: 'Real';
  /** Number of brains */
  brainsCount: Scalars['Int']['output'];
  /** Has seen all screens */
  hasSeenAllScreens: Scalars['Boolean']['output'];
  /** Header */
  header: RealHeader;
  /** Id */
  id: Scalars['Uuid']['output'];
  insightsType: InsightsType;
  /** Real is added to brain by user */
  isAddedToBrain?: Maybe<Scalars['Boolean']['output']>;
  isRead: Scalars['Boolean']['output'];
  isShareable: Scalars['Boolean']['output'];
  /** Screens */
  screens: Array<Maybe<RealScreen>>;
  title: Scalars['String']['output'];
};

export type RealAudio = {
  __typename?: 'RealAudio';
  /** Track name */
  trackName: Scalars['String']['output'];
};

export type RealHeader = {
  __typename?: 'RealHeader';
  /** Icon */
  icon?: Maybe<RealIcon>;
  /** Subtitle */
  subtitle?: Maybe<Scalars['String']['output']>;
  /** Title */
  title: Scalars['String']['output'];
};

export enum RealIcon {
  Accelerate = 'ACCELERATE',
  Ai = 'AI',
  Bank = 'BANK',
  Failure = 'FAILURE',
  Flag = 'FLAG',
  Flash = 'FLASH',
  LeftArrow = 'LEFT_ARROW',
  Minus = 'MINUS',
  Pipeline = 'PIPELINE',
  Plus = 'PLUS',
  RePrioritize = 'RE_PRIORITIZE',
  Stop = 'STOP',
}

export type RealScreen = {
  __typename?: 'RealScreen';
  /** Audio */
  audio?: Maybe<RealAudio>;
  /** Content */
  content?: Maybe<RealTextList>;
  /** Header */
  header?: Maybe<RealScreenHeader>;
  legacyLink?: Maybe<LegacyLink>;
  /** ScreenId */
  screenId: Scalars['Uuid']['output'];
  /** ScreenType */
  screenType: RealScreenType;
  /** SeeMoreLink */
  seeMoreLink?: Maybe<TextLinkResult>;
};

export type RealScreenHeader = {
  __typename?: 'RealScreenHeader';
  /** Icon */
  icon: RealIcon;
  /** Subtitle */
  subtitle?: Maybe<Scalars['String']['output']>;
  /** Title */
  title: Scalars['String']['output'];
};

export enum RealScreenType {
  Decision = 'DECISION',
  Default = 'DEFAULT',
  Intro = 'INTRO',
}

export type RealTextList = {
  __typename?: 'RealTextList';
  /** Items */
  items?: Maybe<Array<Maybe<RealTextListItem>>>;
};

export type RealTextListItem = {
  __typename?: 'RealTextListItem';
  /** Icon */
  icon?: Maybe<RealIcon>;
  /** Text */
  text: Array<Maybe<FormattedTextResult>>;
};

export type RecommendationItem = {
  __typename?: 'RecommendationItem';
  cost?: Maybe<Scalars['String']['output']>;
  deepLinkCode?: Maybe<Scalars['Int']['output']>;
  /** Description of recommendation item */
  description?: Maybe<Scalars['String']['output']>;
  /** Detail of items */
  details?: Maybe<Array<Maybe<RecommendationItemDetail>>>;
  /** Description of icon item */
  icon?: Maybe<RecommendationItemIcon>;
  sortOrder?: Maybe<Scalars['Int']['output']>;
  /** Title for recommendation item */
  title: Scalars['String']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type RecommendationItemContent = {
  __typename?: 'RecommendationItemContent';
  /** Title for item detail */
  icon: RecommendationItemContentIcon;
  /** Title for item content */
  title: Scalars['String']['output'];
};

export enum RecommendationItemContentIcon {
  ArrowRight = 'ARROW_RIGHT',
}

export type RecommendationItemDetail = {
  __typename?: 'RecommendationItemDetail';
  cost?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  /** Item is pinned */
  isPinned: Scalars['Boolean']['output'];
  /** Item content of item detail */
  itemsContent?: Maybe<Array<Maybe<RecommendationItemContent>>>;
  /** Title for item detail */
  title: Scalars['String']['output'];
  /** Value for item detail */
  value: Scalars['String']['output'];
  /** Link to whatif page */
  whatIfDeepLink?: Maybe<TextLinkResult>;
};

export enum RecommendationItemIcon {
  Accelerate = 'ACCELERATE',
  Acquire = 'ACQUIRE',
  Competition = 'COMPETITION',
  Failure = 'FAILURE',
  Pipeline = 'PIPELINE',
  Reprioritize = 'REPRIORITIZE',
  Stop = 'STOP',
  Unwind = 'UNWIND',
}

export type RecommendationItemPinned = {
  __typename?: 'RecommendationItemPinned';
  id: Scalars['Int']['output'];
  isPinned: Scalars['Boolean']['output'];
};

export type RecommendationsActionsComponent = Component & {
  __typename?: 'RecommendationsActionsComponent';
  code: Scalars['String']['output'];
  componentType: ComponentType;
  filters?: Maybe<Array<FilterComponent>>;
  icon: ComponentIcon;
  id: Scalars['Uuid']['output'];
  title: Scalars['String']['output'];
  viewType: ViewType;
};

export type RecommendationsActionsDataView = IDataView & {
  __typename?: 'RecommendationsActionsDataView';
  availableUserSettings: Array<UserSetting>;
  /** Type of the data view */
  dataViewType: DataViewType;
  /** Data view identifier */
  id: Scalars['Uuid']['output'];
  /** MetaData */
  metadata?: Maybe<Array<MetaData>>;
  /** Custom header for the breakdown page (Optional) */
  pageHeader?: Maybe<Scalars['String']['output']>;
  /** Custom subheader for the breakdown page (Optional) */
  pageSubheader?: Maybe<Scalars['String']['output']>;
  /** List with recommendation items */
  recommendationsList: Array<Maybe<RecommendationsList>>;
};

export type RecommendationsBreakdown = {
  __typename?: 'RecommendationsBreakdown';
  /** Table content id */
  dataViewId: Scalars['Uuid']['output'];
  title: Scalars['String']['output'];
  /** DataView view code */
  viewCode?: Maybe<Scalars['String']['output']>;
};

export type RecommendationsBreakdownComponent = Component & {
  __typename?: 'RecommendationsBreakdownComponent';
  code: Scalars['String']['output'];
  componentType: ComponentType;
  filters?: Maybe<Array<FilterComponent>>;
  icon: ComponentIcon;
  id: Scalars['Uuid']['output'];
  title: Scalars['String']['output'];
  viewType: ViewType;
};

export type RecommendationsBreakdownDataView = IDataView & {
  __typename?: 'RecommendationsBreakdownDataView';
  availableUserSettings: Array<UserSetting>;
  /** List of breakdowns for individual recommendations */
  breakdowns?: Maybe<Array<RecommendationsBreakdown>>;
  /** Type of the data view */
  dataViewType: DataViewType;
  /** Data view identifier */
  id: Scalars['Uuid']['output'];
  metadata?: Maybe<Array<MetaData>>;
};

export type RecommendationsList = {
  __typename?: 'RecommendationsList';
  /** Action items */
  items?: Maybe<Array<RecommendationItem>>;
  /** Label Action title */
  label?: Maybe<Scalars['String']['output']>;
  /** Link to the recommendation */
  link?: Maybe<Link>;
  /** Sentiment for item */
  sentiment?: Maybe<Sentiment>;
  /** Recommendations Action title */
  title: Scalars['String']['output'];
};

export type RecommendedPathwaysDetailsContentData = {
  __typename?: 'RecommendedPathwaysDetailsContentData';
  sentimentType: Sentiment;
  subTitle: Scalars['String']['output'];
  text: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type RecommendedPathwaysDetailsData = {
  __typename?: 'RecommendedPathwaysDetailsData';
  contentBlock: Array<RecommendedPathwaysDetailsContentData>;
  header: RecommendedPathwaysDetailsHeaderData;
};

export type RecommendedPathwaysDetailsHeaderData = {
  __typename?: 'RecommendedPathwaysDetailsHeaderData';
  label: Scalars['String']['output'];
  scoreText: Scalars['String']['output'];
  subLabel: Scalars['String']['output'];
  subTitle: Scalars['String']['output'];
  title: Scalars['String']['output'];
  vsPreviousMonthSentiment: Sentiment;
  vsPreviousMonthValue: Scalars['String']['output'];
};

export type RecommenderComponent = Component & {
  __typename?: 'RecommenderComponent';
  /** Component code */
  code: Scalars['String']['output'];
  componentType: ComponentType;
  icon: ComponentIcon;
  id: Scalars['Uuid']['output'];
  title: Scalars['String']['output'];
  viewType: ViewType;
};

export type RecommenderDataView = IDataView & {
  __typename?: 'RecommenderDataView';
  availableUserSettings: Array<UserSetting>;
  /** Type of the data view */
  dataViewType: DataViewType;
  /** Data view identifier */
  id: Scalars['Uuid']['output'];
  /** Insights for recommender */
  insights?: Maybe<Array<InsightType>>;
  /** MetaData */
  metadata?: Maybe<Array<MetaData>>;
  /** See all link */
  seeAllLink?: Maybe<Link>;
};

export type RegionCountryResult = {
  __typename?: 'RegionCountryResult';
  countryCode?: Maybe<Scalars['String']['output']>;
  countryName?: Maybe<Scalars['String']['output']>;
  regionCode: Scalars['String']['output'];
  regionName: Scalars['String']['output'];
};

export type RevertAgentDecisionInput = {
  agentId: Scalars['Uuid']['input'];
};

export type RiskIndicatorResult = {
  __typename?: 'RiskIndicatorResult';
  /** Actual Text content */
  actual?: Maybe<Scalars['String']['output']>;
  /** Sentiment for previous value */
  previousSentiment?: Maybe<Sentiment>;
  /** Sentiment for value */
  sentiment?: Maybe<Sentiment>;
  /** Target Text content */
  target?: Maybe<Scalars['String']['output']>;
};

export type RndBdnlShortListnCommandInput = {
  indicationCode: Scalars['String']['input'];
  productCode: Scalars['String']['input'];
};

export type RndDialItem = {
  __typename?: 'RndDialItem';
  dialItem?: Maybe<DialItem>;
};

export type RndHomeDialsComponent = Component & {
  __typename?: 'RndHomeDialsComponent';
  /** Component code */
  code: Scalars['String']['output'];
  componentType: ComponentType;
  filters?: Maybe<Array<FilterComponent>>;
  icon: ComponentIcon;
  id: Scalars['Uuid']['output'];
  title: Scalars['String']['output'];
  viewType: ViewType;
};

export type RndHomeDialsDataView = IDataView & {
  __typename?: 'RndHomeDialsDataView';
  annotation?: Maybe<Scalars['String']['output']>;
  annotationDate?: Maybe<Scalars['String']['output']>;
  availableUserSettings: Array<UserSetting>;
  /** Type of the data view */
  dataViewType: DataViewType;
  id: Scalars['Uuid']['output'];
  /** Dial items */
  items?: Maybe<Array<RndDialItem>>;
  metadata?: Maybe<Array<MetaData>>;
  title: Scalars['String']['output'];
};

export type RndInsightsBDandLFilterDataInput = {
  indicationtId?: InputMaybe<Scalars['ID']['input']>;
  moduleId?: InputMaybe<Scalars['ID']['input']>;
  phaseId?: InputMaybe<Scalars['ID']['input']>;
  targetId?: InputMaybe<Scalars['ID']['input']>;
};

export type RndInsightsBusinessDevelopmentAndLicensingDriversComponent = Component & {
  __typename?: 'RndInsightsBusinessDevelopmentAndLicensingDriversComponent';
  code: Scalars['String']['output'];
  componentType: ComponentType;
  icon: ComponentIcon;
  id: Scalars['Uuid']['output'];
  title: Scalars['String']['output'];
  viewType: ViewType;
};

export type RndInsightsBusinessDevelopmentAndLicensingDriversView = IDataView & {
  __typename?: 'RndInsightsBusinessDevelopmentAndLicensingDriversView';
  availableUserSettings: Array<UserSetting>;
  /** Type of the data view */
  dataViewType: DataViewType;
  /** DriversData */
  driversData: DriversData;
  /** Data view identifier */
  id: Scalars['Uuid']['output'];
  metadata: Array<MetaData>;
};

export type RndInsightsBusinessDevelopmentAndLicensingTableComponent = Component & {
  __typename?: 'RndInsightsBusinessDevelopmentAndLicensingTableComponent';
  code: Scalars['String']['output'];
  componentType: ComponentType;
  filters?: Maybe<Array<FilterComponent>>;
  icon: ComponentIcon;
  id: Scalars['Uuid']['output'];
  title: Scalars['String']['output'];
  viewType: ViewType;
};

export type RndInsightsBusinessDevelopmentAndLicensingTableDataView = IDataView & {
  __typename?: 'RndInsightsBusinessDevelopmentAndLicensingTableDataView';
  availableUserSettings: Array<UserSetting>;
  columns?: Maybe<Array<TableColumnResult>>;
  dataViewType: DataViewType;
  /** Dependent filters */
  filters?: Maybe<Array<FilterValue>>;
  id: Scalars['Uuid']['output'];
  /** Date of the last update */
  lastUpdateDate: Scalars['String']['output'];
  metadata: Array<MetaData>;
  rows?: Maybe<Array<Maybe<TableRowResult>>>;
};

export type RndInsightsDesignStudyAvailableCountry = {
  __typename?: 'RndInsightsDesignStudyAvailableCountry';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type RndInsightsDesignStudyAvailableCountryOption = {
  __typename?: 'RndInsightsDesignStudyAvailableCountryOption';
  countries: Array<RndInsightsDesignStudyAvailableCountry>;
  id: Scalars['String']['output'];
  label: Scalars['String']['output'];
};

export type RndInsightsSiteDistributionAvailableCountriesResult = {
  __typename?: 'RndInsightsSiteDistributionAvailableCountriesResult';
  data: Array<RndInsightsDesignStudyAvailableCountryOption>;
};

export type RndInsightsStudyDesignAvailableCountryByIdInput = {
  countryIds?: InputMaybe<Array<Scalars['String']['input']>>;
  studyId?: InputMaybe<Scalars['ID']['input']>;
};

export type RndInventoryRecommendationItem = {
  __typename?: 'RndInventoryRecommendationItem';
  recommendationItem?: Maybe<RecommendationItem>;
};

export type RndInventoryRecommendationsBreakdownComponent = Component & {
  __typename?: 'RndInventoryRecommendationsBreakdownComponent';
  /** Component code */
  code: Scalars['String']['output'];
  componentType: ComponentType;
  filters?: Maybe<Array<FilterComponent>>;
  icon: ComponentIcon;
  id: Scalars['Uuid']['output'];
  /** Title */
  title?: Maybe<Scalars['String']['output']>;
  viewType: ViewType;
};

export type RndInventoryRecommendationsBreakdownDataView = IDataView & {
  __typename?: 'RndInventoryRecommendationsBreakdownDataView';
  availableUserSettings: Array<UserSetting>;
  breakdownTabs?: Maybe<Array<Maybe<Component>>>;
  /** Type of the data view */
  dataViewType: DataViewType;
  id: Scalars['Uuid']['output'];
  metadata?: Maybe<Array<MetaData>>;
  pageHeader?: Maybe<Scalars['String']['output']>;
  pageSubHeader: Scalars['String']['output'];
};

export type RndInventoryRecommendationsComponent = Component & {
  __typename?: 'RndInventoryRecommendationsComponent';
  /** Component code */
  code: Scalars['String']['output'];
  componentType: ComponentType;
  filters?: Maybe<Array<FilterComponent>>;
  icon: ComponentIcon;
  id: Scalars['Uuid']['output'];
  title: Scalars['String']['output'];
  viewType: ViewType;
};

export type RndInventoryRecommendationsDataView = IDataView & {
  __typename?: 'RndInventoryRecommendationsDataView';
  availableUserSettings: Array<UserSetting>;
  /** Type of the data view */
  dataViewType: DataViewType;
  id: Scalars['Uuid']['output'];
  inventoryRecommendationsList?: Maybe<RndInventoryRecommendationsList>;
  link?: Maybe<Link>;
  metadata?: Maybe<Array<MetaData>>;
  sentiment?: Maybe<Sentiment>;
};

export type RndInventoryRecommendationsList = {
  __typename?: 'RndInventoryRecommendationsList';
  items?: Maybe<Array<RndInventoryRecommendationItem>>;
  title?: Maybe<Scalars['String']['output']>;
};

export type RndInventoryRootCausesComponent = Component & {
  __typename?: 'RndInventoryRootCausesComponent';
  /** Component code */
  code: Scalars['String']['output'];
  componentType: ComponentType;
  icon: ComponentIcon;
  id: Scalars['Uuid']['output'];
  title: Scalars['String']['output'];
  viewType: ViewType;
};

export type RndInventoryRootCausesDataInput = {
  drillDownIds?: InputMaybe<Array<InputMaybe<Scalars['Long']['input']>>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  moduleId?: InputMaybe<Scalars['ID']['input']>;
  priorityId?: InputMaybe<Scalars['Int']['input']>;
};

export type RndInventoryRootCausesDataView = IDataView & {
  __typename?: 'RndInventoryRootCausesDataView';
  availableUserSettings: Array<UserSetting>;
  dataViewType: DataViewType;
  id: Scalars['Uuid']['output'];
  /** MetaData */
  metadata?: Maybe<Array<MetaData>>;
  /** inventory root-causes */
  rootCauses?: Maybe<RootCausesDataView>;
};

export type RndModuleResult = {
  __typename?: 'RndModuleResult';
  aiPosDrivers?: Maybe<AiPosDriversResult>;
  breakdown?: Maybe<BreakdownResult>;
  businessDevelopmentAndLicensing?: Maybe<BusinessDevelopmentAndLicensingResult>;
  inventory?: Maybe<InventoryResult>;
  kpis?: Maybe<KpisResult>;
  studyDesigner?: Maybe<StudyDesignerResult>;
  valueOverview?: Maybe<ValueOverviewResult>;
};

export type RndStudyDesignFormComponent = Component & {
  __typename?: 'RndStudyDesignFormComponent';
  code: Scalars['String']['output'];
  componentType: ComponentType;
  icon: ComponentIcon;
  id: Scalars['Uuid']['output'];
  title: Scalars['String']['output'];
  viewType: ViewType;
};

export type RndStudyDesignerDeleteStudyDesignInput = {
  studyId: Scalars['Int']['input'];
};

export type RndStudyDesignerDuplicateStudyDesignInput = {
  studyId: Scalars['Int']['input'];
};

export type RndStudyDesignerEnrollmentChartDataInput = {
  globalRecruitmentRate?: InputMaybe<Scalars['Float']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  moduleId?: InputMaybe<Scalars['ID']['input']>;
  numberOfPatients?: InputMaybe<Scalars['Int']['input']>;
  numberOfSites?: InputMaybe<Scalars['Int']['input']>;
  studyId?: InputMaybe<Scalars['Int']['input']>;
};

export type RndStudyDesignerEnrollmentSliderParams = {
  __typename?: 'RndStudyDesignerEnrollmentSliderParams';
  globalRecruitmentRate: SliderFields;
  numberOfPatients: SliderFields;
  numberOfSites: SliderFields;
};

export type RndStudyDesignerEnrollmentSliderParamsInput = {
  studyId?: InputMaybe<Scalars['Int']['input']>;
};

export type RndStudyDesignerSiteDistributionCountryRecruitmentRateInput = {
  countryCode?: InputMaybe<Scalars['String']['input']>;
  countryRecruitmentRate?: InputMaybe<Scalars['Float']['input']>;
};

export type RndStudyDesignerSiteDistributionDataInput = {
  countryIdList?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  moduleId?: InputMaybe<Scalars['ID']['input']>;
  studyId?: InputMaybe<Scalars['Int']['input']>;
};

export type RndStudyDesignerSiteDistributionTableDataView = IDataView & {
  __typename?: 'RndStudyDesignerSiteDistributionTableDataView';
  availableUserSettings: Array<UserSetting>;
  columns?: Maybe<Array<TableColumnResult>>;
  createLink: Link;
  dataViewType: DataViewType;
  filters: Array<FilterValue>;
  id: Scalars['Uuid']['output'];
  lastUpdateDate?: Maybe<Scalars['String']['output']>;
  metadata: Array<MetaData>;
  rows?: Maybe<Array<Maybe<TableRowResult>>>;
};

export type RndStudyDesignerUpdateSiteDistributionInput = {
  countries?: InputMaybe<
    Array<InputMaybe<RndStudyDesignerSiteDistributionCountryRecruitmentRateInput>>
  >;
  studyId?: InputMaybe<Scalars['Int']['input']>;
};

export type RndStudyDesignsSiteDistributionTableComponent = Component & {
  __typename?: 'RndStudyDesignsSiteDistributionTableComponent';
  code: Scalars['String']['output'];
  componentType: ComponentType;
  icon: ComponentIcon;
  id: Scalars['Uuid']['output'];
  title: Scalars['String']['output'];
  viewType: ViewType;
};

export type RndStudyDesignsTableComponent = Component & {
  __typename?: 'RndStudyDesignsTableComponent';
  code: Scalars['String']['output'];
  componentType: ComponentType;
  icon: ComponentIcon;
  id: Scalars['Uuid']['output'];
  title: Scalars['String']['output'];
  viewType: ViewType;
};

export type RndStudyDesignsTableDataView = IDataView & {
  __typename?: 'RndStudyDesignsTableDataView';
  availableUserSettings: Array<UserSetting>;
  columns?: Maybe<Array<TableColumnResult>>;
  createLink: Link;
  dataViewType: DataViewType;
  filters: Array<FilterValue>;
  id: Scalars['Uuid']['output'];
  lastUpdateDate?: Maybe<Scalars['String']['output']>;
  metadata: Array<MetaData>;
  rows?: Maybe<Array<Maybe<TableRowResult>>>;
};

export type RndTimelineChartDataViewResult = IDataView & {
  __typename?: 'RndTimelineChartDataViewResult';
  availableUserSettings: Array<UserSetting>;
  /** Costs */
  costs: Scalars['String']['output'];
  dataViewType: DataViewType;
  id: Scalars['Uuid']['output'];
  /** Last patient in */
  lastPatientIn: Scalars['String']['output'];
  metadata: Array<MetaData>;
  /** Chart series */
  series?: Maybe<Array<ChartSeries>>;
  /** Axis x configuration */
  xAxis: ChartAxisCategoryViewResult;
  /** Axis y configuration */
  yAxis: ChartAxis;
};

export type RndTrialsAiPosDriverTable = {
  __typename?: 'RndTrialsAiPosDriverTable';
  /** Driver Label */
  driverLabel?: Maybe<Scalars['String']['output']>;
  /** Driver Value */
  driverValue?: Maybe<Scalars['String']['output']>;
  /** Whether the row is or not header */
  isHeader?: Maybe<Scalars['Boolean']['output']>;
  /** Sentiment */
  sentiment: Sentiment;
};

export type RndTrialsAiPosDriversComponent = Component & {
  __typename?: 'RndTrialsAiPosDriversComponent';
  /** Component code */
  code: Scalars['String']['output'];
  componentType: ComponentType;
  filters?: Maybe<Array<FilterComponent>>;
  icon: ComponentIcon;
  id: Scalars['Uuid']['output'];
  title: Scalars['String']['output'];
  viewType: ViewType;
};

export type RndTrialsAiPosDriversDataInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  moduleId?: InputMaybe<Scalars['ID']['input']>;
  priorityId?: InputMaybe<Scalars['Int']['input']>;
};

export type RndTrialsAiPosDriversDataView = IDataView & {
  __typename?: 'RndTrialsAiPosDriversDataView';
  /** AiPos for Drivers */
  aiPos: Scalars['String']['output'];
  availableUserSettings: Array<UserSetting>;
  /** Baseline for Drivers */
  baseline: Scalars['String']['output'];
  dataViewType: DataViewType;
  /** Header for Drivers */
  header: Scalars['String']['output'];
  id: Scalars['Uuid']['output'];
  metadata?: Maybe<Array<MetaData>>;
  /** SubHeader for Drivers */
  subHeader: Scalars['String']['output'];
  /** Table for Drivers */
  table?: Maybe<Array<Maybe<RndTrialsAiPosDriverTable>>>;
};

export type RndTrialsBreakdownAiPtrsDriver = {
  __typename?: 'RndTrialsBreakdownAiPtrsDriver';
  /** Driver Label */
  driverLabel?: Maybe<Scalars['String']['output']>;
  /** Driver Value */
  driverValue?: Maybe<Scalars['Decimal']['output']>;
  /** Whether the row is or not header */
  isHeader?: Maybe<Scalars['Boolean']['output']>;
};

export type RndTrialsBreakdownAiPtrsDriversDataInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  moduleId?: InputMaybe<Scalars['ID']['input']>;
  priorityId?: InputMaybe<Scalars['Int']['input']>;
  rowDimensionFilterId?: InputMaybe<Scalars['Int']['input']>;
};

export type RndTrialsBreakdownAiPtrsDriversDataView = IDataView & {
  __typename?: 'RndTrialsBreakdownAiPtrsDriversDataView';
  /** AI probability of success */
  aiProbabilityOfSuccess?: Maybe<Scalars['Decimal']['output']>;
  availableUserSettings: Array<UserSetting>;
  dataViewType: DataViewType;
  /** Drivers information */
  drivers?: Maybe<Array<Maybe<RndTrialsBreakdownAiPtrsDriver>>>;
  id: Scalars['Uuid']['output'];
  metadata?: Maybe<Array<MetaData>>;
  /** Probability of success */
  probabilityOfSuccess?: Maybe<Scalars['Decimal']['output']>;
  /** Project sub label */
  projecSubLabel?: Maybe<Scalars['String']['output']>;
  /** Project label */
  projectLabel?: Maybe<Scalars['String']['output']>;
};

export type RndTrialsKpiCategoriesComponent = Component & {
  __typename?: 'RndTrialsKpiCategoriesComponent';
  /** Component code */
  code: Scalars['String']['output'];
  componentType: ComponentType;
  filters?: Maybe<Array<FilterComponent>>;
  icon: ComponentIcon;
  id: Scalars['Uuid']['output'];
  title: Scalars['String']['output'];
  viewType: ViewType;
};

export type RndTrialsKpiCategoriesDataInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  moduleId?: InputMaybe<Scalars['ID']['input']>;
  priorityId?: InputMaybe<Scalars['Int']['input']>;
  timeFilterId?: InputMaybe<Scalars['Int']['input']>;
};

export type RndTrialsKpiCategoriesDataView = IDataView & {
  __typename?: 'RndTrialsKpiCategoriesDataView';
  availableUserSettings: Array<UserSetting>;
  /** List of KPI categories */
  categories?: Maybe<Array<RndTrialsKpiCategoryDataView>>;
  dataViewType: DataViewType;
  id: Scalars['Uuid']['output'];
  /** Date of the last update */
  lastUpdateDate: Scalars['String']['output'];
  /** MetaData */
  metadata?: Maybe<Array<MetaData>>;
  /** Title */
  title: Scalars['String']['output'];
};

export type RndTrialsKpiCategoryDataView = {
  __typename?: 'RndTrialsKpiCategoryDataView';
  /** List of KPIs in the categories */
  kpis?: Maybe<Array<RndTrialsKpiValue>>;
  /** Name of the KPI category */
  name: Scalars['String']['output'];
  /** Overall performance trend for the category */
  overallPerformance?: Maybe<ComparatorTrendResult>;
  /** Kpi performance */
  performance?: Maybe<RndTrialsKpiPerformance>;
  /** Type of the KPI category */
  type: Scalars['String']['output'];
};

export type RndTrialsKpiDetailTableComponent = Component & {
  __typename?: 'RndTrialsKpiDetailTableComponent';
  /** Component code */
  code: Scalars['String']['output'];
  componentType: ComponentType;
  filters?: Maybe<Array<FilterComponent>>;
  icon: ComponentIcon;
  id: Scalars['Uuid']['output'];
  title: Scalars['String']['output'];
  viewType: ViewType;
};

export type RndTrialsKpiDetailTableDataInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  kpiCategoryType?: InputMaybe<Scalars['String']['input']>;
  kpiCode?: InputMaybe<Scalars['String']['input']>;
  moduleId?: InputMaybe<Scalars['ID']['input']>;
  priorityId?: InputMaybe<Scalars['Int']['input']>;
  timeFilterId?: InputMaybe<Scalars['Int']['input']>;
};

export type RndTrialsKpiDetailTableDataView = IDataView & {
  __typename?: 'RndTrialsKpiDetailTableDataView';
  /** Actual value of the KPI */
  actual: FormattedValue;
  availableUserSettings: Array<UserSetting>;
  /** Unique identifier for the KPI */
  code: Scalars['String']['output'];
  /** Detail table columns */
  columns?: Maybe<Array<TableColumnResult>>;
  dataViewType: DataViewType;
  id: Scalars['Uuid']['output'];
  /** MetaData */
  metadata?: Maybe<Array<MetaData>>;
  /** Name of the KPI */
  name: Scalars['String']['output'];
  /** Overall performance trend for the KPI */
  overallPerformance?: Maybe<ComparatorTrendResult>;
  /** Planned value of the KPI */
  planned: Scalars['Float']['output'];
  rows?: Maybe<Array<Maybe<TableRowResult>>>;
  /** Target value of the KPI */
  target: Scalars['Float']['output'];
  /** KPI Team Leader */
  teamLead?: Maybe<Scalars['String']['output']>;
};

export type RndTrialsKpiPerformance = {
  __typename?: 'RndTrialsKpiPerformance';
  /** Performance percentage value */
  percentage: Scalars['Decimal']['output'];
  /** Performance sentiment */
  sentiment: Sentiment;
  /** Performance value */
  value: FormattedValue;
};

export type RndTrialsKpiTimeFilterDataInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  moduleId?: InputMaybe<Scalars['ID']['input']>;
  priorityId?: InputMaybe<Scalars['Int']['input']>;
};

export type RndTrialsKpiValue = {
  __typename?: 'RndTrialsKpiValue';
  /** Actual value of the KPI */
  actual: FormattedValue;
  /** Unique identifier for the KPI */
  code: Scalars['String']['output'];
  /** Name of the KPI */
  name: Scalars['String']['output'];
  /** Overall performance trend for the KPI */
  overallPerformance?: Maybe<ComparatorTrendResult>;
  /** Sentiment associated with the KPI */
  sentiment: Sentiment;
  /** Target value of the KPI */
  target: FormattedValue;
};

export type RndTrialsRndInvestmentsPnlTableComponent = Component & {
  __typename?: 'RndTrialsRndInvestmentsPnlTableComponent';
  code: Scalars['String']['output'];
  componentType: ComponentType;
  drillIds: Array<Scalars['Int']['output']>;
  filters?: Maybe<Array<FilterComponent>>;
  icon: ComponentIcon;
  id: Scalars['Uuid']['output'];
  title: Scalars['String']['output'];
  viewType: ViewType;
};

export type RndTrialsRndInvestmentsPnlTableDataView = IDataView & {
  __typename?: 'RndTrialsRndInvestmentsPnlTableDataView';
  availableUserSettings: Array<UserSetting>;
  /** Component code */
  code: Scalars['String']['output'];
  columns?: Maybe<Array<TableColumnResult>>;
  dataViewType: DataViewType;
  /** Dependent filters */
  filters?: Maybe<Array<FilterValue>>;
  id: Scalars['Uuid']['output'];
  metadata: Array<MetaData>;
  rows?: Maybe<Array<Maybe<TableRowResult>>>;
  /** Component title */
  title: Scalars['String']['output'];
};

export type RndTrialsValueOverviewChartDataInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  isVariance?: InputMaybe<Scalars['Boolean']['input']>;
  kpiFilterId?: InputMaybe<Scalars['Int']['input']>;
  moduleId?: InputMaybe<Scalars['ID']['input']>;
  periodFilterId?: InputMaybe<Scalars['Int']['input']>;
  priorityId?: InputMaybe<Scalars['Int']['input']>;
};

export type RndTrialsValueOverviewChartDataView = IDataView & {
  __typename?: 'RndTrialsValueOverviewChartDataView';
  availableUserSettings: Array<UserSetting>;
  chart?: Maybe<RndTrialsValueOverviewChartDataViewResult>;
  dataViewType: DataViewType;
  /** Id of the chart */
  id: Scalars['Uuid']['output'];
  metadata?: Maybe<Array<MetaData>>;
};

export type RndTrialsValueOverviewChartDataViewResult = IDataView & {
  __typename?: 'RndTrialsValueOverviewChartDataViewResult';
  availableUserSettings: Array<UserSetting>;
  dataViewType: DataViewType;
  id: Scalars['Uuid']['output'];
  metadata?: Maybe<Array<MetaData>>;
  /** Chart series */
  series?: Maybe<Array<ChartSeries>>;
  /** Axis x configuration */
  xAxis: ChartAxis;
  /** Axis y configuration */
  yAxis: ChartAxis;
};

export type RndTrialsValueOverviewChartHeaderDataInput = {
  id?: InputMaybe<Scalars['Uuid']['input']>;
  kpiFilterId: Scalars['Int']['input'];
  moduleId?: InputMaybe<Scalars['Uuid']['input']>;
  periodFilterId: Scalars['Int']['input'];
  priorityId?: InputMaybe<Scalars['Int']['input']>;
};

export type RndTrialsValueOverviewChartHeaderDataView = IDataView & {
  __typename?: 'RndTrialsValueOverviewChartHeaderDataView';
  /** Arrow of the chart header */
  arrow: TrendArrowResult;
  /** Arrow label of the chart header */
  arrowLabel: Scalars['String']['output'];
  availableUserSettings: Array<UserSetting>;
  /** Change of the chart header */
  change: VarianceResult;
  dataViewType: DataViewType;
  /** Id of the chart header */
  id: Scalars['Uuid']['output'];
  /** Last update date of the chart header */
  lastUpdateDate: Scalars['DateTime']['output'];
  metadata?: Maybe<Array<MetaData>>;
  /** Title of the chart header */
  title: Scalars['String']['output'];
  /** Value of the chart header */
  value: TextResult;
};

export type RndTrialsValueOverviewFiltersDataInput = {
  headerId?: InputMaybe<Scalars['Uuid']['input']>;
  kpiFilterId?: InputMaybe<Scalars['Long']['input']>;
  moduleId?: InputMaybe<Scalars['Uuid']['input']>;
  periodFilterId?: InputMaybe<Scalars['Long']['input']>;
  priorityId?: InputMaybe<Scalars['Int']['input']>;
};

export type RndValueOverviewChartComponent = Component & {
  __typename?: 'RndValueOverviewChartComponent';
  chartType: ChartType;
  /** Component code */
  code: Scalars['String']['output'];
  componentType: ComponentType;
  filters: Array<FilterComponent>;
  icon: ComponentIcon;
  id: Scalars['Uuid']['output'];
  title: Scalars['String']['output'];
  viewType: ViewType;
};

export type RndValueOverviewChartHeaderComponent = Component & {
  __typename?: 'RndValueOverviewChartHeaderComponent';
  /** Component code */
  code: Scalars['String']['output'];
  componentType: ComponentType;
  filters: Array<FilterComponent>;
  icon: ComponentIcon;
  id: Scalars['Uuid']['output'];
  title: Scalars['String']['output'];
  viewType: ViewType;
};

export type RootCausesDataView = {
  __typename?: 'RootCausesDataView';
  /** SubTitle of cause */
  causeSubtitle?: Maybe<Scalars['String']['output']>;
  /** Title of cause */
  causeTitle?: Maybe<Scalars['String']['output']>;
  /** SubTitle of recommendation box */
  recommendationSubtitle?: Maybe<Scalars['String']['output']>;
  /** Title of recommendation box */
  recommendationTitle?: Maybe<Scalars['String']['output']>;
  /** Icon for Root causes */
  rootCauseIcon?: Maybe<Icon>;
  /** SubTitle of root-cause box */
  rootCausesSubTitle?: Maybe<Scalars['String']['output']>;
  /** Title of root-cause box */
  rootCausesTitle?: Maybe<Scalars['String']['output']>;
};

export enum RowType {
  Aggregation = 'AGGREGATION',
  Category = 'CATEGORY',
  Default = 'DEFAULT',
  Header = 'HEADER',
  Phases = 'PHASES',
  Summary = 'SUMMARY',
}

export type SaveUserSettingsInput = {
  moduleId: Scalars['Uuid']['input'];
  settings: Array<UserSettingInput>;
};

export type ScreenResultType = {
  /** Screen type */
  screenType: ScreenType;
};

export enum ScreenType {
  Actions = 'ACTIONS',
  Fireworks = 'FIREWORKS',
  Progress = 'PROGRESS',
}

export type SelectFilter = Filter & {
  __typename?: 'SelectFilter';
  /** Additional Labels */
  additionalLabels: Array<Maybe<Scalars['String']['output']>>;
  /** Filter ApiKey */
  filterCode?: Maybe<Scalars['String']['output']>;
  filterType: FilterType;
  id: Scalars['Uuid']['output'];
  label?: Maybe<Scalars['String']['output']>;
  /** Hierarchical structure of filters */
  options?: Maybe<Array<FilterOptionResult>>;
  parentId?: Maybe<Scalars['Uuid']['output']>;
};

export type SelectFilterCodeInput = {
  /** Filter code */
  filterCode: Scalars['String']['input'];
  /** Filter value */
  value: Scalars['Int']['input'];
};

export type SelectFilterInput = {
  /** Filter identifier */
  id: Scalars['Uuid']['input'];
  /** Selected filter option code */
  optionCode?: InputMaybe<Scalars['String']['input']>;
  /** Selected filter option identifier */
  value: Scalars['Long']['input'];
};

export type SelectFilterValue = {
  __typename?: 'SelectFilterValue';
  /** Filter identifier */
  id: Scalars['Uuid']['output'];
  /** Selected filter option identifier */
  value: Scalars['Long']['output'];
};

export type SelectGroupFilter = Filter & {
  __typename?: 'SelectGroupFilter';
  additionalLabels: Array<Maybe<Scalars['String']['output']>>;
  filterCode?: Maybe<Scalars['String']['output']>;
  filterType: FilterType;
  /** Select filters that belong to group */
  filters: Array<SelectFilter>;
  id: Scalars['Uuid']['output'];
  label?: Maybe<Scalars['String']['output']>;
  parentId?: Maybe<Scalars['Uuid']['output']>;
};

export type SelectGroupFilterInput = {
  /** Identifier of the Select Group filter */
  id: Scalars['Uuid']['input'];
  /** Selected values for SelectGroupFilter's child filters */
  values: Array<SelectFilterCodeInput>;
};

export type SelectGroupFilterValue = {
  __typename?: 'SelectGroupFilterValue';
  /** Filter id */
  id: Scalars['Uuid']['output'];
  /** Child filters values */
  values: Array<Maybe<FilterCodeValue>>;
};

export type SelectOption = {
  __typename?: 'SelectOption';
  children: Array<SelectOption>;
  historicalValue?: Maybe<Scalars['Int']['output']>;
  isDefault: Scalars['Boolean']['output'];
  label: Scalars['String']['output'];
  sentiment?: Maybe<Sentiment>;
  value: Scalars['String']['output'];
};

export type SendSharingMutationInput = {
  linkId: Scalars['ID']['input'];
  tag?: InputMaybe<Scalars['String']['input']>;
  userIds: Array<Scalars['ID']['input']>;
};

export enum Sentiment {
  Critical = 'CRITICAL',
  Negative = 'NEGATIVE',
  Neutral = 'NEUTRAL',
  Positive = 'POSITIVE',
  SlightlyNegative = 'SLIGHTLY_NEGATIVE',
  SlightlyPositive = 'SLIGHTLY_POSITIVE',
  VeryNegative = 'VERY_NEGATIVE',
  VeryPositive = 'VERY_POSITIVE',
  Warning = 'WARNING',
}

export enum SeriesType {
  Area = 'AREA',
  AreaRange = 'AREA_RANGE',
  Bar = 'BAR',
  Column = 'COLUMN',
  ColumnRange = 'COLUMN_RANGE',
  Line = 'LINE',
  Lowmedhigh = 'LOWMEDHIGH',
  Spline = 'SPLINE',
  Waterfall = 'WATERFALL',
}

export type SettingOption = {
  __typename?: 'SettingOption';
  label: Scalars['String']['output'];
  value: Scalars['Long']['output'];
};

export enum ShapesEnum {
  Circle = 'CIRCLE',
  Diamond = 'DIAMOND',
  None = 'NONE',
  Square = 'SQUARE',
}

export type ShareLinkInput = {
  link: Scalars['String']['input'];
};

export type ShareableLink = {
  __typename?: 'ShareableLink';
  availableTags: Array<InboxItemTag>;
  availableTeams: Array<SharingTeamResult>;
  availableUsers: Array<SharingUserResult>;
  id: Scalars['Uuid']['output'];
  link: Scalars['String']['output'];
  moduleCode: ModuleCode;
  subtitle?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};

export type SharedLink = {
  __typename?: 'SharedLink';
  /** Content type of the shared link. */
  contentType: InboxContentType;
  /** Shared link unique ID. */
  id: Scalars['Uuid']['output'];
  /** Indicates if the link is expired. */
  isExpired: Scalars['Boolean']['output'];
  /** Indicates if the link is followed by the user. */
  isFollowed: Scalars['Boolean']['output'];
  /** Shared link to a specific page in the app. */
  link: Scalars['String']['output'];
  /** Module code of the shared link. */
  moduleCode: ModuleCode;
  /** Date and time when the link was shared. */
  sentAt?: Maybe<Scalars['DateTime']['output']>;
  /** Display name of the user who shared the link. */
  sharedBy: Scalars['String']['output'];
  /** Shared link subtitle. */
  subtitle?: Maybe<Scalars['String']['output']>;
  /** Tag type of the shared link. */
  tag?: Maybe<InboxItemTag>;
  /** Shared link title. */
  title: Scalars['String']['output'];
};

export type SharingTeamResult = {
  __typename?: 'SharingTeamResult';
  displayName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  teamUsers: Array<SharingUserResult>;
};

export type SharingUserResult = {
  __typename?: 'SharingUserResult';
  displayName: Scalars['String']['output'];
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isSuggested: Scalars['Boolean']['output'];
};

export type SimpleCardResult = Card & {
  __typename?: 'SimpleCardResult';
  /** Card Type */
  cardType: CardType;
  code: Scalars['String']['output'];
  /** Data view identifier */
  id: Scalars['String']['output'];
  /** Link result */
  linkResult?: Maybe<Link>;
  /** Text result */
  textResult?: Maybe<TextResult>;
  /** Card title */
  title: TextResult;
  /** Variance result */
  variance?: Maybe<VarianceResult>;
};

export type SliderFields = {
  __typename?: 'SliderFields';
  /** DefaultValue value */
  defaultValue?: Maybe<Scalars['Float']['output']>;
  /** Max value */
  max?: Maybe<Scalars['Float']['output']>;
  /** Min value */
  min?: Maybe<Scalars['Float']['output']>;
  /** Step value */
  step?: Maybe<Scalars['Float']['output']>;
};

export type SpendCardDataView = IDataView & {
  __typename?: 'SpendCardDataView';
  /** Annotation */
  annotation?: Maybe<Scalars['String']['output']>;
  availableUserSettings: Array<UserSetting>;
  /** Cards */
  cards?: Maybe<Array<Maybe<SpendHomeCardResult>>>;
  /** Type of the data view */
  dataViewType: DataViewType;
  /** Edit link */
  editLink?: Maybe<Link>;
  /** Data view identifier */
  id: Scalars['Uuid']['output'];
  metadata?: Maybe<Array<MetaData>>;
  /** Title */
  title?: Maybe<Scalars['String']['output']>;
};

export type SpendCardResult = {
  __typename?: 'SpendCardResult';
  /** Country */
  country: Scalars['String']['output'];
  /** Spend value */
  delta: Scalars['String']['output'];
  /** Material */
  material: Scalars['String']['output'];
  /** Card sentiment */
  sentiment?: Maybe<Sentiment>;
  /** Spend value */
  spend: Scalars['String']['output'];
  /** Spend value */
  total: Scalars['String']['output'];
};

export type SpendCmoPulseBusinessUnitFilterDataInput = {
  businessUnitFilterId?: InputMaybe<Scalars['Int']['input']>;
  dimensionIds?: InputMaybe<Array<InputMaybe<Scalars['Long']['input']>>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  keyDriverFilterId?: InputMaybe<Scalars['Int']['input']>;
  moduleId?: InputMaybe<Scalars['ID']['input']>;
  priorityId?: InputMaybe<Scalars['Int']['input']>;
};

export type SpendCmoPulseDataInput = {
  componentCode?: InputMaybe<Scalars['String']['input']>;
  dimensionIds?: InputMaybe<Array<InputMaybe<Scalars['Long']['input']>>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  moduleId?: InputMaybe<Scalars['ID']['input']>;
  priorityId?: InputMaybe<Scalars['Int']['input']>;
  userSettings?: InputMaybe<Array<UserSettingInput>>;
};

export type SpendCmoPulseDialComponent = Component & {
  __typename?: 'SpendCmoPulseDialComponent';
  /** Component code */
  code: Scalars['String']['output'];
  componentType: ComponentType;
  icon: ComponentIcon;
  id: Scalars['Uuid']['output'];
  title: Scalars['String']['output'];
  viewType: ViewType;
};

export type SpendCmoPulseDialDataView = IDataView & {
  __typename?: 'SpendCmoPulseDialDataView';
  availableUserSettings: Array<UserSetting>;
  /** Type of the data view */
  dataViewType: DataViewType;
  /** Link configuration */
  driversPageLink?: Maybe<Link>;
  /** Data view identifier */
  id: Scalars['Uuid']['output'];
  metadata?: Maybe<Array<MetaData>>;
  /** Message to display when no data is available for selection */
  noDataMessage?: Maybe<SpendNoDataMessage>;
  /** Dial Progress */
  progress?: Maybe<Scalars['Int']['output']>;
  /** Dial Target */
  progressTarget?: Maybe<Scalars['Int']['output']>;
  /** Sentiment for progress of the Dial */
  sentiment?: Maybe<Sentiment>;
  /** Subtitle of the Dial */
  subtitle?: Maybe<Scalars['String']['output']>;
  /** Title of the Dial */
  title?: Maybe<Scalars['String']['output']>;
};

export type SpendCmoPulseDriversChartComponent = Component & {
  __typename?: 'SpendCmoPulseDriversChartComponent';
  /** Component code */
  code: Scalars['String']['output'];
  componentType: ComponentType;
  filters?: Maybe<Array<FilterComponent>>;
  icon: ComponentIcon;
  id: Scalars['Uuid']['output'];
  title: Scalars['String']['output'];
  viewType: ViewType;
};

export type SpendCmoPulseDriversChartDataInput = {
  businessUnitFilterId?: InputMaybe<Scalars['Long']['input']>;
  componentCode?: InputMaybe<Scalars['String']['input']>;
  dimensionIds?: InputMaybe<Array<InputMaybe<Scalars['Long']['input']>>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  keyDriverFilterId?: InputMaybe<Scalars['Long']['input']>;
  moduleId?: InputMaybe<Scalars['ID']['input']>;
  priorityId?: InputMaybe<Scalars['Long']['input']>;
  timeFilterId?: InputMaybe<Scalars['Long']['input']>;
  userSettings?: InputMaybe<Array<UserSettingInput>>;
};

export type SpendCmoPulseDriversChartDataView = IDataView & {
  __typename?: 'SpendCmoPulseDriversChartDataView';
  availableUserSettings: Array<UserSetting>;
  chart: ChartDataViewResult;
  /** Key Drivers Chart Headers */
  cmoPulseDriverChartHeader: CmoPulseDriversChartHeader;
  dataViewType: DataViewType;
  id: Scalars['Uuid']['output'];
  lastUpdateDate?: Maybe<Scalars['String']['output']>;
  metadata: Array<MetaData>;
  yearSelector: YearSelector;
};

export type SpendCmoPulseDriversChartFilterDataInput = {
  businessUnitFilterId?: InputMaybe<Scalars['Int']['input']>;
  dimensionIds?: InputMaybe<Array<InputMaybe<Scalars['Long']['input']>>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  keyDriverFilterId?: InputMaybe<Scalars['Int']['input']>;
  moduleId?: InputMaybe<Scalars['ID']['input']>;
  priorityId?: InputMaybe<Scalars['Int']['input']>;
};

export type SpendCmoPulseDriversListComponent = Component & {
  __typename?: 'SpendCmoPulseDriversListComponent';
  code: Scalars['String']['output'];
  componentType: ComponentType;
  icon: ComponentIcon;
  id: Scalars['Uuid']['output'];
  title: Scalars['String']['output'];
  viewType: ViewType;
};

export type SpendCmoPulseDriversListDataInput = {
  componentCode?: InputMaybe<Scalars['String']['input']>;
  dimensionIds?: InputMaybe<Array<InputMaybe<Scalars['Long']['input']>>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  keyDriverFilterId?: InputMaybe<Scalars['Long']['input']>;
  moduleId?: InputMaybe<Scalars['ID']['input']>;
  priorityId?: InputMaybe<Scalars['Long']['input']>;
  userSettings?: InputMaybe<Array<UserSettingInput>>;
};

export type SpendCmoPulseDriversListDataView = IDataView & {
  __typename?: 'SpendCmoPulseDriversListDataView';
  availableUserSettings: Array<UserSetting>;
  dataViewType: DataViewType;
  /** Drivers List */
  driversList: Array<Maybe<CardContent>>;
  id: Scalars['Uuid']['output'];
  lastUpdateDate?: Maybe<Scalars['String']['output']>;
  metadata: Array<MetaData>;
  subTitle?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type SpendCmoPulseKeyDriverFilterDataInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  moduleId?: InputMaybe<Scalars['ID']['input']>;
  priorityId?: InputMaybe<Scalars['Int']['input']>;
};

export type SpendCmoPulseKeyDriversChartComponent = Component & {
  __typename?: 'SpendCmoPulseKeyDriversChartComponent';
  /** Component code */
  code: Scalars['String']['output'];
  componentType: ComponentType;
  filters?: Maybe<Array<FilterComponent>>;
  icon: ComponentIcon;
  id: Scalars['Uuid']['output'];
  title: Scalars['String']['output'];
  viewType: ViewType;
};

export type SpendCmoPulseKeyDriversListComponent = Component & {
  __typename?: 'SpendCmoPulseKeyDriversListComponent';
  code: Scalars['String']['output'];
  componentType: ComponentType;
  icon: ComponentIcon;
  id: Scalars['Uuid']['output'];
  title: Scalars['String']['output'];
  viewType: ViewType;
};

export type SpendCmoPulseLeaderboardDataInput = {
  businessUnitFilterId?: InputMaybe<Scalars['Long']['input']>;
  componentCode?: InputMaybe<Scalars['String']['input']>;
  dimensionIds?: InputMaybe<Array<InputMaybe<Scalars['Long']['input']>>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  keyDriverFilterId?: InputMaybe<Scalars['Long']['input']>;
  moduleId?: InputMaybe<Scalars['ID']['input']>;
  priorityId?: InputMaybe<Scalars['Int']['input']>;
  userSettings?: InputMaybe<Array<UserSettingInput>>;
};

export type SpendCmoPulseLeaderboardPositionComponent = Component & {
  __typename?: 'SpendCmoPulseLeaderboardPositionComponent';
  /** Component code */
  code: Scalars['String']['output'];
  componentType: ComponentType;
  icon: ComponentIcon;
  id: Scalars['Uuid']['output'];
  title: Scalars['String']['output'];
  viewType: ViewType;
};

export type SpendCmoPulseRecommendedActionsComponent = Component & {
  __typename?: 'SpendCmoPulseRecommendedActionsComponent';
  /** Component code */
  code: Scalars['String']['output'];
  componentType: ComponentType;
  icon: ComponentIcon;
  id: Scalars['Uuid']['output'];
  title: Scalars['String']['output'];
  viewType: ViewType;
};

export type SpendGlocalPulseBusinessUnitFilterDataInput = {
  businessUnitFilterId?: InputMaybe<Scalars['Int']['input']>;
  dimensionIds?: InputMaybe<Array<InputMaybe<Scalars['Long']['input']>>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  keyDriverFilterId?: InputMaybe<Scalars['Int']['input']>;
  moduleId?: InputMaybe<Scalars['ID']['input']>;
  priorityId?: InputMaybe<Scalars['Int']['input']>;
};

export type SpendGlocalPulseDataInput = {
  componentCode?: InputMaybe<Scalars['String']['input']>;
  dimensionIds?: InputMaybe<Array<InputMaybe<Scalars['Long']['input']>>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  moduleId?: InputMaybe<Scalars['ID']['input']>;
  priorityId?: InputMaybe<Scalars['Int']['input']>;
  userSettings?: InputMaybe<Array<UserSettingInput>>;
};

export type SpendGlocalPulseDialComponent = Component & {
  __typename?: 'SpendGlocalPulseDialComponent';
  /** Component code */
  code: Scalars['String']['output'];
  componentType: ComponentType;
  icon: ComponentIcon;
  id: Scalars['Uuid']['output'];
  title: Scalars['String']['output'];
  viewType: ViewType;
};

export type SpendGlocalPulseDialDataView = IDataView & {
  __typename?: 'SpendGlocalPulseDialDataView';
  availableUserSettings: Array<UserSetting>;
  /** Type of the data view */
  dataViewType: DataViewType;
  /** Link configuration */
  driversPageLink?: Maybe<Link>;
  /** Data view identifier */
  id: Scalars['Uuid']['output'];
  metadata?: Maybe<Array<MetaData>>;
  /** Message to display when no data is available for selection */
  noDataMessage?: Maybe<SpendNoDataMessage>;
  /** Dial Progress */
  progress?: Maybe<Scalars['Int']['output']>;
  /** Dial Target */
  progressTarget?: Maybe<Scalars['Int']['output']>;
  /** Sentiment for progress of the Dial */
  sentiment?: Maybe<Sentiment>;
  /** Subtitle of the Dial */
  subtitle?: Maybe<Scalars['String']['output']>;
  /** Title of the Dial */
  title?: Maybe<Scalars['String']['output']>;
};

export type SpendGlocalPulseDriversChartComponent = Component & {
  __typename?: 'SpendGlocalPulseDriversChartComponent';
  /** Component code */
  code: Scalars['String']['output'];
  componentType: ComponentType;
  filters?: Maybe<Array<FilterComponent>>;
  icon: ComponentIcon;
  id: Scalars['Uuid']['output'];
  title: Scalars['String']['output'];
  viewType: ViewType;
};

export type SpendGlocalPulseDriversChartDataInput = {
  businessUnitFilterId?: InputMaybe<Scalars['Long']['input']>;
  componentCode?: InputMaybe<Scalars['String']['input']>;
  dimensionIds?: InputMaybe<Array<InputMaybe<Scalars['Long']['input']>>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  keyDriverFilterId?: InputMaybe<Scalars['Long']['input']>;
  moduleId?: InputMaybe<Scalars['ID']['input']>;
  priorityId?: InputMaybe<Scalars['Long']['input']>;
  timeFilterId?: InputMaybe<Scalars['Long']['input']>;
  userSettings?: InputMaybe<Array<UserSettingInput>>;
};

export type SpendGlocalPulseDriversChartDataView = IDataView & {
  __typename?: 'SpendGlocalPulseDriversChartDataView';
  availableUserSettings: Array<UserSetting>;
  chart: ChartDataViewResult;
  dataViewType: DataViewType;
  /** Key Drivers Chart Headers */
  glocalPulseDriverChartHeader: GlocalPulseDriversChartHeader;
  id: Scalars['Uuid']['output'];
  lastUpdateDate?: Maybe<Scalars['String']['output']>;
  metadata: Array<MetaData>;
  yearSelector: YearSelector;
};

export type SpendGlocalPulseDriversChartFilterDataInput = {
  businessUnitFilterId?: InputMaybe<Scalars['Int']['input']>;
  dimensionIds?: InputMaybe<Array<InputMaybe<Scalars['Long']['input']>>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  keyDriverFilterId?: InputMaybe<Scalars['Int']['input']>;
  moduleId?: InputMaybe<Scalars['ID']['input']>;
  priorityId?: InputMaybe<Scalars['Int']['input']>;
};

export type SpendGlocalPulseDriversListComponent = Component & {
  __typename?: 'SpendGlocalPulseDriversListComponent';
  code: Scalars['String']['output'];
  componentType: ComponentType;
  icon: ComponentIcon;
  id: Scalars['Uuid']['output'];
  title: Scalars['String']['output'];
  viewType: ViewType;
};

export type SpendGlocalPulseDriversListDataInput = {
  componentCode?: InputMaybe<Scalars['String']['input']>;
  dimensionIds?: InputMaybe<Array<InputMaybe<Scalars['Long']['input']>>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  keyDriverFilterId?: InputMaybe<Scalars['Long']['input']>;
  moduleId?: InputMaybe<Scalars['ID']['input']>;
  priorityId?: InputMaybe<Scalars['Long']['input']>;
  userSettings?: InputMaybe<Array<UserSettingInput>>;
};

export type SpendGlocalPulseDriversListDataView = IDataView & {
  __typename?: 'SpendGlocalPulseDriversListDataView';
  availableUserSettings: Array<UserSetting>;
  dataViewType: DataViewType;
  /** Drivers List */
  driversList: Array<Maybe<CardContent>>;
  id: Scalars['Uuid']['output'];
  lastUpdateDate?: Maybe<Scalars['String']['output']>;
  metadata: Array<MetaData>;
  subTitle?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type SpendGlocalPulseKeyDriverFilterDataInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  moduleId?: InputMaybe<Scalars['ID']['input']>;
  priorityId?: InputMaybe<Scalars['Int']['input']>;
};

export type SpendGlocalPulseKeyDriversChartComponent = Component & {
  __typename?: 'SpendGlocalPulseKeyDriversChartComponent';
  /** Component code */
  code: Scalars['String']['output'];
  componentType: ComponentType;
  filters?: Maybe<Array<FilterComponent>>;
  icon: ComponentIcon;
  id: Scalars['Uuid']['output'];
  title: Scalars['String']['output'];
  viewType: ViewType;
};

export type SpendGlocalPulseKeyDriversListComponent = Component & {
  __typename?: 'SpendGlocalPulseKeyDriversListComponent';
  code: Scalars['String']['output'];
  componentType: ComponentType;
  icon: ComponentIcon;
  id: Scalars['Uuid']['output'];
  title: Scalars['String']['output'];
  viewType: ViewType;
};

export type SpendGlocalPulseLeaderboardDataInput = {
  businessUnitFilterId?: InputMaybe<Scalars['Long']['input']>;
  componentCode?: InputMaybe<Scalars['String']['input']>;
  dimensionIds?: InputMaybe<Array<InputMaybe<Scalars['Long']['input']>>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  keyDriverFilterId?: InputMaybe<Scalars['Long']['input']>;
  moduleId?: InputMaybe<Scalars['ID']['input']>;
  priorityId?: InputMaybe<Scalars['Int']['input']>;
  userSettings?: InputMaybe<Array<UserSettingInput>>;
};

export type SpendGlocalPulseLeaderboardListComponent = Component & {
  __typename?: 'SpendGlocalPulseLeaderboardListComponent';
  /** Component code */
  code: Scalars['String']['output'];
  componentType: ComponentType;
  filters?: Maybe<Array<FilterComponent>>;
  icon: ComponentIcon;
  id: Scalars['Uuid']['output'];
  title: Scalars['String']['output'];
  viewType: ViewType;
};

export type SpendGlocalPulseLeaderboardListDataView = IDataView & {
  __typename?: 'SpendGlocalPulseLeaderboardListDataView';
  availableUserSettings: Array<UserSetting>;
  /** Leaderboard Card Ranking */
  cardRanking: Array<Maybe<CardRanking>>;
  dataViewType: DataViewType;
  /** Leaderboard Headers */
  header: LeaderboardHeader;
  id: Scalars['Uuid']['output'];
  lastUpdateDate?: Maybe<Scalars['String']['output']>;
  metadata: Array<MetaData>;
  subtitle: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type SpendGlocalPulseLeaderboardPositionComponent = Component & {
  __typename?: 'SpendGlocalPulseLeaderboardPositionComponent';
  /** Component code */
  code: Scalars['String']['output'];
  componentType: ComponentType;
  icon: ComponentIcon;
  id: Scalars['Uuid']['output'];
  title: Scalars['String']['output'];
  viewType: ViewType;
};

export type SpendGlocalPulseRecommendedActionsComponent = Component & {
  __typename?: 'SpendGlocalPulseRecommendedActionsComponent';
  /** Component code */
  code: Scalars['String']['output'];
  componentType: ComponentType;
  icon: ComponentIcon;
  id: Scalars['Uuid']['output'];
  title: Scalars['String']['output'];
  viewType: ViewType;
};

export type SpendHomeCardResult = {
  __typename?: 'SpendHomeCardResult';
  /** Card actual value */
  actual: Scalars['String']['output'];
  /** Card Type */
  cardType: CardType;
  id: Scalars['String']['output'];
  /** Link result */
  linkResult?: Maybe<Link>;
  /** Card variance value */
  sentiment: Sentiment;
  /** Card sub title */
  subTitle?: Maybe<Scalars['String']['output']>;
  /** Card title */
  title: Scalars['String']['output'];
  /** Card variance value */
  variance: Scalars['String']['output'];
};

export type SpendHomeCardsComponent = Component & {
  __typename?: 'SpendHomeCardsComponent';
  /** Component code */
  code: Scalars['String']['output'];
  componentType: ComponentType;
  filters?: Maybe<Array<FilterComponent>>;
  icon: ComponentIcon;
  id: Scalars['Uuid']['output'];
  title: Scalars['String']['output'];
  viewType: ViewType;
};

export type SpendHomeDial = {
  __typename?: 'SpendHomeDial';
  /** Value of dial described by formatted string */
  label: Scalars['String']['output'];
  link?: Maybe<Link>;
  /** Float value for progress (how much circle is filled */
  progress: Scalars['Float']['output'];
  /** Value of dial described by formatted string */
  progressText: TextResult;
  /** Sentiment of circle which is translated to the color on frontend side */
  sentiment: Color;
  /** Int value of sort order */
  sortOrder?: Maybe<Scalars['Int']['output']>;
  /** Float value of target (white dot inside circle) */
  target?: Maybe<Scalars['Float']['output']>;
  /** Value of dial described by formatted string */
  varianceText: TextResult;
};

export type SpendHomeDialComponent = Component & {
  __typename?: 'SpendHomeDialComponent';
  /** Component code */
  code: Scalars['String']['output'];
  componentType: ComponentType;
  filters?: Maybe<Array<FilterComponent>>;
  icon: ComponentIcon;
  id: Scalars['Uuid']['output'];
  title: Scalars['String']['output'];
  viewType: ViewType;
};

export type SpendHomeDialDataView = IDataView & {
  __typename?: 'SpendHomeDialDataView';
  annotation?: Maybe<Scalars['String']['output']>;
  annotationDate?: Maybe<Scalars['String']['output']>;
  availableUserSettings: Array<UserSetting>;
  /** Type of the data view */
  dataViewType: DataViewType;
  id: Scalars['Uuid']['output'];
  /** Dials */
  items: Array<Maybe<SpendHomeDial>>;
  metadata?: Maybe<Array<MetaData>>;
  title: Scalars['String']['output'];
};

export type SpendModuleResult = {
  __typename?: 'SpendModuleResult';
  /** Cmo Pulse Focus Area Data */
  cmoPulse?: Maybe<CmoPulseResult>;
  /** Cmo Pulse Key Drivers Focus Area Data */
  cmoPulseKeyDrivers?: Maybe<CmoPulseKeyDriversResult>;
  /** Glocal Pulse Focus Area Data */
  glocalPulse?: Maybe<GlocalPulseResult>;
  /** Glocal Pulse Key Drivers Focus Area Data */
  glocalPulseKeyDrivers?: Maybe<GlocalPulseKeyDriversResult>;
  /** Glocal Pulse Leaderboard List Focus Area Data */
  glocalPulseLeaderboard?: Maybe<GlocalPulseLeaderboardListResult>;
};

export type SpendNoDataMessage = {
  __typename?: 'SpendNoDataMessage';
  content: Scalars['String']['output'];
  header: Scalars['String']['output'];
};

export type SpendProcurementChartComponent = Component & {
  __typename?: 'SpendProcurementChartComponent';
  code: Scalars['String']['output'];
  componentType: ComponentType;
  drillIds: Array<Scalars['Int']['output']>;
  filters?: Maybe<Array<FilterComponent>>;
  icon: ComponentIcon;
  id: Scalars['Uuid']['output'];
  title: Scalars['String']['output'];
  viewType: ViewType;
};

export type SpendProcurementChartDataView = IDataView & {
  __typename?: 'SpendProcurementChartDataView';
  availableUserSettings: Array<UserSetting>;
  /** Type of the data view */
  dataViewType: DataViewType;
  /** Id of the row for which is chart displayed */
  drillId?: Maybe<Scalars['Int']['output']>;
  /** Selected filters for chart */
  filters?: Maybe<Array<FilterValue>>;
  /** Data view identifier */
  id: Scalars['Uuid']['output'];
  /** MetaData */
  metadata?: Maybe<Array<MetaData>>;
  /** Chart series */
  series?: Maybe<Array<ChartSeries>>;
  /** Axis x configuration */
  xAxis: ChartAxis;
  /** Axis y configuration */
  yAxis: ChartAxis;
};

export type SpendProcurementTableComponent = Component & {
  __typename?: 'SpendProcurementTableComponent';
  code: Scalars['String']['output'];
  componentType: ComponentType;
  drillIds: Array<Scalars['Int']['output']>;
  filters?: Maybe<Array<FilterComponent>>;
  icon: ComponentIcon;
  id: Scalars['Uuid']['output'];
  title: Scalars['String']['output'];
  viewType: ViewType;
};

export type SpendProcurementTableDataView = IDataView & {
  __typename?: 'SpendProcurementTableDataView';
  availableUserSettings: Array<UserSetting>;
  /** Table columns */
  columns?: Maybe<Array<TableColumnResult>>;
  /** Type of the data view */
  dataViewType: DataViewType;
  /** Dependent filters */
  filters?: Maybe<Array<FilterValue>>;
  /** Data view identifier */
  id: Scalars['Uuid']['output'];
  /** Formatted date of last data update */
  lastUpdateDate?: Maybe<Scalars['String']['output']>;
  /** MetaData */
  metadata?: Maybe<Array<MetaData>>;
  /** Table rows */
  rows?: Maybe<Array<Maybe<TableRowResult>>>;
};

export type SpendTrendingComponent = Component & {
  __typename?: 'SpendTrendingComponent';
  /** Component code */
  code: Scalars['String']['output'];
  componentType: ComponentType;
  filters?: Maybe<Array<FilterComponent>>;
  icon: ComponentIcon;
  id: Scalars['Uuid']['output'];
  title: Scalars['String']['output'];
  viewType: ViewType;
};

export type SpendTrendingResult = IDataView & {
  __typename?: 'SpendTrendingResult';
  availableUserSettings: Array<UserSetting>;
  /** Cards */
  cards?: Maybe<Array<Maybe<SpendCardResult>>>;
  /** Type of the data view */
  dataViewType: DataViewType;
  id: Scalars['Uuid']['output'];
  metadata?: Maybe<Array<MetaData>>;
  /** Title */
  title?: Maybe<Scalars['String']['output']>;
};

export type StatusTextResult = {
  __typename?: 'StatusTextResult';
  /** Label content */
  content: Scalars['String']['output'];
  /** Sentiment for value */
  sentiment?: Maybe<Sentiment>;
};

export type StoriesQueryInput = {
  moduleId: Scalars['Uuid']['input'];
};

export type Story = {
  __typename?: 'Story';
  additionalFilters: Array<AdditionalFilter>;
  /** Number of brains */
  brainsCount: Scalars['Int']['output'];
  /** Caption */
  caption?: Maybe<Scalars['String']['output']>;
  /** Components */
  content?: Maybe<Array<Maybe<Component>>>;
  /** Story decision */
  decision?: Maybe<StoryDecision>;
  /** Formatted value */
  formattedValue?: Maybe<Scalars['String']['output']>;
  /** True if story can be liked */
  hasFeedback: Scalars['Boolean']['output'];
  /** Story's headline */
  headline: Scalars['String']['output'];
  /** Story identifier */
  id: Scalars['Uuid']['output'];
  insightsType: InsightsType;
  /** Story is added to brain by user */
  isAddedToBrain?: Maybe<Scalars['Boolean']['output']>;
  /** Story is liked by user */
  isLiked?: Maybe<Scalars['Boolean']['output']>;
  /** Story is read by user */
  isRead: Scalars['Boolean']['output'];
  /** True if story can be shared */
  isShareable: Scalars['Boolean']['output'];
  /** Legacy link */
  legacyLink?: Maybe<LegacyLink>;
  /** Link */
  link?: Maybe<Link>;
  /** Module code */
  module: ModuleCode;
  /** Publish date */
  publishDate: Scalars['Date']['output'];
  /** Story's recommender headline */
  recommenderHeadline: Scalars['String']['output'];
  /** Story's reference */
  reference?: Maybe<ExternalLink>;
  /** Story's sentiment */
  sentiment?: Maybe<Sentiment>;
  /** Stamp */
  stamp?: Maybe<Scalars['String']['output']>;
  /** Story data module */
  storyDataModule: ModuleCode;
  /** Story type */
  storyType: StoryDisplayType;
  /** Tag */
  tag?: Maybe<Scalars['String']['output']>;
  /** Story's timestamp */
  timeStamp?: Maybe<Scalars['String']['output']>;
};

export type StoryDecision = {
  __typename?: 'StoryDecision';
  /** Is take */
  isTaken: Scalars['Boolean']['output'];
  /** Scree ids */
  screenIds: Array<Maybe<Scalars['Int']['output']>>;
  /** Subtitle */
  subTitle: Scalars['String']['output'];
  /** Title */
  title: Scalars['String']['output'];
};

export enum StoryDisplayType {
  AiDriveFocus = 'AI_DRIVE_FOCUS',
  AiInspired = 'AI_INSPIRED',
  AiXplained = 'AI_XPLAINED',
  Default = 'DEFAULT',
  Media = 'MEDIA',
  PlailistStory = 'PLAILIST_STORY',
  Real = 'REAL',
  Whisper = 'WHISPER',
  WhisperReal = 'WHISPER_REAL',
}

export type StoryListComponent = Component & {
  __typename?: 'StoryListComponent';
  /** Component code */
  code: Scalars['String']['output'];
  componentType: ComponentType;
  icon: ComponentIcon;
  id: Scalars['Uuid']['output'];
  title: Scalars['String']['output'];
  viewType: ViewType;
};

export type StudyDesign = {
  __typename?: 'StudyDesign';
  comment?: Maybe<Scalars['String']['output']>;
  costs: Scalars['String']['output'];
  duration: Scalars['String']['output'];
  firstPatientIn: Scalars['String']['output'];
  globalRecruitmentRate: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  isOwner: Scalars['Boolean']['output'];
  lastPatientOut: Scalars['String']['output'];
  numberOfPatients: Scalars['Int']['output'];
  numberOfPatientsAndSitesLabel: Scalars['String']['output'];
  numberOfSites: Scalars['Int']['output'];
  siteDistributionNorthAmerica: Scalars['Int']['output'];
  siteDistributionNorthernEurope: Scalars['Int']['output'];
  siteDistributionTotalCountries: Scalars['Int']['output'];
  subtitle: Scalars['String']['output'];
  summaryRegions: Array<StudyDesignSiteDistributionRegionItem>;
  title: Scalars['String']['output'];
  topCompetitorName: Scalars['String']['output'];
  topCompetitorPace: Scalars['String']['output'];
  topCompetitorPaceSentiment: Sentiment;
};

export type StudyDesignLinkResult = {
  __typename?: 'StudyDesignLinkResult';
  id?: Maybe<Scalars['String']['output']>;
  isOwner?: Maybe<Scalars['Boolean']['output']>;
  subtitle: Scalars['String']['output'];
  title?: Maybe<Scalars['String']['output']>;
};

export type StudyDesignSiteDistributionRegionItem = {
  __typename?: 'StudyDesignSiteDistributionRegionItem';
  numberOfSites: Scalars['Int']['output'];
  regionLabel: Scalars['String']['output'];
  totalNumberOfSites: Scalars['Int']['output'];
};

export type StudyDesignerResult = {
  __typename?: 'StudyDesignerResult';
  enrollmentChartData?: Maybe<RndTimelineChartDataViewResult>;
  enrollmentSliderParams: RndStudyDesignerEnrollmentSliderParams;
  siteDistributionAvailableCountriesList?: Maybe<RndInsightsSiteDistributionAvailableCountriesResult>;
  siteDistributionTableData?: Maybe<RndStudyDesignerSiteDistributionTableDataView>;
};

export type StudyDesignerResultEnrollmentChartDataArgs = {
  input?: InputMaybe<RndStudyDesignerEnrollmentChartDataInput>;
};

export type StudyDesignerResultEnrollmentSliderParamsArgs = {
  input?: InputMaybe<RndStudyDesignerEnrollmentSliderParamsInput>;
};

export type StudyDesignerResultSiteDistributionAvailableCountriesListArgs = {
  input?: InputMaybe<RndInsightsStudyDesignAvailableCountryByIdInput>;
};

export type StudyDesignerResultSiteDistributionTableDataArgs = {
  input?: InputMaybe<RndStudyDesignerSiteDistributionDataInput>;
};

export type Subscriptions = {
  __typename?: 'Subscriptions';
  intercept: InterceptResult;
};

export type SubscriptionsInterceptArgs = {
  input: InterceptSubscriptionInput;
};

export type SummaryInfo = {
  __typename?: 'SummaryInfo';
  items?: Maybe<Array<SummaryInfoItem>>;
  lastUpdateDate?: Maybe<Scalars['String']['output']>;
};

export type SummaryInfoComponent = Component & {
  __typename?: 'SummaryInfoComponent';
  /** Component code */
  code: Scalars['String']['output'];
  componentType: ComponentType;
  filters?: Maybe<Array<FilterComponent>>;
  icon: ComponentIcon;
  id: Scalars['Uuid']['output'];
  title: Scalars['String']['output'];
  viewType: ViewType;
};

export type SummaryInfoDataView = IDataView & {
  __typename?: 'SummaryInfoDataView';
  availableUserSettings: Array<UserSetting>;
  /** Type of the data view */
  dataViewType: DataViewType;
  /** Data view identifier */
  id: Scalars['Uuid']['output'];
  /** MetaData */
  metadata?: Maybe<Array<MetaData>>;
  /** The timeline axis */
  summaryInfo: SummaryInfo;
};

export type SummaryInfoItem = {
  __typename?: 'SummaryInfoItem';
  content: TableCellContent;
  header: TableCellContent;
};

export type SupplyHomeCardResult = {
  __typename?: 'SupplyHomeCardResult';
  /** Card Type */
  cardType: CardType;
  id: Scalars['String']['output'];
  /** Link result */
  linkResult?: Maybe<Link>;
  /** Card title */
  title: Scalars['String']['output'];
  /** Card trend */
  trend: TrendArrowResult;
};

export type SupplyHomeCardsComponent = Component & {
  __typename?: 'SupplyHomeCardsComponent';
  /** Component code */
  code: Scalars['String']['output'];
  componentType: ComponentType;
  filters?: Maybe<Array<FilterComponent>>;
  icon: ComponentIcon;
  id: Scalars['Uuid']['output'];
  title: Scalars['String']['output'];
  viewType: ViewType;
};

export type SupplyHomeCardsDataView = IDataView & {
  __typename?: 'SupplyHomeCardsDataView';
  /** Annotation */
  annotation?: Maybe<Scalars['String']['output']>;
  availableUserSettings: Array<UserSetting>;
  /** Cards */
  cards?: Maybe<Array<Maybe<SupplyHomeCardResult>>>;
  /** Type of the data view */
  dataViewType: DataViewType;
  /** Data view identifier */
  id: Scalars['Uuid']['output'];
  metadata?: Maybe<Array<MetaData>>;
  /** Title */
  title?: Maybe<Scalars['String']['output']>;
};

export type SupplyHomeDial = {
  __typename?: 'SupplyHomeDial';
  /** Toggle text line 1 */
  absDsp: Scalars['String']['output'];
  /** Float value for progress (how much circle is filled */
  progress: Scalars['Float']['output'];
  /** Category name */
  scoreCategory: Scalars['String']['output'];
  /** Category identifier */
  scoreCategoryId: Scalars['Int']['output'];
  /** Sentiment of circle which is translated to the color on frontend side */
  sentiment: Sentiment;
  /** Float value of target (white dot inside circle) */
  target?: Maybe<Scalars['Float']['output']>;
  /** Toggle text line 2 */
  targetMtdDltDsp: ValueTextResult;
};

export type SupplyHomeDialsComponent = Component & {
  __typename?: 'SupplyHomeDialsComponent';
  /** Component code */
  code: Scalars['String']['output'];
  componentType: ComponentType;
  filters?: Maybe<Array<FilterComponent>>;
  icon: ComponentIcon;
  id: Scalars['Uuid']['output'];
  title: Scalars['String']['output'];
  viewType: ViewType;
};

export type SupplyHomeDialsDataView = IDataView & {
  __typename?: 'SupplyHomeDialsDataView';
  availableUserSettings: Array<UserSetting>;
  /** Type of the data view */
  dataViewType: DataViewType;
  /** Dials */
  dials: Array<Maybe<SupplyHomeDial>>;
  id: Scalars['Uuid']['output'];
  metadata?: Maybe<Array<MetaData>>;
  title: Scalars['String']['output'];
};

export type SupplyLowInventoryRiskRootCausesComponent = Component & {
  __typename?: 'SupplyLowInventoryRiskRootCausesComponent';
  /** Component code */
  code: Scalars['String']['output'];
  componentType: ComponentType;
  filters: Array<FilterComponent>;
  icon: ComponentIcon;
  id: Scalars['Uuid']['output'];
  title: Scalars['String']['output'];
  viewType: ViewType;
};

export type SupplyLowInventoryRiskRootCausesContent = {
  __typename?: 'SupplyLowInventoryRiskRootCausesContent';
  /** Description */
  description: Scalars['String']['output'];
  items?: Maybe<Array<SupplyLowInventoryRiskRootCausesItem>>;
  /** SubTitle */
  subTitle: Scalars['String']['output'];
  /** Title */
  title: Scalars['String']['output'];
};

export type SupplyLowInventoryRiskRootCausesDataView = IDataView & {
  __typename?: 'SupplyLowInventoryRiskRootCausesDataView';
  availableUserSettings: Array<UserSetting>;
  /** Content */
  content: SupplyLowInventoryRiskRootCausesContent;
  /** Type of the data view */
  dataViewType: DataViewType;
  /** Header */
  header: SupplyLowInventoryRiskRootCausesHeader;
  /** Data view identifier */
  id: Scalars['Uuid']['output'];
  metadata?: Maybe<Array<MetaData>>;
  title: Scalars['String']['output'];
};

export type SupplyLowInventoryRiskRootCausesHeader = {
  __typename?: 'SupplyLowInventoryRiskRootCausesHeader';
  /** Description */
  description: Scalars['String']['output'];
  /** MicroChart */
  microChart: MicroChartResult;
  /** RiskLabel */
  riskLabel: Scalars['String']['output'];
  /** RiskValue */
  riskValue: BadgeResult;
  /** Title */
  title: Scalars['String']['output'];
};

export type SupplyLowInventoryRiskRootCausesItem = {
  __typename?: 'SupplyLowInventoryRiskRootCausesItem';
  causes?: Maybe<Array<Scalars['String']['output']>>;
  code: Scalars['String']['output'];
  sentiment: Sentiment;
  title: Scalars['String']['output'];
};

export type SupplyModuleResult = {
  __typename?: 'SupplyModuleResult';
  pulse: SupplyPulseResult;
};

export type SupplyPulseChartDataPoint = {
  __typename?: 'SupplyPulseChartDataPoint';
  isHighlighted: Scalars['Boolean']['output'];
  month: Scalars['String']['output'];
  value: Scalars['Decimal']['output'];
  year: Scalars['Int']['output'];
};

export type SupplyPulseChartDataView = {
  __typename?: 'SupplyPulseChartDataView';
  series: Array<SupplyPulseChartSeries>;
  xAxisLabels: Array<Scalars['String']['output']>;
  yearSelector: SupplyPulseChartYearSelector;
};

export type SupplyPulseChartDataViewInput = {
  additionalFiltersInput?: InputMaybe<Array<UserAdditionalFiltersInput>>;
  categoryId?: InputMaybe<Scalars['Int']['input']>;
  componentCode?: InputMaybe<Scalars['String']['input']>;
  dimensionCode?: InputMaybe<Scalars['String']['input']>;
  dimensionId?: InputMaybe<Scalars['Long']['input']>;
  drillId?: InputMaybe<Scalars['Int']['input']>;
  drillIds?: InputMaybe<Array<Scalars['Long']['input']>>;
  filters?: InputMaybe<Array<FilterInput>>;
  id: Scalars['Uuid']['input'];
  moduleId: Scalars['Uuid']['input'];
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  previousPriorityId?: InputMaybe<Scalars['Int']['input']>;
  priorityId?: InputMaybe<Scalars['Int']['input']>;
  rowsPerPage?: InputMaybe<Scalars['Int']['input']>;
  scoreDriverCode?: InputMaybe<Scalars['String']['input']>;
  selectedYear?: InputMaybe<Scalars['Int']['input']>;
  sortColumn?: InputMaybe<Scalars['String']['input']>;
  sortType?: InputMaybe<Scalars['String']['input']>;
  timePeriodId?: InputMaybe<Scalars['Int']['input']>;
  userSettings?: InputMaybe<Array<UserSettingInput>>;
};

export type SupplyPulseChartSeries = {
  __typename?: 'SupplyPulseChartSeries';
  data: Array<SupplyPulseChartDataPoint>;
  label: Scalars['String']['output'];
  sentiment?: Maybe<Sentiment>;
};

export type SupplyPulseChartYearSelector = {
  __typename?: 'SupplyPulseChartYearSelector';
  selectedIndex: Scalars['Int']['output'];
  values: Array<Scalars['Int']['output']>;
};

export type SupplyPulseDataViewInput = {
  additionalFiltersInput?: InputMaybe<Array<UserAdditionalFiltersInput>>;
  categoryId?: InputMaybe<Scalars['Int']['input']>;
  componentCode?: InputMaybe<Scalars['String']['input']>;
  dimensionCode?: InputMaybe<Scalars['String']['input']>;
  dimensionId?: InputMaybe<Scalars['Long']['input']>;
  drillId?: InputMaybe<Scalars['Int']['input']>;
  drillIds?: InputMaybe<Array<Scalars['Long']['input']>>;
  filters?: InputMaybe<Array<FilterInput>>;
  id: Scalars['Uuid']['input'];
  moduleId: Scalars['Uuid']['input'];
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  previousPriorityId?: InputMaybe<Scalars['Int']['input']>;
  priorityId?: InputMaybe<Scalars['Int']['input']>;
  rowsPerPage?: InputMaybe<Scalars['Int']['input']>;
  scoreDriverCode?: InputMaybe<Scalars['String']['input']>;
  sortColumn?: InputMaybe<Scalars['String']['input']>;
  sortType?: InputMaybe<Scalars['String']['input']>;
  timePeriodId?: InputMaybe<Scalars['Int']['input']>;
  userSettings?: InputMaybe<Array<UserSettingInput>>;
};

export type SupplyPulseDoubleGauge = {
  __typename?: 'SupplyPulseDoubleGauge';
  mainLabel: Scalars['String']['output'];
  mainSentiment: Sentiment;
  mainValue: Scalars['String']['output'];
  referenceLabel: Scalars['String']['output'];
  referenceValue: Scalars['String']['output'];
};

export type SupplyPulseDriverKpi = {
  __typename?: 'SupplyPulseDriverKpi';
  isPobos: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  trendArrow: TrendArrowResult;
  value: ValueTextResult;
  variance: Scalars['String']['output'];
};

export type SupplyPulseDriverKpisDataView = IDataView & {
  __typename?: 'SupplyPulseDriverKpisDataView';
  availableUserSettings: Array<UserSetting>;
  dataViewType: DataViewType;
  id: Scalars['Uuid']['output'];
  keyDriversTitle: Scalars['String']['output'];
  kpis: Array<SupplyPulseDriverKpi>;
  kpisVarianceColumnLabel: Scalars['String']['output'];
  metadata?: Maybe<Array<MetaData>>;
  priorityLabel: Scalars['String']['output'];
  scoreDriverCategory: Scalars['String']['output'];
  scoreDriverTrendArrow: TrendArrowResult;
  scoreDriverValue: ValueTextResult;
};

export type SupplyPulseDriverKpisInput = {
  additionalFiltersInput?: InputMaybe<Array<UserAdditionalFiltersInput>>;
  categoryId?: InputMaybe<Scalars['Int']['input']>;
  componentCode?: InputMaybe<Scalars['String']['input']>;
  dimensionCode?: InputMaybe<Scalars['String']['input']>;
  dimensionId?: InputMaybe<Scalars['Long']['input']>;
  drillId?: InputMaybe<Scalars['Int']['input']>;
  drillIds?: InputMaybe<Array<Scalars['Long']['input']>>;
  filters?: InputMaybe<Array<FilterInput>>;
  id: Scalars['Uuid']['input'];
  moduleId: Scalars['Uuid']['input'];
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  previousPriorityId?: InputMaybe<Scalars['Int']['input']>;
  priorityId?: InputMaybe<Scalars['Int']['input']>;
  rowsPerPage?: InputMaybe<Scalars['Int']['input']>;
  scoreDriverCode: Scalars['String']['input'];
  sortColumn?: InputMaybe<Scalars['String']['input']>;
  sortType?: InputMaybe<Scalars['String']['input']>;
  timePeriodId?: InputMaybe<Scalars['Int']['input']>;
  userSettings?: InputMaybe<Array<UserSettingInput>>;
};

export type SupplyPulseHistoryHeaderDataView = {
  __typename?: 'SupplyPulseHistoryHeaderDataView';
  priorityName: Scalars['String']['output'];
  priorityScore: ValueTextResult;
  title: Scalars['String']['output'];
};

export type SupplyPulseHistoryResult = {
  __typename?: 'SupplyPulseHistoryResult';
  chart: SupplyPulseChartDataView;
  driverKpis: SupplyPulseDriverKpisDataView;
  header: SupplyPulseHistoryHeaderDataView;
  keyDrivers: SupplyPulseKeyDriversDataView;
};

export type SupplyPulseHistoryResultChartArgs = {
  input: SupplyPulseChartDataViewInput;
};

export type SupplyPulseHistoryResultDriverKpisArgs = {
  input: SupplyPulseDriverKpisInput;
};

export type SupplyPulseHistoryResultHeaderArgs = {
  input: SupplyPulseDataViewInput;
};

export type SupplyPulseHistoryResultKeyDriversArgs = {
  input: SupplyPulseDataViewInput;
};

export type SupplyPulseKeyDriver = {
  __typename?: 'SupplyPulseKeyDriver';
  code: Scalars['String']['output'];
  hasDetails: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  trendArrow: TrendArrowResult;
  value: ValueTextResult;
};

export type SupplyPulseKeyDriversDataView = {
  __typename?: 'SupplyPulseKeyDriversDataView';
  drivers: Array<SupplyPulseKeyDriver>;
  metadata?: Maybe<Array<MetaData>>;
  title: Scalars['String']['output'];
};

export type SupplyPulseLandingResult = IDataView & {
  __typename?: 'SupplyPulseLandingResult';
  availableUserSettings: Array<UserSetting>;
  dataViewType: DataViewType;
  gauge?: Maybe<SupplyPulseDoubleGauge>;
  id: Scalars['Uuid']['output'];
  leaderboardPosition?: Maybe<SupplyPulseLeaderboardPosition>;
  metadata: Array<MetaData>;
  recommendation?: Maybe<SupplyPulseRecommendation>;
};

export type SupplyPulseLeaderboardCompetitor = {
  __typename?: 'SupplyPulseLeaderboardCompetitor';
  name: Scalars['String']['output'];
  rank: ValueTextResult;
  score: ValueTextResult;
  scoreUom?: Maybe<Scalars['String']['output']>;
  selectedPriority: Scalars['Boolean']['output'];
  trendArrow?: Maybe<TrendArrowResult>;
};

export type SupplyPulseLeaderboardListComponent = Component & {
  __typename?: 'SupplyPulseLeaderboardListComponent';
  code: Scalars['String']['output'];
  componentType: ComponentType;
  filters: Array<FilterComponent>;
  icon: ComponentIcon;
  id: Scalars['Uuid']['output'];
  title: Scalars['String']['output'];
  viewType: ViewType;
};

export type SupplyPulseLeaderboardListDataView = IDataView & {
  __typename?: 'SupplyPulseLeaderboardListDataView';
  availableUserSettings: Array<UserSetting>;
  competitors: Array<SupplyPulseLeaderboardCompetitor>;
  dataViewType: DataViewType;
  id: Scalars['Uuid']['output'];
  metadata?: Maybe<Array<MetaData>>;
};

export type SupplyPulseLeaderboardPosition = {
  __typename?: 'SupplyPulseLeaderboardPosition';
  label: Scalars['String']['output'];
  trendArrow: TrendArrowResult;
  value: Scalars['String']['output'];
};

export type SupplyPulseLeaderboardResult = {
  __typename?: 'SupplyPulseLeaderboardResult';
  list: SupplyPulseLeaderboardListDataView;
};

export type SupplyPulseLeaderboardResultListArgs = {
  input: DataViewInput;
};

export type SupplyPulseRecommendation = {
  __typename?: 'SupplyPulseRecommendation';
  description: Scalars['String']['output'];
  items?: Maybe<Array<SupplyPulseRecommendation>>;
  title: Scalars['String']['output'];
};

export type SupplyPulseResult = {
  __typename?: 'SupplyPulseResult';
  history: SupplyPulseHistoryResult;
  landing: SupplyPulseLandingResult;
  leaderboard: SupplyPulseLeaderboardResult;
};

export type SupplyPulseResultLandingArgs = {
  input: SupplyPulseDataViewInput;
};

export type SupplyShortDatedRootCausesComponent = Component & {
  __typename?: 'SupplyShortDatedRootCausesComponent';
  /** Component code */
  code: Scalars['String']['output'];
  componentType: ComponentType;
  filters: Array<FilterComponent>;
  icon: ComponentIcon;
  id: Scalars['Uuid']['output'];
  title: Scalars['String']['output'];
  viewType: ViewType;
};

export type SupplyShortDatedRootCausesContent = {
  __typename?: 'SupplyShortDatedRootCausesContent';
  /** Description */
  description: Scalars['String']['output'];
  items?: Maybe<Array<SupplyShortDatedRootCausesItem>>;
  /** SubTitle */
  subTitle: Scalars['String']['output'];
  /** Title */
  title: Scalars['String']['output'];
};

export type SupplyShortDatedRootCausesDataView = IDataView & {
  __typename?: 'SupplyShortDatedRootCausesDataView';
  availableUserSettings: Array<UserSetting>;
  /** Content */
  content: SupplyShortDatedRootCausesContent;
  /** Type of the data view */
  dataViewType: DataViewType;
  /** Header */
  header: SupplyShortDatedRootCausesHeader;
  /** Data view identifier */
  id: Scalars['Uuid']['output'];
  metadata?: Maybe<Array<MetaData>>;
  title: Scalars['String']['output'];
};

export type SupplyShortDatedRootCausesHeader = {
  __typename?: 'SupplyShortDatedRootCausesHeader';
  /** Description */
  description: Scalars['String']['output'];
  /** MicroChart */
  microChart: MicroChartResult;
  /** RiskLabel */
  riskLabel: Scalars['String']['output'];
  /** RiskValue */
  riskValue: BadgeResult;
  /** Title */
  title: Scalars['String']['output'];
};

export type SupplyShortDatedRootCausesItem = {
  __typename?: 'SupplyShortDatedRootCausesItem';
  causes?: Maybe<Array<Scalars['String']['output']>>;
  code: Scalars['String']['output'];
  sentiment: Sentiment;
  title: Scalars['String']['output'];
};

export type Tab = {
  __typename?: 'Tab';
  name?: Maybe<Scalars['String']['output']>;
  pageId?: Maybe<Scalars['Uuid']['output']>;
  path?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type TableCellContent =
  | BadgeResult
  | ColoredVarianceResult
  | ColoredVarianceWithDeltaResult
  | ComparatorTrendResult
  | CriteriaBadgeResult
  | DrillDownResult
  | GraphicElementResult
  | IconLinkResult
  | IconsLinkResult
  | KpiStatus
  | KpiStatusOverview
  | LocationMarkerResult
  | MicroChartResult
  | ProgressBarResult
  | RegionCountryResult
  | RiskIndicatorResult
  | StatusTextResult
  | StudyDesignLinkResult
  | TextExtendedResult
  | TextLinkResult
  | TextResult
  | Timeline
  | TimelineDataPoint
  | TimelinePhases
  | ValueWithVarianceResult
  | VarianceResult;

export type TableCellResult = {
  __typename?: 'TableCellResult';
  /** Cell component */
  cellContent?: Maybe<TableCellContent>;
  /** Column data key where cell belongs */
  columnDataKey: Scalars['String']['output'];
};

export type TableColumnOptionsResult = {
  __typename?: 'TableColumnOptionsResult';
  /** Alignment setting for column */
  alignment?: Maybe<Alignment>;
  /** Flag for displaying AI stamp */
  isAiDriven?: Maybe<Scalars['Boolean']['output']>;
  /** Is default select */
  selectDefault?: Maybe<Scalars['Boolean']['output']>;
  selectDisplayDefault: Scalars['Boolean']['output'];
  /** Select display group id */
  selectDisplayGroupId: Scalars['Int']['output'];
  /** Select group id */
  selectGroupId: Scalars['Int']['output'];
  /** Is default toggle */
  toggleDefault?: Maybe<Scalars['Boolean']['output']>;
  /** Toggle group id */
  toggleGroup: Scalars['Int']['output'];
  /** Width setting for column */
  width?: Maybe<Scalars['Int']['output']>;
};

export type TableColumnResult = {
  __typename?: 'TableColumnResult';
  /** Column identifier */
  columnId: Scalars['Int']['output'];
  /** DataKey which connects column with data */
  dataKey: Scalars['String']['output'];
  /** Main column data key, used for grouping */
  mainColumnDataKey?: Maybe<Scalars['String']['output']>;
  /** Column options */
  options?: Maybe<TableColumnOptionsResult>;
  /** Column title */
  title?: Maybe<Scalars['String']['output']>;
  /** Column title display type */
  titleDisplayType: TitleDisplayType;
};

export type TableComponent = Component & {
  __typename?: 'TableComponent';
  /** Component code */
  code: Scalars['String']['output'];
  componentType: ComponentType;
  /** Drill ids */
  drillIds?: Maybe<Array<Scalars['Long']['output']>>;
  filters?: Maybe<Array<FilterComponent>>;
  icon: ComponentIcon;
  id: Scalars['Uuid']['output'];
  periodType: PeriodType;
  /** Title */
  title?: Maybe<Scalars['String']['output']>;
  viewType: ViewType;
};

export type TableContent = {
  __typename?: 'TableContent';
  columns?: Maybe<Array<TableColumnResult>>;
  rows?: Maybe<Array<TableRowResult>>;
  title: Scalars['String']['output'];
};

export type TableDataView = IDataView & {
  __typename?: 'TableDataView';
  /** Annotations */
  annotation?: Maybe<Scalars['String']['output']>;
  availableUserSettings: Array<UserSetting>;
  /** Table columns */
  columns?: Maybe<Array<TableColumnResult>>;
  /** Type of the data view */
  dataViewType: DataViewType;
  /** Edit link */
  editLink?: Maybe<Link>;
  /** Dependent filters */
  filters?: Maybe<Array<FilterValue>>;
  /** Data view identifier */
  id: Scalars['Uuid']['output'];
  /** Formatted date of last data update */
  lastUpdateDate?: Maybe<Scalars['String']['output']>;
  /** MetaData */
  metadata?: Maybe<Array<MetaData>>;
  /** Table options */
  options?: Maybe<TableViewOptionsResult>;
  /** Table rows */
  rows?: Maybe<Array<Maybe<TableRowResult>>>;
  /** Title */
  title?: Maybe<Scalars['String']['output']>;
};

export type TableRowResult = {
  __typename?: 'TableRowResult';
  /** Cells */
  cells?: Maybe<Array<TableCellResult>>;
  /** Children rows */
  children?: Maybe<Array<TableRowResult>>;
  /**
   * Deprecated field which should not be used
   * @deprecated To be removed after P&L implementation
   */
  groupChildren?: Maybe<Scalars['Boolean']['output']>;
  /**
   * Deprecated field which should not be used
   * @deprecated To be removed after P&L implementation
   */
  groupFirstChild?: Maybe<Scalars['Boolean']['output']>;
  /** Row identifier */
  rowId: Scalars['Long']['output'];
  /** Type of the row */
  rowType: RowType;
};

export type TableSwitcherComponent = Component & {
  __typename?: 'TableSwitcherComponent';
  code: Scalars['String']['output'];
  componentType: ComponentType;
  icon: ComponentIcon;
  id: Scalars['Uuid']['output'];
  tables?: Maybe<Array<TableComponent>>;
  title: Scalars['String']['output'];
  viewType: ViewType;
};

export type TableViewOptionsResult = {
  __typename?: 'TableViewOptionsResult';
  showMap?: Maybe<Scalars['Boolean']['output']>;
};

export enum TagType {
  Eyes = 'EYES',
  Rocket = 'ROCKET',
  Urgent = 'URGENT',
  Warning = 'WARNING',
}

export type TakeAgentDecisionInput = {
  agentId: Scalars['Uuid']['input'];
  moduleCode: Scalars['String']['input'];
  screenIds: Array<Scalars['Int']['input']>;
  subtitle: Scalars['String']['input'];
  titleLabel: Scalars['String']['input'];
};

export type TenantDefaultsInput = {
  availableNumberScalingSettings: Array<NumberScaling>;
  availablePeriodTypeSettings: Array<PeriodType>;
  decimalPrecision: Scalars['Int']['input'];
  exchangeRate: Scalars['Int']['input'];
  numberScaling: NumberScaling;
  periodType: PeriodType;
};

export type TenantDefaultsPriorityInput = {
  id: Scalars['Int']['input'];
};

export type TenantDefaultsPriorityResult = {
  __typename?: 'TenantDefaultsPriorityResult';
  id: Scalars['Long']['output'];
  moduleCode: ModuleCode;
  tenantId: Scalars['String']['output'];
};

export type TenantDefaultsResult = {
  __typename?: 'TenantDefaultsResult';
  moduleDefaults: Array<ModuleDefaultsResult>;
  tenantId: Scalars['String']['output'];
};

export type TenantModuleDefaultsInput = {
  defaultPriorities: Array<TenantDefaultsPriorityInput>;
  valueSettings: TenantDefaultsInput;
};

export type TextExtendedResult = {
  __typename?: 'TextExtendedResult';
  /** BulletPoint settings */
  bulletPoint?: Maybe<GraphicElementResult>;
  /** Text content */
  text?: Maybe<TextResult>;
};

export type TextLinkResult = {
  __typename?: 'TextLinkResult';
  /** Label of content */
  label?: Maybe<Scalars['String']['output']>;
  /** Link direction */
  linkDirection?: Maybe<LinkDirection>;
  /** Link configuration */
  linkResult?: Maybe<Link>;
  /** SubLabels content */
  subLabels?: Maybe<Array<Scalars['String']['output']>>;
  /** Text content */
  value?: Maybe<Scalars['String']['output']>;
};

export type TextResult = {
  __typename?: 'TextResult';
  /** A color for a text value */
  color?: Maybe<ColorUnion>;
  /** Flag for highlighting text value */
  isHighlighted?: Maybe<Scalars['Boolean']['output']>;
  /** SubLabels content */
  subLabels?: Maybe<Array<Scalars['String']['output']>>;
  /** Text content */
  value?: Maybe<Scalars['String']['output']>;
};

export enum TextSize {
  Default = 'DEFAULT',
  Large = 'LARGE',
  Medium = 'MEDIUM',
  Small = 'SMALL',
  Xlarge = 'XLARGE',
  Xsmall = 'XSMALL',
}

export type ThemeColor = {
  __typename?: 'ThemeColor';
  color?: Maybe<Color>;
};

export type Timeline = {
  __typename?: 'Timeline';
  /** The timeline axis */
  axis: TimelineAxis;
  /** The timeline intervals */
  intervals?: Maybe<Array<TimelineInterval>>;
  /** The timeline series */
  series?: Maybe<Array<TimelineSeries>>;
};

export type TimelineAxis = {
  __typename?: 'TimelineAxis';
  /** Axis categories */
  categories?: Maybe<Array<Scalars['String']['output']>>;
  /** The maximum x value of the axis */
  maxX: Scalars['Long']['output'];
  /** The minimum x value of the axis */
  minX: Scalars['Long']['output'];
};

export type TimelineComponent = Component & {
  __typename?: 'TimelineComponent';
  /** Component code */
  code: Scalars['String']['output'];
  componentType: ComponentType;
  filters?: Maybe<Array<FilterComponent>>;
  icon: ComponentIcon;
  id: Scalars['Uuid']['output'];
  title: Scalars['String']['output'];
  viewType: ViewType;
};

export type TimelineDataPoint = {
  __typename?: 'TimelineDataPoint';
  /** Extra data for the data point, i.e. tooltip data, will be specified here */
  extraData?: Maybe<Scalars['JObject']['output']>;
  formattedX: Scalars['String']['output'];
  label: Scalars['String']['output'];
  /** Optional override of the series marker for an individual data point */
  marker?: Maybe<TimelineMarker>;
  tooltip?: Maybe<TableContent>;
  x: Scalars['Long']['output'];
};

export type TimelineInterval = {
  __typename?: 'TimelineInterval';
  color: ThemeColor;
  name?: Maybe<Scalars['String']['output']>;
  x1: Scalars['Long']['output'];
  x2: Scalars['Long']['output'];
};

export type TimelineMarker = {
  __typename?: 'TimelineMarker';
  color: ThemeColor;
  isFilled: Scalars['Boolean']['output'];
  symbol: TimelineMarkerSymbol;
};

export enum TimelineMarkerSymbol {
  Circle = 'CIRCLE',
  Rhomb = 'RHOMB',
  Square = 'SQUARE',
  Triangle = 'TRIANGLE',
}

export type TimelinePhases = {
  __typename?: 'TimelinePhases';
  /** The timeline axis */
  axis: TimelineAxis;
  /** The timeline intervals */
  intervals?: Maybe<Array<TimelineInterval>>;
};

export type TimelineSeries = {
  __typename?: 'TimelineSeries';
  data?: Maybe<Array<TimelineDataPoint>>;
  /** The ID of the series, for example `enrollment` */
  id: Scalars['String']['output'];
  marker: TimelineMarker;
  /** The name of the series shown in the legend, tooltip etc., for example `Enrollment` */
  name: Scalars['String']['output'];
  sortOrder?: Maybe<Scalars['Int']['output']>;
};

export enum TitleDisplayType {
  Above = 'ABOVE',
  Below = 'BELOW',
  Inline = 'INLINE',
}

export enum TooltipCustomType {
  Batch = 'BATCH',
  Default = 'DEFAULT',
}

export enum TooltipRangeFormat {
  Inline = 'INLINE',
  None = 'NONE',
  Split = 'SPLIT',
}

export type TotalScoreAndNpvRow = {
  __typename?: 'TotalScoreAndNpvRow';
  targetScoreDeltaSentiment: Sentiment;
  targetScoreDeltaValue: Scalars['String']['output'];
  targetScoreLabel: Scalars['String']['output'];
  targetScoreSentiment: Sentiment;
  targetScoreValue: Scalars['String']['output'];
};

export type TreeFilterOptionResult = {
  __typename?: 'TreeFilterOptionResult';
  /** Additional filter option labels */
  additionalLabels?: Maybe<Array<Scalars['String']['output']>>;
  /** Sub options */
  children?: Maybe<Array<TreeFilterOptionResult>>;
  filterId?: Maybe<Scalars['Uuid']['output']>;
  /** Option unique ID. */
  id: Scalars['ID']['output'];
  /** Flag to indicate whether option is default for filter */
  isDefault?: Maybe<Scalars['Boolean']['output']>;
  /** Option label. */
  label: Scalars['String']['output'];
  /** Option group code. */
  optionGroupCode?: Maybe<Scalars['String']['output']>;
  /** Filter Option Sentiment */
  sentiment?: Maybe<Sentiment>;
  /** Option value */
  value?: Maybe<Scalars['Long']['output']>;
};

export type TreeSelectFilter = Filter & {
  __typename?: 'TreeSelectFilter';
  /** Additional Labels */
  additionalLabels: Array<Maybe<Scalars['String']['output']>>;
  /** Filter ApiKey */
  filterCode?: Maybe<Scalars['String']['output']>;
  filterType: FilterType;
  id: Scalars['Uuid']['output'];
  label?: Maybe<Scalars['String']['output']>;
  /** Hierarchical structure of filters */
  options?: Maybe<Array<TreeFilterOptionResult>>;
  parentId?: Maybe<Scalars['Uuid']['output']>;
};

export type TrendArrowResult = {
  __typename?: 'TrendArrowResult';
  /** Direction of the arrow up/down/neutral */
  direction: Direction;
  /** Sentiment of the arrow which is translated on frontend side to specific color */
  sentiment: Sentiment;
};

export type UpdateRecommendationItemPinnedInput = {
  id: Scalars['Int']['input'];
  isPinned: Scalars['Boolean']['input'];
};

export type UpdateResponse = {
  __typename?: 'UpdateResponse';
  isSuccess: Scalars['Boolean']['output'];
  message: Scalars['String']['output'];
};

export type UpdateSeenAllScreensInput = {
  hasSeenAllScreens: Scalars['Boolean']['input'];
  realId: Scalars['Uuid']['input'];
};

export type UpdateStudyDesignCommentInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  studyId: Scalars['Int']['input'];
};

export type UpdateStudyDesignInput = {
  globalRecruitmentRate: Scalars['Float']['input'];
  id: Scalars['Int']['input'];
  numberOfPatients: Scalars['Int']['input'];
  numberOfSites: Scalars['Int']['input'];
};

export type UserAdditionalFilterInput = {
  filterCode: Scalars['String']['input'];
  filterLabel: Scalars['String']['input'];
  filterType: Scalars['String']['input'];
  filterValue: Scalars['String']['input'];
};

export type UserAdditionalFilters = {
  __typename?: 'UserAdditionalFilters';
  /** Filter code */
  filterCode: Scalars['String']['output'];
  /** Filter label */
  filterLabel: Scalars['String']['output'];
  /** Filter type */
  filterType: Scalars['String']['output'];
  /** Filter value */
  filterValue: Scalars['String']['output'];
  /** Is filter default */
  isDefault: Scalars['Boolean']['output'];
  /** Is filter valid */
  isValid: Scalars['Boolean']['output'];
};

export type UserAdditionalFiltersInput = {
  filterCode?: InputMaybe<Scalars['String']['input']>;
  filterLabel?: InputMaybe<Scalars['String']['input']>;
  filterType?: InputMaybe<Scalars['String']['input']>;
  filterValue?: InputMaybe<Scalars['String']['input']>;
};

export type UserAnalytics = {
  __typename?: 'UserAnalytics';
  /** Filters */
  filterOptions?: Maybe<Array<Maybe<FilterOptionResult>>>;
  /** Metric header */
  headerMetrics?: Maybe<Array<Maybe<Metric>>>;
  /** Metric header */
  metricLists?: Maybe<Array<Maybe<MetricList>>>;
  /** Time frame user analytics data */
  timeFrame?: Maybe<Scalars['String']['output']>;
};

export type UserAnalyticsComponent = Component & {
  __typename?: 'UserAnalyticsComponent';
  /** Component code */
  code: Scalars['String']['output'];
  componentType: ComponentType;
  filters?: Maybe<Array<FilterComponent>>;
  icon: ComponentIcon;
  id: Scalars['Uuid']['output'];
  title: Scalars['String']['output'];
  viewType: ViewType;
};

export type UserAnalyticsInput = {
  filters?: InputMaybe<Array<InputMaybe<SelectFilterInput>>>;
  moduleId: Scalars['Uuid']['input'];
};

export type UserInputSelect = {
  __typename?: 'UserInputSelect';
  label: Scalars['String']['output'];
  legendItems: Array<LegendItem>;
  selectOptions: Array<SelectOption>;
  userInputId: Scalars['Int']['output'];
};

export type UserPrioritiesComponent = Component & {
  __typename?: 'UserPrioritiesComponent';
  /** Component code */
  code: Scalars['String']['output'];
  componentType: ComponentType;
  icon: ComponentIcon;
  id: Scalars['Uuid']['output'];
  title: Scalars['String']['output'];
  viewType: ViewType;
};

export type UserProfileResult = {
  __typename?: 'UserProfileResult';
  badge?: Maybe<Badge>;
};

export type UserResult = {
  __typename?: 'UserResult';
  displayName: Scalars['String']['output'];
  email: Scalars['String']['output'];
  externalUserId?: Maybe<Scalars['String']['output']>;
  userId: Scalars['Uuid']['output'];
  userRoles?: Maybe<Array<Scalars['String']['output']>>;
};

export type UserSetting = {
  __typename?: 'UserSetting';
  defaultValue?: Maybe<Scalars['Long']['output']>;
  key: Scalars['String']['output'];
  label: Scalars['String']['output'];
  options: Array<SettingOption>;
  userValue?: Maybe<Scalars['Long']['output']>;
};

export type UserSettingInput = {
  key: Scalars['String']['input'];
  value: Scalars['Int']['input'];
};

export type ValueOverviewFilters = {
  __typename?: 'ValueOverviewFilters';
  /** Kpi filter */
  kpiFilter: SelectFilter;
  /** Period filter */
  periodFilter: SelectFilter;
};

export type ValueOverviewResult = {
  __typename?: 'ValueOverviewResult';
  barChartData?: Maybe<RndTrialsValueOverviewChartDataView>;
  chartData?: Maybe<RndTrialsValueOverviewChartDataView>;
  chartHeaderData?: Maybe<RndTrialsValueOverviewChartHeaderDataView>;
  /** Dependent filters */
  valueOverviewfilters?: Maybe<ValueOverviewFilters>;
};

export type ValueOverviewResultBarChartDataArgs = {
  input: RndTrialsValueOverviewChartDataInput;
};

export type ValueOverviewResultChartDataArgs = {
  input: RndTrialsValueOverviewChartDataInput;
};

export type ValueOverviewResultChartHeaderDataArgs = {
  input: RndTrialsValueOverviewChartHeaderDataInput;
};

export type ValueOverviewResultValueOverviewfiltersArgs = {
  input: RndTrialsValueOverviewFiltersDataInput;
};

export type ValueTextResult = {
  __typename?: 'ValueTextResult';
  /** Label for value */
  label?: Maybe<Scalars['String']['output']>;
  /** Raw value which was used for value field */
  rawValue?: Maybe<Scalars['Float']['output']>;
  /** Sentiment of the value which is translated on FE to specific color */
  sentiment?: Maybe<Sentiment>;
  /** Type of the text content which represents formatted value */
  type?: Maybe<ValueTextType>;
  /** Text content which represents formatted value */
  value?: Maybe<Scalars['String']['output']>;
};

export enum ValueTextType {
  Currency = 'CURRENCY',
  Currencyeuro = 'CURRENCYEURO',
  Number = 'NUMBER',
  Numbernodecimal = 'NUMBERNODECIMAL',
  Numberwithsuffixnodecimal = 'NUMBERWITHSUFFIXNODECIMAL',
  Numberwithsuffixonedecimal = 'NUMBERWITHSUFFIXONEDECIMAL',
  Percentage = 'PERCENTAGE',
  Percentagenodecimal = 'PERCENTAGENODECIMAL',
  Percentageonedecimal = 'PERCENTAGEONEDECIMAL',
  Points = 'POINTS',
  Text = 'TEXT',
  Unknown = 'UNKNOWN',
  Variancepercentagenodecimal = 'VARIANCEPERCENTAGENODECIMAL',
  Variancepercentageonedecimal = 'VARIANCEPERCENTAGEONEDECIMAL',
}

export type ValueWithVarianceResult = {
  __typename?: 'ValueWithVarianceResult';
  /** Absolute Sentiment for value */
  absoluteSentiment?: Maybe<Sentiment>;
  /** Absolute Variance Text content */
  absoluteVariance?: Maybe<Scalars['String']['output']>;
  /** Percentage Sentiment for value */
  percentageSentiment?: Maybe<Sentiment>;
  /** Percentage Variance Text content */
  percentageVariance?: Maybe<Scalars['String']['output']>;
  /** Text content */
  value?: Maybe<Scalars['String']['output']>;
};

export type VarianceResult = {
  __typename?: 'VarianceResult';
  /** Actual Text content */
  actual?: Maybe<Scalars['String']['output']>;
  /** Label content */
  label?: Maybe<Scalars['String']['output']>;
  /** Sentiment for value */
  sentiment?: Maybe<Sentiment>;
  /** SubLabels content */
  subLabels?: Maybe<Array<Scalars['String']['output']>>;
  /** Target Text content */
  target?: Maybe<Scalars['String']['output']>;
  /**
   * Text content
   * @deprecated Will be removed in release/1.9
   */
  value?: Maybe<Scalars['String']['output']>;
};

export type VideoComponent = Component & {
  __typename?: 'VideoComponent';
  /** Component code */
  code: Scalars['String']['output'];
  componentType: ComponentType;
  icon: ComponentIcon;
  id: Scalars['Uuid']['output'];
  title: Scalars['String']['output'];
  /** Content of the markdown */
  videoContent: Media;
  viewType: ViewType;
};

export type VideoContent = Media & {
  __typename?: 'VideoContent';
  id: Scalars['Uuid']['output'];
  lqipUrl?: Maybe<Scalars['String']['output']>;
  /** Video source url. */
  sourceUrl: Scalars['String']['output'];
  thumbnailUrl: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export enum ViewType {
  Default = 'DEFAULT',
  Landscape = 'LANDSCAPE',
  Portrait = 'PORTRAIT',
}

export type YearSelector = {
  __typename?: 'YearSelector';
  selectedIndex: Scalars['Int']['output'];
  values: Array<Scalars['Int']['output']>;
};

export type YourBrainItem = {
  __typename?: 'YourBrainItem';
  /** Your brain content type Story or Real */
  contentType: InboxContentType;
  /** Date and time when the item was added to brain. */
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** Indicates if the your brain item is expired. */
  isExpired: Scalars['Boolean']['output'];
  /** Your brain link to a specific page in the app. */
  link: Scalars['String']['output'];
  /** Module code of the your brain item. */
  moduleCode: ModuleCode;
  /** Your brain item resource id - story or real. */
  resourceId: Scalars['String']['output'];
  /** Your brain item title. */
  title: Scalars['String']['output'];
};

export type YourBrainResult = {
  __typename?: 'YourBrainResult';
  yourBrainItemResults?: Maybe<Array<YourBrainItem>>;
};

export const isBadgeResult = (value?: any): value is BadgeResult =>
  value?.__typename === 'BadgeResult';

export const isButtonComponent = (value?: any): value is ButtonComponent =>
  value?.__typename === 'ButtonComponent';

export const isButtonDataView = (value?: any): value is ButtonDataView =>
  value?.__typename === 'ButtonDataView';

export const isCardsComponent = (value?: any): value is CardsComponent =>
  value?.__typename === 'CardsComponent';

export const isCardsDataView = (value?: any): value is CardsDataView =>
  value?.__typename === 'CardsDataView';

export const isCashFlowKpiComponent = (value?: any): value is CashFlowKpiComponent =>
  value?.__typename === 'CashFlowKpiComponent';

export const isCashFlowKpiDataViewResult = (value?: any): value is CashFlowKpiDataViewResult =>
  value?.__typename === 'CashFlowKpiDataViewResult';

export const isChartAxisCategoryViewResult = (value?: any): value is ChartAxisCategoryViewResult =>
  value?.__typename === 'ChartAxisCategoryViewResult';

export const isChartAxisPercentViewResult = (value?: any): value is ChartAxisPercentViewResult =>
  value?.__typename === 'ChartAxisPercentViewResult';

export const isChartAxisValueViewResult = (value?: any): value is ChartAxisValueViewResult =>
  value?.__typename === 'ChartAxisValueViewResult';

export const isChartComponent = (value?: any): value is ChartComponent =>
  value?.__typename === 'ChartComponent';

export const isChartDataViewResult = (value?: any): value is ChartDataViewResult =>
  value?.__typename === 'ChartDataViewResult';

export const isCmoPulseLeaderboardPositionDataView = (
  value?: any,
): value is CmoPulseLeaderboardPositionDataView =>
  value?.__typename === 'CmoPulseLeaderboardPositionDataView';

export const isCmoPulseRecommendedActionsDataView = (
  value?: any,
): value is CmoPulseRecommendedActionsDataView =>
  value?.__typename === 'CmoPulseRecommendedActionsDataView';

export const isColorString = (value?: any): value is ColorString =>
  value?.__typename === 'ColorString';

export const isColoredVarianceResult = (value?: any): value is ColoredVarianceResult =>
  value?.__typename === 'ColoredVarianceResult';

export const isColoredVarianceWithDeltaResult = (
  value?: any,
): value is ColoredVarianceWithDeltaResult =>
  value?.__typename === 'ColoredVarianceWithDeltaResult';

export const isColumnLayoutComponent = (value?: any): value is ColumnLayoutComponent =>
  value?.__typename === 'ColumnLayoutComponent';

export const isComparatorTrendResult = (value?: any): value is ComparatorTrendResult =>
  value?.__typename === 'ComparatorTrendResult';

export const isComponentBase = (value?: any): value is ComponentBase =>
  value?.__typename === 'ComponentBase';

export const isCriteriaBadgeResult = (value?: any): value is CriteriaBadgeResult =>
  value?.__typename === 'CriteriaBadgeResult';

export const isDailyAgentActionsScreenResult = (
  value?: any,
): value is DailyAgentActionsScreenResult => value?.__typename === 'DailyAgentActionsScreenResult';

export const isDailyAgentFireworksScreenResult = (
  value?: any,
): value is DailyAgentFireworksScreenResult =>
  value?.__typename === 'DailyAgentFireworksScreenResult';

export const isDailyAgentProgressScreenResult = (
  value?: any,
): value is DailyAgentProgressScreenResult =>
  value?.__typename === 'DailyAgentProgressScreenResult';

export const isDemoHomeChartComponent = (value?: any): value is DemoHomeChartComponent =>
  value?.__typename === 'DemoHomeChartComponent';

export const isDemoHomeMicroChartComponent = (value?: any): value is DemoHomeMicroChartComponent =>
  value?.__typename === 'DemoHomeMicroChartComponent';

export const isDialsComponent = (value?: any): value is DialsComponent =>
  value?.__typename === 'DialsComponent';

export const isDialsDataView = (value?: any): value is DialsDataView =>
  value?.__typename === 'DialsDataView';

export const isDrillDownResult = (value?: any): value is DrillDownResult =>
  value?.__typename === 'DrillDownResult';

export const isEbiHomeCategoriesDialsComponent = (
  value?: any,
): value is EbiHomeCategoriesDialsComponent =>
  value?.__typename === 'EbiHomeCategoriesDialsComponent';

export const isEbiHomeCategoriesDialsDataView = (
  value?: any,
): value is EbiHomeCategoriesDialsDataView =>
  value?.__typename === 'EbiHomeCategoriesDialsDataView';

export const isFilterComponent = (value?: any): value is FilterComponent =>
  value?.__typename === 'FilterComponent';

export const isFiltersComponent = (value?: any): value is FiltersComponent =>
  value?.__typename === 'FiltersComponent';

export const isFinHomePageCardsComponent = (value?: any): value is FinHomePageCardsComponent =>
  value?.__typename === 'FinHomePageCardsComponent';

export const isFinHomePageCardsDataView = (value?: any): value is FinHomePageCardsDataView =>
  value?.__typename === 'FinHomePageCardsDataView';

export const isGlocalPulseLeaderboardPositionDataView = (
  value?: any,
): value is GlocalPulseLeaderboardPositionDataView =>
  value?.__typename === 'GlocalPulseLeaderboardPositionDataView';

export const isGlocalPulseRecommendedActionsDataView = (
  value?: any,
): value is GlocalPulseRecommendedActionsDataView =>
  value?.__typename === 'GlocalPulseRecommendedActionsDataView';

export const isGraCardDataView = (value?: any): value is GraCardDataView =>
  value?.__typename === 'GraCardDataView';

export const isGraFacilitatedPathwaysTableComponent = (
  value?: any,
): value is GraFacilitatedPathwaysTableComponent =>
  value?.__typename === 'GraFacilitatedPathwaysTableComponent';

export const isGraFacilitatedPathwaysTableDataView = (
  value?: any,
): value is GraFacilitatedPathwaysTableDataView =>
  value?.__typename === 'GraFacilitatedPathwaysTableDataView';

export const isGraHomeCardsComponent = (value?: any): value is GraHomeCardsComponent =>
  value?.__typename === 'GraHomeCardsComponent';

export const isGraHomeDialsComponent = (value?: any): value is GraHomeDialsComponent =>
  value?.__typename === 'GraHomeDialsComponent';

export const isGraHomeDialsDataView = (value?: any): value is GraHomeDialsDataView =>
  value?.__typename === 'GraHomeDialsDataView';

export const isGraRecommendedPathwaysDetailsComponent = (
  value?: any,
): value is GraRecommendedPathwaysDetailsComponent =>
  value?.__typename === 'GraRecommendedPathwaysDetailsComponent';

export const isGraRecommendedPathwaysDetailsDataView = (
  value?: any,
): value is GraRecommendedPathwaysDetailsDataView =>
  value?.__typename === 'GraRecommendedPathwaysDetailsDataView';

export const isGraRecommendedPathwaysTableComponent = (
  value?: any,
): value is GraRecommendedPathwaysTableComponent =>
  value?.__typename === 'GraRecommendedPathwaysTableComponent';

export const isGraRecommendedPathwaysTableDataView = (
  value?: any,
): value is GraRecommendedPathwaysTableDataView =>
  value?.__typename === 'GraRecommendedPathwaysTableDataView';

export const isGraphicElementResult = (value?: any): value is GraphicElementResult =>
  value?.__typename === 'GraphicElementResult';

export const isGridComponent = (value?: any): value is GridComponent =>
  value?.__typename === 'GridComponent';

export const isGridDataView = (value?: any): value is GridDataView =>
  value?.__typename === 'GridDataView';

export const isGtmCardDataView = (value?: any): value is GtmCardDataView =>
  value?.__typename === 'GtmCardDataView';

export const isGtmHomeCardsComponent = (value?: any): value is GtmHomeCardsComponent =>
  value?.__typename === 'GtmHomeCardsComponent';

export const isGtmHomeDialComponent = (value?: any): value is GtmHomeDialComponent =>
  value?.__typename === 'GtmHomeDialComponent';

export const isGtmHomeDialDataView = (value?: any): value is GtmHomeDialDataView =>
  value?.__typename === 'GtmHomeDialDataView';

export const isGtmImpactRecommendationComponent = (
  value?: any,
): value is GtmImpactRecommendationComponent =>
  value?.__typename === 'GtmImpactRecommendationComponent';

export const isGtmImpactRecommendationDataView = (
  value?: any,
): value is GtmImpactRecommendationDataView =>
  value?.__typename === 'GtmImpactRecommendationDataView';

export const isGtmImpactTableComponent = (value?: any): value is GtmImpactTableComponent =>
  value?.__typename === 'GtmImpactTableComponent';

export const isGtmImpactTableDataView = (value?: any): value is GtmImpactTableDataView =>
  value?.__typename === 'GtmImpactTableDataView';

export const isGtmImpactTableHeaderComponent = (
  value?: any,
): value is GtmImpactTableHeaderComponent => value?.__typename === 'GtmImpactTableHeaderComponent';

export const isGtmImpactTableHeaderDataView = (
  value?: any,
): value is GtmImpactTableHeaderDataView => value?.__typename === 'GtmImpactTableHeaderDataView';

export const isGtmPulseDetailsChartComponent = (
  value?: any,
): value is GtmPulseDetailsChartComponent => value?.__typename === 'GtmPulseDetailsChartComponent';

export const isGtmPulseDetailsChartHeaderComponent = (
  value?: any,
): value is GtmPulseDetailsChartHeaderComponent =>
  value?.__typename === 'GtmPulseDetailsChartHeaderComponent';

export const isGtmPulseDetailsChartHeaderDataView = (
  value?: any,
): value is GtmPulseDetailsChartHeaderDataView =>
  value?.__typename === 'GtmPulseDetailsChartHeaderDataView';

export const isGtmPulseDetailsDriversComponent = (
  value?: any,
): value is GtmPulseDetailsDriversComponent =>
  value?.__typename === 'GtmPulseDetailsDriversComponent';

export const isGtmPulseDetailsDriversDataView = (
  value?: any,
): value is GtmPulseDetailsDriversDataView =>
  value?.__typename === 'GtmPulseDetailsDriversDataView';

export const isGtmPulseDialComponent = (value?: any): value is GtmPulseDialComponent =>
  value?.__typename === 'GtmPulseDialComponent';

export const isGtmPulseDialDataView = (value?: any): value is GtmPulseDialDataView =>
  value?.__typename === 'GtmPulseDialDataView';

export const isGtmPulseLeaderboardListComponent = (
  value?: any,
): value is GtmPulseLeaderboardListComponent =>
  value?.__typename === 'GtmPulseLeaderboardListComponent';

export const isGtmPulseLeaderboardListDataView = (
  value?: any,
): value is GtmPulseLeaderboardListDataView =>
  value?.__typename === 'GtmPulseLeaderboardListDataView';

export const isGtmPulseLeaderboardPositionComponent = (
  value?: any,
): value is GtmPulseLeaderboardPositionComponent =>
  value?.__typename === 'GtmPulseLeaderboardPositionComponent';

export const isGtmPulseLeaderboardPositionDataView = (
  value?: any,
): value is GtmPulseLeaderboardPositionDataView =>
  value?.__typename === 'GtmPulseLeaderboardPositionDataView';

export const isGtmPulseRecommendedActionsComponent = (
  value?: any,
): value is GtmPulseRecommendedActionsComponent =>
  value?.__typename === 'GtmPulseRecommendedActionsComponent';

export const isGtmPulseRecommendedActionsDataView = (
  value?: any,
): value is GtmPulseRecommendedActionsDataView =>
  value?.__typename === 'GtmPulseRecommendedActionsDataView';

export const isGtmResourceAllocationTableComponent = (
  value?: any,
): value is GtmResourceAllocationTableComponent =>
  value?.__typename === 'GtmResourceAllocationTableComponent';

export const isGtmResourceAllocationTableDataView = (
  value?: any,
): value is GtmResourceAllocationTableDataView =>
  value?.__typename === 'GtmResourceAllocationTableDataView';

export const isHeaderRow = (value?: any): value is HeaderRow => value?.__typename === 'HeaderRow';

export const isHeaderRowComponent = (value?: any): value is HeaderRowComponent =>
  value?.__typename === 'HeaderRowComponent';

export const isHeaderSimple = (value?: any): value is HeaderSimple =>
  value?.__typename === 'HeaderSimple';

export const isHeaderSimpleComponent = (value?: any): value is HeaderSimpleComponent =>
  value?.__typename === 'HeaderSimpleComponent';

export const isIconLinkResult = (value?: any): value is IconLinkResult =>
  value?.__typename === 'IconLinkResult';

export const isIconsLinkResult = (value?: any): value is IconsLinkResult =>
  value?.__typename === 'IconsLinkResult';

export const isImageResult = (value?: any): value is ImageResult =>
  value?.__typename === 'ImageResult';

export const isKpiCardResult = (value?: any): value is KpiCardResult =>
  value?.__typename === 'KpiCardResult';

export const isKpiComponent = (value?: any): value is KpiComponent =>
  value?.__typename === 'KpiComponent';

export const isKpiStatus = (value?: any): value is KpiStatus => value?.__typename === 'KpiStatus';

export const isKpiStatusOverview = (value?: any): value is KpiStatusOverview =>
  value?.__typename === 'KpiStatusOverview';

export const isListComponent = (value?: any): value is ListComponent =>
  value?.__typename === 'ListComponent';

export const isListDataView = (value?: any): value is ListDataView =>
  value?.__typename === 'ListDataView';

export const isLocationMarkerResult = (value?: any): value is LocationMarkerResult =>
  value?.__typename === 'LocationMarkerResult';

export const isMarkdownComponent = (value?: any): value is MarkdownComponent =>
  value?.__typename === 'MarkdownComponent';

export const isMicroChartCardResult = (value?: any): value is MicroChartCardResult =>
  value?.__typename === 'MicroChartCardResult';

export const isMicroChartComponent = (value?: any): value is MicroChartComponent =>
  value?.__typename === 'MicroChartComponent';

export const isMicroChartResult = (value?: any): value is MicroChartResult =>
  value?.__typename === 'MicroChartResult';

export const isMnsDeviationsDetailsComponent = (
  value?: any,
): value is MnsDeviationsDetailsComponent => value?.__typename === 'MnsDeviationsDetailsComponent';

export const isMnsDeviationsDetailsDataView = (
  value?: any,
): value is MnsDeviationsDetailsDataView => value?.__typename === 'MnsDeviationsDetailsDataView';

export const isMnsDeviationsDialComponent = (value?: any): value is MnsDeviationsDialComponent =>
  value?.__typename === 'MnsDeviationsDialComponent';

export const isMnsDeviationsDialDataView = (value?: any): value is MnsDeviationsDialDataView =>
  value?.__typename === 'MnsDeviationsDialDataView';

export const isMultiSelectFilterValue = (value?: any): value is MultiSelectFilterValue =>
  value?.__typename === 'MultiSelectFilterValue';

export const isNavigationTabsComponent = (value?: any): value is NavigationTabsComponent =>
  value?.__typename === 'NavigationTabsComponent';

export const isPlailistComponent = (value?: any): value is PlailistComponent =>
  value?.__typename === 'PlailistComponent';

export const isPnlRebatesBreakdownChartComponent = (
  value?: any,
): value is PnlRebatesBreakdownChartComponent =>
  value?.__typename === 'PnlRebatesBreakdownChartComponent';

export const isPnlRebatesBreakdownChartDataView = (
  value?: any,
): value is PnlRebatesBreakdownChartDataView =>
  value?.__typename === 'PnlRebatesBreakdownChartDataView';

export const isPnlRebatesBreakdownChartHeaderComponent = (
  value?: any,
): value is PnlRebatesBreakdownChartHeaderComponent =>
  value?.__typename === 'PnlRebatesBreakdownChartHeaderComponent';

export const isPnlRebatesBreakdownChartHeaderDataView = (
  value?: any,
): value is PnlRebatesBreakdownChartHeaderDataView =>
  value?.__typename === 'PnlRebatesBreakdownChartHeaderDataView';

export const isPnlRebatesBreakdownTableComponent = (
  value?: any,
): value is PnlRebatesBreakdownTableComponent =>
  value?.__typename === 'PnlRebatesBreakdownTableComponent';

export const isPnlRebatesBreakdownTableDataView = (
  value?: any,
): value is PnlRebatesBreakdownTableDataView =>
  value?.__typename === 'PnlRebatesBreakdownTableDataView';

export const isPnlRebatesInsightsCardsComponent = (
  value?: any,
): value is PnlRebatesInsightsCardsComponent =>
  value?.__typename === 'PnlRebatesInsightsCardsComponent';

export const isPnlRebatesInsightsCardsDataView = (
  value?: any,
): value is PnlRebatesInsightsCardsDataView =>
  value?.__typename === 'PnlRebatesInsightsCardsDataView';

export const isPnlRebatesInsightsHeaderComponent = (
  value?: any,
): value is PnlRebatesInsightsHeaderComponent =>
  value?.__typename === 'PnlRebatesInsightsHeaderComponent';

export const isPnlRebatesInsightsHeaderDataView = (
  value?: any,
): value is PnlRebatesInsightsHeaderDataView =>
  value?.__typename === 'PnlRebatesInsightsHeaderDataView';

export const isPplCardDataView = (value?: any): value is PplCardDataView =>
  value?.__typename === 'PplCardDataView';

export const isPplHomeCardsComponent = (value?: any): value is PplHomeCardsComponent =>
  value?.__typename === 'PplHomeCardsComponent';

export const isPplHomeCategoriesDialsComponent = (
  value?: any,
): value is PplHomeCategoriesDialsComponent =>
  value?.__typename === 'PplHomeCategoriesDialsComponent';

export const isPplHomeCategoriesDialsDataView = (
  value?: any,
): value is PplHomeCategoriesDialsDataView =>
  value?.__typename === 'PplHomeCategoriesDialsDataView';

export const isPplHomeDetailsChartComponent = (
  value?: any,
): value is PplHomeDetailsChartComponent => value?.__typename === 'PplHomeDetailsChartComponent';

export const isPplHomeDetailsChartHeaderComponent = (
  value?: any,
): value is PplHomeDetailsChartHeaderComponent =>
  value?.__typename === 'PplHomeDetailsChartHeaderComponent';

export const isPplHomeDetailsChartHeaderDataView = (
  value?: any,
): value is PplHomeDetailsChartHeaderDataView =>
  value?.__typename === 'PplHomeDetailsChartHeaderDataView';

export const isPplHomeDetailsDriverChartHeaderDataView = (
  value?: any,
): value is PplHomeDetailsDriverChartHeaderDataView =>
  value?.__typename === 'PplHomeDetailsDriverChartHeaderDataView';

export const isPplHomeDetailsDriversChartComponent = (
  value?: any,
): value is PplHomeDetailsDriversChartComponent =>
  value?.__typename === 'PplHomeDetailsDriversChartComponent';

export const isPplHomeDetailsDriversChartHeaderComponent = (
  value?: any,
): value is PplHomeDetailsDriversChartHeaderComponent =>
  value?.__typename === 'PplHomeDetailsDriversChartHeaderComponent';

export const isPplHomeDetailsDriversComponent = (
  value?: any,
): value is PplHomeDetailsDriversComponent =>
  value?.__typename === 'PplHomeDetailsDriversComponent';

export const isPplHomeDetailsDriversDataView = (
  value?: any,
): value is PplHomeDetailsDriversDataView => value?.__typename === 'PplHomeDetailsDriversDataView';

export const isPplHomeDetailsRecommendationsComponent = (
  value?: any,
): value is PplHomeDetailsRecommendationsComponent =>
  value?.__typename === 'PplHomeDetailsRecommendationsComponent';

export const isPplHomeDetailsRecommendationsDataView = (
  value?: any,
): value is PplHomeDetailsRecommendationsDataView =>
  value?.__typename === 'PplHomeDetailsRecommendationsDataView';

export const isPplHomeDialComponent = (value?: any): value is PplHomeDialComponent =>
  value?.__typename === 'PplHomeDialComponent';

export const isPplHomeDialDataView = (value?: any): value is PplHomeDialDataView =>
  value?.__typename === 'PplHomeDialDataView';

export const isPplMicroChartResult = (value?: any): value is PplMicroChartResult =>
  value?.__typename === 'PplMicroChartResult';

export const isProAgentDetailsComponent = (value?: any): value is ProAgentDetailsComponent =>
  value?.__typename === 'ProAgentDetailsComponent';

export const isProAgentDetailsDataView = (value?: any): value is ProAgentDetailsDataView =>
  value?.__typename === 'ProAgentDetailsDataView';

export const isProDailyAgentComponent = (value?: any): value is ProDailyAgentComponent =>
  value?.__typename === 'ProDailyAgentComponent';

export const isProDailyAgentDataView = (value?: any): value is ProDailyAgentDataView =>
  value?.__typename === 'ProDailyAgentDataView';

export const isProHomeFeedComponent = (value?: any): value is ProHomeFeedComponent =>
  value?.__typename === 'ProHomeFeedComponent';

export const isProHomeFeedDataView = (value?: any): value is ProHomeFeedDataView =>
  value?.__typename === 'ProHomeFeedDataView';

export const isProgressBarResult = (value?: any): value is ProgressBarResult =>
  value?.__typename === 'ProgressBarResult';

export const isReal = (value?: any): value is Real => value?.__typename === 'Real';

export const isRecommendationsActionsComponent = (
  value?: any,
): value is RecommendationsActionsComponent =>
  value?.__typename === 'RecommendationsActionsComponent';

export const isRecommendationsActionsDataView = (
  value?: any,
): value is RecommendationsActionsDataView =>
  value?.__typename === 'RecommendationsActionsDataView';

export const isRecommendationsBreakdownComponent = (
  value?: any,
): value is RecommendationsBreakdownComponent =>
  value?.__typename === 'RecommendationsBreakdownComponent';

export const isRecommendationsBreakdownDataView = (
  value?: any,
): value is RecommendationsBreakdownDataView =>
  value?.__typename === 'RecommendationsBreakdownDataView';

export const isRecommenderComponent = (value?: any): value is RecommenderComponent =>
  value?.__typename === 'RecommenderComponent';

export const isRecommenderDataView = (value?: any): value is RecommenderDataView =>
  value?.__typename === 'RecommenderDataView';

export const isRegionCountryResult = (value?: any): value is RegionCountryResult =>
  value?.__typename === 'RegionCountryResult';

export const isRiskIndicatorResult = (value?: any): value is RiskIndicatorResult =>
  value?.__typename === 'RiskIndicatorResult';

export const isRndHomeDialsComponent = (value?: any): value is RndHomeDialsComponent =>
  value?.__typename === 'RndHomeDialsComponent';

export const isRndHomeDialsDataView = (value?: any): value is RndHomeDialsDataView =>
  value?.__typename === 'RndHomeDialsDataView';

export const isRndInsightsBusinessDevelopmentAndLicensingDriversComponent = (
  value?: any,
): value is RndInsightsBusinessDevelopmentAndLicensingDriversComponent =>
  value?.__typename === 'RndInsightsBusinessDevelopmentAndLicensingDriversComponent';

export const isRndInsightsBusinessDevelopmentAndLicensingDriversView = (
  value?: any,
): value is RndInsightsBusinessDevelopmentAndLicensingDriversView =>
  value?.__typename === 'RndInsightsBusinessDevelopmentAndLicensingDriversView';

export const isRndInsightsBusinessDevelopmentAndLicensingTableComponent = (
  value?: any,
): value is RndInsightsBusinessDevelopmentAndLicensingTableComponent =>
  value?.__typename === 'RndInsightsBusinessDevelopmentAndLicensingTableComponent';

export const isRndInsightsBusinessDevelopmentAndLicensingTableDataView = (
  value?: any,
): value is RndInsightsBusinessDevelopmentAndLicensingTableDataView =>
  value?.__typename === 'RndInsightsBusinessDevelopmentAndLicensingTableDataView';

export const isRndInventoryRecommendationsBreakdownComponent = (
  value?: any,
): value is RndInventoryRecommendationsBreakdownComponent =>
  value?.__typename === 'RndInventoryRecommendationsBreakdownComponent';

export const isRndInventoryRecommendationsBreakdownDataView = (
  value?: any,
): value is RndInventoryRecommendationsBreakdownDataView =>
  value?.__typename === 'RndInventoryRecommendationsBreakdownDataView';

export const isRndInventoryRecommendationsComponent = (
  value?: any,
): value is RndInventoryRecommendationsComponent =>
  value?.__typename === 'RndInventoryRecommendationsComponent';

export const isRndInventoryRecommendationsDataView = (
  value?: any,
): value is RndInventoryRecommendationsDataView =>
  value?.__typename === 'RndInventoryRecommendationsDataView';

export const isRndInventoryRootCausesComponent = (
  value?: any,
): value is RndInventoryRootCausesComponent =>
  value?.__typename === 'RndInventoryRootCausesComponent';

export const isRndInventoryRootCausesDataView = (
  value?: any,
): value is RndInventoryRootCausesDataView =>
  value?.__typename === 'RndInventoryRootCausesDataView';

export const isRndStudyDesignFormComponent = (value?: any): value is RndStudyDesignFormComponent =>
  value?.__typename === 'RndStudyDesignFormComponent';

export const isRndStudyDesignerSiteDistributionTableDataView = (
  value?: any,
): value is RndStudyDesignerSiteDistributionTableDataView =>
  value?.__typename === 'RndStudyDesignerSiteDistributionTableDataView';

export const isRndStudyDesignsSiteDistributionTableComponent = (
  value?: any,
): value is RndStudyDesignsSiteDistributionTableComponent =>
  value?.__typename === 'RndStudyDesignsSiteDistributionTableComponent';

export const isRndStudyDesignsTableComponent = (
  value?: any,
): value is RndStudyDesignsTableComponent => value?.__typename === 'RndStudyDesignsTableComponent';

export const isRndStudyDesignsTableDataView = (
  value?: any,
): value is RndStudyDesignsTableDataView => value?.__typename === 'RndStudyDesignsTableDataView';

export const isRndTimelineChartDataViewResult = (
  value?: any,
): value is RndTimelineChartDataViewResult =>
  value?.__typename === 'RndTimelineChartDataViewResult';

export const isRndTrialsAiPosDriversComponent = (
  value?: any,
): value is RndTrialsAiPosDriversComponent =>
  value?.__typename === 'RndTrialsAiPosDriversComponent';

export const isRndTrialsAiPosDriversDataView = (
  value?: any,
): value is RndTrialsAiPosDriversDataView => value?.__typename === 'RndTrialsAiPosDriversDataView';

export const isRndTrialsBreakdownAiPtrsDriversDataView = (
  value?: any,
): value is RndTrialsBreakdownAiPtrsDriversDataView =>
  value?.__typename === 'RndTrialsBreakdownAiPtrsDriversDataView';

export const isRndTrialsKpiCategoriesComponent = (
  value?: any,
): value is RndTrialsKpiCategoriesComponent =>
  value?.__typename === 'RndTrialsKpiCategoriesComponent';

export const isRndTrialsKpiCategoriesDataView = (
  value?: any,
): value is RndTrialsKpiCategoriesDataView =>
  value?.__typename === 'RndTrialsKpiCategoriesDataView';

export const isRndTrialsKpiDetailTableComponent = (
  value?: any,
): value is RndTrialsKpiDetailTableComponent =>
  value?.__typename === 'RndTrialsKpiDetailTableComponent';

export const isRndTrialsKpiDetailTableDataView = (
  value?: any,
): value is RndTrialsKpiDetailTableDataView =>
  value?.__typename === 'RndTrialsKpiDetailTableDataView';

export const isRndTrialsRndInvestmentsPnlTableComponent = (
  value?: any,
): value is RndTrialsRndInvestmentsPnlTableComponent =>
  value?.__typename === 'RndTrialsRndInvestmentsPnlTableComponent';

export const isRndTrialsRndInvestmentsPnlTableDataView = (
  value?: any,
): value is RndTrialsRndInvestmentsPnlTableDataView =>
  value?.__typename === 'RndTrialsRndInvestmentsPnlTableDataView';

export const isRndTrialsValueOverviewChartDataView = (
  value?: any,
): value is RndTrialsValueOverviewChartDataView =>
  value?.__typename === 'RndTrialsValueOverviewChartDataView';

export const isRndTrialsValueOverviewChartDataViewResult = (
  value?: any,
): value is RndTrialsValueOverviewChartDataViewResult =>
  value?.__typename === 'RndTrialsValueOverviewChartDataViewResult';

export const isRndTrialsValueOverviewChartHeaderDataView = (
  value?: any,
): value is RndTrialsValueOverviewChartHeaderDataView =>
  value?.__typename === 'RndTrialsValueOverviewChartHeaderDataView';

export const isRndValueOverviewChartComponent = (
  value?: any,
): value is RndValueOverviewChartComponent =>
  value?.__typename === 'RndValueOverviewChartComponent';

export const isRndValueOverviewChartHeaderComponent = (
  value?: any,
): value is RndValueOverviewChartHeaderComponent =>
  value?.__typename === 'RndValueOverviewChartHeaderComponent';

export const isSelectFilter = (value?: any): value is SelectFilter =>
  value?.__typename === 'SelectFilter';

export const isSelectFilterValue = (value?: any): value is SelectFilterValue =>
  value?.__typename === 'SelectFilterValue';

export const isSelectGroupFilter = (value?: any): value is SelectGroupFilter =>
  value?.__typename === 'SelectGroupFilter';

export const isSelectGroupFilterValue = (value?: any): value is SelectGroupFilterValue =>
  value?.__typename === 'SelectGroupFilterValue';

export const isSimpleCardResult = (value?: any): value is SimpleCardResult =>
  value?.__typename === 'SimpleCardResult';

export const isSpendCardDataView = (value?: any): value is SpendCardDataView =>
  value?.__typename === 'SpendCardDataView';

export const isSpendCmoPulseDialComponent = (value?: any): value is SpendCmoPulseDialComponent =>
  value?.__typename === 'SpendCmoPulseDialComponent';

export const isSpendCmoPulseDialDataView = (value?: any): value is SpendCmoPulseDialDataView =>
  value?.__typename === 'SpendCmoPulseDialDataView';

export const isSpendCmoPulseDriversChartComponent = (
  value?: any,
): value is SpendCmoPulseDriversChartComponent =>
  value?.__typename === 'SpendCmoPulseDriversChartComponent';

export const isSpendCmoPulseDriversChartDataView = (
  value?: any,
): value is SpendCmoPulseDriversChartDataView =>
  value?.__typename === 'SpendCmoPulseDriversChartDataView';

export const isSpendCmoPulseDriversListComponent = (
  value?: any,
): value is SpendCmoPulseDriversListComponent =>
  value?.__typename === 'SpendCmoPulseDriversListComponent';

export const isSpendCmoPulseDriversListDataView = (
  value?: any,
): value is SpendCmoPulseDriversListDataView =>
  value?.__typename === 'SpendCmoPulseDriversListDataView';

export const isSpendCmoPulseKeyDriversChartComponent = (
  value?: any,
): value is SpendCmoPulseKeyDriversChartComponent =>
  value?.__typename === 'SpendCmoPulseKeyDriversChartComponent';

export const isSpendCmoPulseKeyDriversListComponent = (
  value?: any,
): value is SpendCmoPulseKeyDriversListComponent =>
  value?.__typename === 'SpendCmoPulseKeyDriversListComponent';

export const isSpendCmoPulseLeaderboardPositionComponent = (
  value?: any,
): value is SpendCmoPulseLeaderboardPositionComponent =>
  value?.__typename === 'SpendCmoPulseLeaderboardPositionComponent';

export const isSpendCmoPulseRecommendedActionsComponent = (
  value?: any,
): value is SpendCmoPulseRecommendedActionsComponent =>
  value?.__typename === 'SpendCmoPulseRecommendedActionsComponent';

export const isSpendGlocalPulseDialComponent = (
  value?: any,
): value is SpendGlocalPulseDialComponent => value?.__typename === 'SpendGlocalPulseDialComponent';

export const isSpendGlocalPulseDialDataView = (
  value?: any,
): value is SpendGlocalPulseDialDataView => value?.__typename === 'SpendGlocalPulseDialDataView';

export const isSpendGlocalPulseDriversChartComponent = (
  value?: any,
): value is SpendGlocalPulseDriversChartComponent =>
  value?.__typename === 'SpendGlocalPulseDriversChartComponent';

export const isSpendGlocalPulseDriversChartDataView = (
  value?: any,
): value is SpendGlocalPulseDriversChartDataView =>
  value?.__typename === 'SpendGlocalPulseDriversChartDataView';

export const isSpendGlocalPulseDriversListComponent = (
  value?: any,
): value is SpendGlocalPulseDriversListComponent =>
  value?.__typename === 'SpendGlocalPulseDriversListComponent';

export const isSpendGlocalPulseDriversListDataView = (
  value?: any,
): value is SpendGlocalPulseDriversListDataView =>
  value?.__typename === 'SpendGlocalPulseDriversListDataView';

export const isSpendGlocalPulseKeyDriversChartComponent = (
  value?: any,
): value is SpendGlocalPulseKeyDriversChartComponent =>
  value?.__typename === 'SpendGlocalPulseKeyDriversChartComponent';

export const isSpendGlocalPulseKeyDriversListComponent = (
  value?: any,
): value is SpendGlocalPulseKeyDriversListComponent =>
  value?.__typename === 'SpendGlocalPulseKeyDriversListComponent';

export const isSpendGlocalPulseLeaderboardListComponent = (
  value?: any,
): value is SpendGlocalPulseLeaderboardListComponent =>
  value?.__typename === 'SpendGlocalPulseLeaderboardListComponent';

export const isSpendGlocalPulseLeaderboardListDataView = (
  value?: any,
): value is SpendGlocalPulseLeaderboardListDataView =>
  value?.__typename === 'SpendGlocalPulseLeaderboardListDataView';

export const isSpendGlocalPulseLeaderboardPositionComponent = (
  value?: any,
): value is SpendGlocalPulseLeaderboardPositionComponent =>
  value?.__typename === 'SpendGlocalPulseLeaderboardPositionComponent';

export const isSpendGlocalPulseRecommendedActionsComponent = (
  value?: any,
): value is SpendGlocalPulseRecommendedActionsComponent =>
  value?.__typename === 'SpendGlocalPulseRecommendedActionsComponent';

export const isSpendHomeCardsComponent = (value?: any): value is SpendHomeCardsComponent =>
  value?.__typename === 'SpendHomeCardsComponent';

export const isSpendHomeDialComponent = (value?: any): value is SpendHomeDialComponent =>
  value?.__typename === 'SpendHomeDialComponent';

export const isSpendHomeDialDataView = (value?: any): value is SpendHomeDialDataView =>
  value?.__typename === 'SpendHomeDialDataView';

export const isSpendProcurementChartComponent = (
  value?: any,
): value is SpendProcurementChartComponent =>
  value?.__typename === 'SpendProcurementChartComponent';

export const isSpendProcurementChartDataView = (
  value?: any,
): value is SpendProcurementChartDataView => value?.__typename === 'SpendProcurementChartDataView';

export const isSpendProcurementTableComponent = (
  value?: any,
): value is SpendProcurementTableComponent =>
  value?.__typename === 'SpendProcurementTableComponent';

export const isSpendProcurementTableDataView = (
  value?: any,
): value is SpendProcurementTableDataView => value?.__typename === 'SpendProcurementTableDataView';

export const isSpendTrendingComponent = (value?: any): value is SpendTrendingComponent =>
  value?.__typename === 'SpendTrendingComponent';

export const isSpendTrendingResult = (value?: any): value is SpendTrendingResult =>
  value?.__typename === 'SpendTrendingResult';

export const isStatusTextResult = (value?: any): value is StatusTextResult =>
  value?.__typename === 'StatusTextResult';

export const isStory = (value?: any): value is Story => value?.__typename === 'Story';

export const isStoryListComponent = (value?: any): value is StoryListComponent =>
  value?.__typename === 'StoryListComponent';

export const isStudyDesignLinkResult = (value?: any): value is StudyDesignLinkResult =>
  value?.__typename === 'StudyDesignLinkResult';

export const isSummaryInfoComponent = (value?: any): value is SummaryInfoComponent =>
  value?.__typename === 'SummaryInfoComponent';

export const isSummaryInfoDataView = (value?: any): value is SummaryInfoDataView =>
  value?.__typename === 'SummaryInfoDataView';

export const isSupplyHomeCardsComponent = (value?: any): value is SupplyHomeCardsComponent =>
  value?.__typename === 'SupplyHomeCardsComponent';

export const isSupplyHomeCardsDataView = (value?: any): value is SupplyHomeCardsDataView =>
  value?.__typename === 'SupplyHomeCardsDataView';

export const isSupplyHomeDialsComponent = (value?: any): value is SupplyHomeDialsComponent =>
  value?.__typename === 'SupplyHomeDialsComponent';

export const isSupplyHomeDialsDataView = (value?: any): value is SupplyHomeDialsDataView =>
  value?.__typename === 'SupplyHomeDialsDataView';

export const isSupplyLowInventoryRiskRootCausesComponent = (
  value?: any,
): value is SupplyLowInventoryRiskRootCausesComponent =>
  value?.__typename === 'SupplyLowInventoryRiskRootCausesComponent';

export const isSupplyLowInventoryRiskRootCausesDataView = (
  value?: any,
): value is SupplyLowInventoryRiskRootCausesDataView =>
  value?.__typename === 'SupplyLowInventoryRiskRootCausesDataView';

export const isSupplyPulseDriverKpisDataView = (
  value?: any,
): value is SupplyPulseDriverKpisDataView => value?.__typename === 'SupplyPulseDriverKpisDataView';

export const isSupplyPulseLandingResult = (value?: any): value is SupplyPulseLandingResult =>
  value?.__typename === 'SupplyPulseLandingResult';

export const isSupplyPulseLeaderboardListComponent = (
  value?: any,
): value is SupplyPulseLeaderboardListComponent =>
  value?.__typename === 'SupplyPulseLeaderboardListComponent';

export const isSupplyPulseLeaderboardListDataView = (
  value?: any,
): value is SupplyPulseLeaderboardListDataView =>
  value?.__typename === 'SupplyPulseLeaderboardListDataView';

export const isSupplyShortDatedRootCausesComponent = (
  value?: any,
): value is SupplyShortDatedRootCausesComponent =>
  value?.__typename === 'SupplyShortDatedRootCausesComponent';

export const isSupplyShortDatedRootCausesDataView = (
  value?: any,
): value is SupplyShortDatedRootCausesDataView =>
  value?.__typename === 'SupplyShortDatedRootCausesDataView';

export const isTableComponent = (value?: any): value is TableComponent =>
  value?.__typename === 'TableComponent';

export const isTableDataView = (value?: any): value is TableDataView =>
  value?.__typename === 'TableDataView';

export const isTableSwitcherComponent = (value?: any): value is TableSwitcherComponent =>
  value?.__typename === 'TableSwitcherComponent';

export const isTextExtendedResult = (value?: any): value is TextExtendedResult =>
  value?.__typename === 'TextExtendedResult';

export const isTextLinkResult = (value?: any): value is TextLinkResult =>
  value?.__typename === 'TextLinkResult';

export const isTextResult = (value?: any): value is TextResult =>
  value?.__typename === 'TextResult';

export const isThemeColor = (value?: any): value is ThemeColor =>
  value?.__typename === 'ThemeColor';

export const isTimeline = (value?: any): value is Timeline => value?.__typename === 'Timeline';

export const isTimelineComponent = (value?: any): value is TimelineComponent =>
  value?.__typename === 'TimelineComponent';

export const isTimelineDataPoint = (value?: any): value is TimelineDataPoint =>
  value?.__typename === 'TimelineDataPoint';

export const isTimelinePhases = (value?: any): value is TimelinePhases =>
  value?.__typename === 'TimelinePhases';

export const isTreeSelectFilter = (value?: any): value is TreeSelectFilter =>
  value?.__typename === 'TreeSelectFilter';

export const isUserAnalyticsComponent = (value?: any): value is UserAnalyticsComponent =>
  value?.__typename === 'UserAnalyticsComponent';

export const isUserPrioritiesComponent = (value?: any): value is UserPrioritiesComponent =>
  value?.__typename === 'UserPrioritiesComponent';

export const isValueWithVarianceResult = (value?: any): value is ValueWithVarianceResult =>
  value?.__typename === 'ValueWithVarianceResult';

export const isVarianceResult = (value?: any): value is VarianceResult =>
  value?.__typename === 'VarianceResult';

export const isVideoComponent = (value?: any): value is VideoComponent =>
  value?.__typename === 'VideoComponent';

export const isVideoContent = (value?: any): value is VideoContent =>
  value?.__typename === 'VideoContent';
