import { Real } from '@aily/graphql-sdk/schema';
import React, {
  createContext,
  ReactElement,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

import { useRealAudio } from './hooks/UseRealAudio';

interface RealModalContextType {
  data?: Real;
  activeIndex: number;
  showOpeningScreen: boolean;
  visibleCards: number;
  pauseAudio: boolean;
  audioProgressPercentage: number;
  rewind: () => void;
  fastForward: () => void;
  onNext: () => void;
  onBack: () => void;
  handleClose: () => void;
  handleSetActiveIndex: (value: number) => void;
  handleToggleAudio: () => void;
  audio: ReactElement;
}

const RealModalContext = createContext<RealModalContextType | undefined>(undefined);

const INITIAL_INDEX = -1;

export const RealModalProvider: React.FC<{
  children: React.ReactNode;
  data?: Real;
  onClose: () => void;
}> = ({ children, data, onClose }) => {
  const [activeIndex, setActiveIndex] = useState(INITIAL_INDEX);
  const [pauseAudio, setPauseAudio] = useState(false);
  const [visibleCards, setVisibleCards] = useState(INITIAL_INDEX);
  const showOpeningScreen = useMemo(() => activeIndex === INITIAL_INDEX, [activeIndex]);

  const onNext = useCallback(() => {
    setVisibleCards((prev) => prev + (prev > activeIndex ? 0 : 1));
    setActiveIndex((prev) => prev + 1);
  }, [activeIndex]);

  const { audio, fastForward, rewind, audioProgressPercentage } = useRealAudio(
    data?.screens?.[activeIndex + 1]?.audio?.trackName ?? '',
    pauseAudio,
    onNext,
  );

  const onBack = useCallback(() => {
    setActiveIndex((prev) => prev - 1);
  }, []);

  const handleClose = useCallback(() => {
    onClose();
    setActiveIndex(INITIAL_INDEX);
  }, [onClose]);

  const handleSetActiveIndex = useCallback((index: number) => {
    setActiveIndex(index);
    setPauseAudio(false);
  }, []);

  const handleToggleAudio = useCallback(() => {
    setPauseAudio((prev) => !prev);
  }, []);

  return (
    <RealModalContext.Provider
      value={{
        data,
        activeIndex,
        showOpeningScreen,
        visibleCards,
        pauseAudio,
        audioProgressPercentage,
        onNext,
        handleClose,
        handleSetActiveIndex,
        onBack,
        handleToggleAudio,
        rewind: () => rewind(onBack),
        fastForward,
        audio,
      }}
    >
      {children}
    </RealModalContext.Provider>
  );
};

export const useRealModal = () => {
  const context = useContext(RealModalContext);
  if (context === undefined) {
    throw new Error('useRealModal must be used within a RealModalProvider');
  }
  return context;
};
