import {
  AilyEbiLogo,
  AilyGraLogo,
  AilyGtmLogo,
  AilyMaSLogo,
  AilyPplLogo,
  AilyProLogo,
  AilySpendLogo,
} from '@aily-labs/ui';
import React from 'react';

import { AilyFin } from './AilyFin';
import { AilyRnd } from './AilyRnd';

export interface ModuleLogoProps {
  variant: 'ebi' | 'fin' | 'rnd' | 'gtm' | 'spend' | 'gra' | 'mns' | 'pro' | 'ppl';
  primaryColor?: string;
  secondaryColor?: string;
}

export type ModuleLogoVariantProps = Omit<ModuleLogoProps, 'variant'>;

type ComponentMap = Record<ModuleLogoProps['variant'], React.ComponentType<ModuleLogoVariantProps>>;

const componentMap: ComponentMap = {
  ebi: () => <AilyEbiLogo />,
  fin: AilyFin,
  rnd: AilyRnd,
  spend: () => <AilySpendLogo />,
  gtm: () => <AilyGtmLogo />,
  ppl: () => <AilyPplLogo />,
  mns: () => <AilyMaSLogo />,
  gra: () => <AilyGraLogo />,
  pro: () => <AilyProLogo style={{ height: 34 }} />,
};

export const ModuleLogo: React.FC<ModuleLogoProps> = ({ variant, ...rest }) => {
  const Component = componentMap[variant];
  return <Component {...rest} />;
};
