import * as T from '@aily/graphql-sdk/schema';
import { DoublePlayIcon, HStack, VStack } from '@aily-labs/ui';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { map } from 'lodash-es';
import { ReactElement } from 'react';

import { useHorizontalScroll } from '../hooks/UseHorizontalScroll';
import { RealScreen, RealText, renderText } from '.';

const Wrapper = styled(Box)({ width: '100%', height: '100%', padding: 32 });

const SliderContainer = styled(Box)(() => ({
  width: '100%',
  height: '100%',
  overflowX: 'auto',
  overflowY: 'hidden',
  cursor: 'grab',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  '&:active': { cursor: 'grabbing' },
  '&::-webkit-scrollbar': { display: 'none' },
  msOverflowStyle: 'none',
  scrollbarWidth: 'none',
  scrollBehavior: 'smooth',
  padding: '100px 32px',
  '& .slow-scroll': {
    scrollBehavior: 'smooth',
    transition: 'scroll-behavior 3s ease',
  },
  '& > *': {
    userSelect: 'none',
  },
}));

const SliderContent = styled(Box)<{ numberOfScreens: number }>(({ numberOfScreens }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: numberOfScreens > 5 ? 'flex-start' : 'center',
  height: '100%',
  width: '100%',
  padding: 32,
}));

interface RealSliderProps {
  data: T.Real;
  activeIndex: number;
  visibleCards: number;
  pauseAudio: boolean;
  audio: ReactElement;
  onSetActiveIndex: (value: number) => void;
  onClose: () => void;
  onClickLetsDo?: () => void;
}

export const RealSlider = ({
  data,
  activeIndex,
  visibleCards,
  pauseAudio,
  onSetActiveIndex,
  onClose,
  onClickLetsDo,
  audio,
}: RealSliderProps) => {
  const { sliderRef } = useHorizontalScroll(activeIndex, visibleCards);

  // remove the first screen because it wont be displayed as a card
  const screens = data?.screens?.slice(1);
  const numberOfScreens = screens.length;
  const handleLetsDo = () => {
    onClickLetsDo?.();
    onClose();
  };

  const onCardClick = (index: number) => {
    if (activeIndex < visibleCards || index < visibleCards + 1) {
      onSetActiveIndex(index);
    }
  };

  return (
    <Wrapper>
      <HStack
        alignItems="center"
        justifyContent="center"
        marginTop={100}
        flex={1}
        gap="$spacingMedium"
      >
        <DoublePlayIcon />

        <HStack flexWrap="nowrap" gap={5}>
          {data?.screens[0]?.content?.items?.map((content, index) => (
            <HStack key={index} alignItems="center">
              {(content?.text as T.FormattedTextResult[]).map((item, textIndex) =>
                renderText({ ...item, fullWidth: true, boldText: true } as RealText, textIndex),
              )}
            </HStack>
          ))}
        </HStack>
      </HStack>

      <SliderContainer ref={sliderRef} className="active" position="relative">
        <SliderContent className="active" numberOfScreens={numberOfScreens}>
          {map(screens, (screen, index) => (
            <VStack
              key={index}
              gap={'$spacingXLarge'}
              alignItems="center"
              pointerEvents={index > visibleCards ? 'none' : 'auto'}
            >
              <RealScreen
                index={index}
                numberOfScreens={numberOfScreens}
                activeIndex={activeIndex}
                data={screen as T.RealScreen}
                isVisible={index <= visibleCards}
                onClick={() => onCardClick(index)}
                allScreensAreVisible={visibleCards === numberOfScreens - 1}
                handleLetsDo={handleLetsDo}
                pauseAudio={pauseAudio}
                visibleCards={visibleCards}
                audio={<div>{audio}</div>}
              />
            </VStack>
          ))}
        </SliderContent>
      </SliderContainer>
    </Wrapper>
  );
};
